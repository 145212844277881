import Grid from '@mui/material/Grid2'
import { api } from '@one/api'
import { ArtikelArtJson } from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useCallback } from 'react'

const sortData = (data) => {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
  const itemcmp = (a, b) => collator.compare(a.key, b.key)
  const ext = data.map((item) => ({ ...item, key: `${item.name} - ${item.bez} (${item.kurz})` }))
  ext.sort(itemcmp)
  return ext
}

export const ArtikelArtPflegeView = () => {
  const { model, isChanged, uiLock, onValueChange, save, reload, updModel } = useModelMgr({
    noid: true,
    api,
    title: 'Ignorierte Artikelarten',
    unwrapField: 'result',
    rest: 'artikelart',
    restEdit: 'alle',
    restSave: 'ignoriere',
    init: { arten: [] },
    editMutator: (data) => ({
      ...data.reduce((a, item) => {
        a[item.id] = item.ignoriert
        return a
      }, {}),
      arten: sortData(data)
    }),
    saveMutator: (data) => {
      const { arten, ...editiert } = data
      return editiert
    }
  })

  const onChangeEditiert = useCallback(
    (item, checked) => () => {
      updModel({ [item.id]: checked })
    },
    [updModel]
  )

  const renderCheckbox = useCallback(
    (item: ArtikelArtJson) => {
      const checked = model[item.id]
      return (
        <Grid size={{xs:12, sm:6, md:4,lg:3}} key={item.id}>
          <Checkbox
            label={`${item.name} - ${item.bez} (${item.kurz})`}
            size="small"
            name={`${item.name}`}
            checked={checked}
            onChange={onChangeEditiert(item, !checked)}
          />
        </Grid>
      )
    },
    [model, onChangeEditiert]
  )

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <CardEx backLink title="Pflege der nicht anzuzeigenden Artikelarten" height="100%">
            <ScrollPanel height="100%">
              <Grid
                container
                direction="row"
                height="100%"
                alignContent="start"
                flexWrap="wrap"
                spacing={1}
              >
                {model.arten.length > 0 && model.arten.map(renderCheckbox)}
              </Grid>
            </ScrollPanel>
          </CardEx>
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            {/* <TooltipEx title="Alle makierten Artikelarten auf 'ignoriert' setzten">
              <span>
              </span>
            </TooltipEx> */}
            <SaveButton onClickVoid={save} isChanged={isChanged} isNew={false} />
            <ReloadButton onClick={reload} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
