import { Add } from '@mui/icons-material'
import Grid from '@mui/material/Grid2'
import { LieferantSearchDialog } from '@one/components/Lieferant/LieferantSearchDialog'
import { LieferantDisplayJson } from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelLieferantenProps {
  dispatch: (action: ModelAction) => void
    readonly?: boolean
}

export const PimArtikelLieferanten = ({ dispatch,readonly }: PimArtikelLieferantenProps) => {
  const [DlgAnker, showDlg] = useDialogAnker()
  const onAddLieferant = () => {
    showDlg((open, onClose) => (
      <LieferantSearchDialog
        open={open}
        onClose={onClose((lieferanten: LieferantDisplayJson[]) => {
          if (lieferanten?.length > 0) {
            dispatch({ type: EigenlistungsArtikelUseCase.ADDLIEFERANT, lieferanten })
          }
        })}
        multiselect
        defaultDezentral
        disableZentral
      />
    ))
  }

  return (
    <Grid container direction="column">
      <Grid marginBottom={1} marginTop={2}>
        <Button
          label="Artikellieferant hinzufügen"
          StartIcon={Add}
          variant="contained"
          onClickVoid={onAddLieferant}
          disabled={readonly}
        />
      </Grid>
      <DlgAnker />
    </Grid>
  )
}

export const PimArtikelLieferantenMemo = React.memo(PimArtikelLieferanten)
