import {
  ErpArtikelDetailsJson,
  PimArtikelVersionJson,
  SeArtikelJson,
  SparteDisplayJson,
  WarengruppeDisplayJson
} from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { StringArrayField } from '@utils/ui/fields/StringArrayField'
import { TextField } from '@utils/ui/fields/TextField'
import { resolveObjectField } from '@utils/utils'
import { StaticField } from '@utils/ui/fields/StaticField'
import Grid from '@mui/material/Grid2'
import React from 'react'
import { WarengruppeField } from '@one/components/Warengruppe/WarengruppeField'
import { formatSparte, formatWarengruppe } from '@one/components/common/WarengruppeCell'
import { SpartenField } from '@one/components/Warengruppe/SparteField'

export interface PimArtikelKennzeichenProps {
  pimArtikelVersion: PimArtikelVersionJson
  details?: ErpArtikelDetailsJson | null
  onChange?: ValueChangeFn<SeArtikelJson>
  errors?: any
  mode?: 'readonly' | 'edit' | 'amend'
  warengruppe?: WarengruppeDisplayJson
  sparte?: SparteDisplayJson
}

/**
 * PimArtikelKennzeichen wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikelVersion
 * @param warengruppe
 * @param sparte
 * @param onChange
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelKennzeichen = ({
  pimArtikelVersion,
  warengruppe,
  sparte,
  onChange,
  errors,
  mode
}: PimArtikelKennzeichenProps) => {
  return (
    <CardEx title="Kennzeichen">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Materialgruppe"
            value={pimArtikelVersion.materialgruppe}
            name="pimArtikel.version.materialgruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.materialgruppe')}
            disabled={mode !== 'edit'}
            fullWidth
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="BDB-Warengruppe"
            value={pimArtikelVersion.bdbWarengruppe}
            name="pimArtikel.version.bdbWarengruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.bdbWarengruppe')}
            disabled={mode !== 'edit'}
            fullWidth
          />
        </Grid>

        {mode === 'readonly' ? (
          <>
            <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
              <StaticField
                label="Basis-Warengruppe"
                value={formatWarengruppe(pimArtikelVersion.zeBasisWarengruppe)}
                name="pimArtikel.version.zeBasisWarengruppe"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
              <StaticField
                label="Warengruppe"
                value={formatWarengruppe(warengruppe)}
                name="warengruppe"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
              <StaticField label="Sparte" value={formatSparte(sparte)} name="sparte" />
            </Grid>
          </>
        ) : (
          <>
            <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
              <WarengruppeField
                label="Basis-Warengruppe"
                value={pimArtikelVersion.zeBasisWarengruppe}
                name="pimArtikel.version.zeBasisWarengruppe"
                onChange={onChange}
                error={resolveObjectField(errors, 'pimArtikel.version.zeBasisWarengruppe')}
                auchLeere
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
              <WarengruppeField
                label="Warengruppe"
                value={warengruppe}
                onChange={onChange}
                name="warengruppe"
                gfMitStatistik
                auchLeere
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
              <SpartenField value={sparte} name="sparte" onChange={onChange} fullWidth />
            </Grid>
          </>
        )}
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Farbgruppe"
            value={pimArtikelVersion.farbgruppe}
            name="pimArtikel.version.farbgruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.farbgruppe')}
            disabled={mode !== 'edit'}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <NumberField
            label="Anzahl Packstuecke"
            value={pimArtikelVersion.anzahlPackstuecke}
            name="pimArtikel.version.anzahlPackstuecke"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.anzahlPackstuecke')}
            fraction={0}
            disabled={mode !== 'edit'}
            fullWidth
          />
        </Grid>
        {/*<Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>*/}
        {/*  <NumberField*/}
        {/*    label="Grundpreismenge"*/}
        {/*    value={pimArtikelVersion.grundpreismenge}*/}
        {/*    name="pimArtikel.version.grundpreismenge"*/}
        {/*    onChange={onChange}*/}
        {/*    error={resolveObjectField(errors, 'pimArtikel.version.grundpreismenge')}*/}
        {/*    disabled={readonly}*/}
        {/*    fraction={0}*/}
        {/*    fullWidth*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <TextField
            label="Intrastat-Mengeneinheit"
            value={pimArtikelVersion.intrastatMengeneinheit}
            name="pimArtikel.version.intrastatMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.intrastatMengeneinheit')}
            disabled={mode !== 'edit'}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          {mode != 'edit' ? (
            <StaticField
              value={pimArtikelVersion.regalplatzierungen}
              label="Regalplatzierungen"
              name="regalplatzierungen"
            />
          ) : (
            <StringArrayField
              label="Regalplatzierungen"
              value={pimArtikelVersion.regalplatzierungen}
              name="pimArtikel.version.regalplatzierungen"
              onChange={onChange}
              /* error={resolveObjectField(errors, 'pimArtikel.version.regalplatzierungen')} */
              fullWidth
            />
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Arena-Kennzeichen"
            checked={pimArtikelVersion.arenaKennzeichen}
            name="pimArtikel.version.arenaKennzeichen"
            onChange={onChange}
            disabled={mode !== 'edit'}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Grundpreisauszeichnungspflicht"
            checked={pimArtikelVersion.grundpreisauszeichnungspflicht}
            name="pimArtikel.version.grundpreisauszeichnungspflicht"
            onChange={onChange}
            disabled={mode !== 'edit'}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Ohne Verkaufspreis"
            checked={pimArtikelVersion.ohneVerkaufspreis}
            name="pimArtikel.version.ohneVerkaufspreis"
            onChange={onChange}
            disabled={mode !== 'edit'}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Preisbindung"
            checked={pimArtikelVersion.preisbindung}
            name="pimArtikel.version.preisbindung"
            onChange={onChange}
            disabled={mode !== 'edit'}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Rabattfähig"
            checked={pimArtikelVersion.rabattfaehig}
            name="pimArtikel.version.rabattfaehig"
            onChange={onChange}
            disabled={mode !== 'edit'}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="RundungschemaRelevanz"
            checked={pimArtikelVersion.rundungschemaRelevanz}
            name="pimArtikel.version.rundungschemaRelevanz"
            onChange={onChange}
            disabled={mode !== 'edit'}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Skontofaehig"
            checked={pimArtikelVersion.skontofaehig}
            name="pimArtikel.version.skontofaehig"
            onChange={onChange}
            disabled={mode !== 'edit'}
            labelNotDisabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            name="ohneVerkaufspreis"
            label="ohne Verkaufspreis"
            checked={pimArtikelVersion.ohneVerkaufspreis}
            disabled={mode !== 'edit'}
            labelNotDisabled
            indeterminate
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            name="eigenmarke"
            label="Eigenmarke"
            checked={pimArtikelVersion.eigenmarke}
            disabled={mode !== 'edit'}
            labelNotDisabled
            indeterminate
          />
        </Grid>
        {/*Redundant zu PimArtikelKopf <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
          <Checkbox
            label="Baustein-Kennzeichen"
            name="bausteinKennzeichen"
            checked={pimArtikelVersion.bausteinKennzeichen}
            disabled={mode !== "edit"}
            labelNotDisabled
            indeterminate
          />
        </Grid>*/}
      </Grid>
    </CardEx>
  )
}

export const PimArtikelKennzeichenMemo = React.memo(PimArtikelKennzeichen)
