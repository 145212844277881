import { Sync, SyncProblem } from '@mui/icons-material'
import { Alert, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { api } from '@one/api'
import { ChangedRabattgruppenTreeView } from '@one/components/common/ChangedRabattgruppenTreeView'
import { CompositeAttributeChangeTreeView } from '@one/components/common/CompositeAttributeChangeTreeView'
import {
  isCompositeAttributeChangeEmpty,
  swapAndCleanCompositeAttributeChange
} from '@one/components/common/utils'
import {
  AboQuellewechselJson,
  CompositeAttributeChange,
  EkKonditionenDefinitionJson,
  EkKonditionenRabattgruppeVersionChangeJson,
  LieferantDisplayJson,
  LieferantKonflikteJson,
  SuchLieferantTyp
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { CardEx } from '@utils/ui/CardEx'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { useEffect, useMemo, useRef, useState } from 'react'

export type LieferantAenderungenPanelProps = {
  aenderungen?: boolean
  konflikt?: boolean
  lieferantSyncEinstId: number | null
  lieferant: LieferantDisplayJson | null
  dzLieferant: LieferantDisplayJson | null
  changedAttributes?: CompositeAttributeChange | null
  changedRabattgruppen?: EkKonditionenRabattgruppeVersionChangeJson[]
  konditionDefinition?: EkKonditionenDefinitionJson
  quellenWechsel?: AboQuellewechselJson
}

export const LieferantAenderungenPanel = ({
  aenderungen,
  konflikt,
  lieferant,
  dzLieferant,
  lieferantSyncEinstId,
  changedAttributes,
  changedRabattgruppen,
  konditionDefinition,
  quellenWechsel
}: LieferantAenderungenPanelProps) => {
  const [changedAttributesLoaded, setChangedAttributesLoaded] =
    useState<CompositeAttributeChange>(null)
  const [loadError, setLoadError] = useState<string>()
  const lastLieferantSyncEinstIdRef = useRef(lieferantSyncEinstId)
  const lastLieferantRef = useRef(lieferant)
  const lastDzLieferantRef = useRef(dzLieferant)

  const [apiCall, apiBusy] = useApiCaller(api)

  useEffect(() => {
    if (
      lastLieferantSyncEinstIdRef.current !== lieferantSyncEinstId ||
      lastDzLieferantRef.current !== dzLieferant ||
      lastLieferantRef.current !== lieferant
    ) {
      lastLieferantSyncEinstIdRef.current = lieferantSyncEinstId
      lastDzLieferantRef.current = dzLieferant
      lastLieferantRef.current = lieferant
      if (lieferant != null && dzLieferant != null && lieferantSyncEinstId != null) {
        apiCall<LieferantKonflikteJson>({
          method: 'GET',
          exclusive: ApiExclusive.CANCEL,
          rest: '/lieferant/lieferantKonflikte',
          params: {
            zeLieferantId: lieferant.id,
            dzLieferantId: dzLieferant.id,
            mapperEinstellungId: lieferantSyncEinstId
          },
          onSuccess: (data) => {
            setChangedAttributesLoaded(data.konflikte)
            setLoadError(null)
          },
          onError: (state) => {
            setLoadError(state.mainMessage?.message)
            return true
          }
        })
      } else {
        setChangedAttributesLoaded(null)
        setLoadError(null)
      }
    }
  }, [apiCall, dzLieferant, lieferant, lieferantSyncEinstId, setChangedAttributesLoaded])

  const composite = useMemo(
    () => swapAndCleanCompositeAttributeChange(changedAttributesLoaded || changedAttributes),
    [changedAttributes, changedAttributesLoaded]
  )

  if (
    !aenderungen &&
    !konflikt &&
    isCompositeAttributeChangeEmpty(composite) &&
    !changedRabattgruppen?.length &&
    !apiBusy
  ) {
    return null
  }

  const labelNeu = lieferant?.typ === SuchLieferantTyp.ABO ? 'ALZ:' : 'LST:'
  const labelAlt = 'ERP:'
  const wasListung = quellenWechsel?.von?.quelle === 'Listung'

  const itemLoadError = loadError ? (
    <Grid size={{ md:2 }}>
      <>
        <Typography>Fehler:</Typography>
        <Typography>{loadError}</Typography>
      </>
    </Grid>
  ) : null

  const itemUnbekannt =
    !loadError &&
    isCompositeAttributeChangeEmpty(composite) &&
    !changedRabattgruppen?.length &&
    !quellenWechsel ? (
      <Grid size={{ md:12 }}>
        <CardEx
          title="Unbekannte Änderungen"
          avatar={konflikt ? <SyncProblem /> : <Sync />}
          height="100%"
        >
          <Typography>
            {konflikt
              ? 'Es gibt nicht näher spezifizierte Konflikte'
              : 'Es gibt nicht näher spezifizierte Änderungen'}
          </Typography>
        </CardEx>
      </Grid>
    ) : null

  const itemComposite = !isCompositeAttributeChangeEmpty(composite) ? (
    <Grid size={{ md:4 }}>
      <CardEx
        title="Attributänderungen"
        avatar={konflikt ? <SyncProblem /> : <Sync />}
        height="100%"
      >
        <CompositeAttributeChangeTreeView
          composite={composite}
          labelAlt={labelAlt}
          labelNeu={labelNeu}
        />
      </CardEx>
    </Grid>
  ) : null

  const itemQuellenwechsel = quellenWechsel ? (
    <Grid size={{ md:4 }}>
      <CardEx title="Quellenwechsel" avatar={<Sync />} height="100%">
        {!wasListung && (
          <Alert severity="warning">
            Anpassung durch Beendigung der Versorgung durch die Allianz
          </Alert>
        )}
        <CompositeAttributeChangeTreeView
          composite={{
            attributes: [
              {
                label: 'Quelle',
                newString: quellenWechsel.nach.quelle,
                oldString: quellenWechsel.von.quelle
              }
            ]
          }}
          labelAlt="Alt:"
          labelNeu="Neu:"
        />
      </CardEx>
    </Grid>
  ) : null

  const itemRabattgruppen =
    changedRabattgruppen?.length > 0 ? (
      <Grid size={{ md:4 }}>
        <CardEx title="Geänderte EK-Rabattgruppen" avatar={<Sync />} height="100%">
          <ChangedRabattgruppenTreeView
            changedRabattgruppen={changedRabattgruppen}
            konditionDefinition={konditionDefinition}
          />
        </CardEx>
      </Grid>
    ) : null

  return (
    <WaitPlane wait={apiBusy}>
      <Grid container direction="row" height="100%" spacing={1}>
        {itemLoadError}
        {itemUnbekannt}
        {itemQuellenwechsel}
        {itemComposite}
        {itemRabattgruppen}
      </Grid>
    </WaitPlane>
  )
}
