import { Grid2 as Grid } from '@mui/material'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { TextField } from '@utils/ui/fields/TextField'
import {useContext, useMemo} from 'react'
import { WarengruppeSearcherCriteriaJson } from '@one/typings/apiTypings'
import { UploadAccepts, UploadButton } from '@utils/ui/Buttons/UploadButton'
import { api } from '@one/api'
import {AppContext} from "@utils/ui/App/AppContext";

export interface WarengruppenUebersichtParamsProps {
  value: WarengruppeSearcherCriteriaJson | null
  onSearch: () => void
  onChange: ChangeFunction<WarengruppeSearcherCriteriaJson>
}

export const WarengruppenUebersichtParams = ({
  value,
  onSearch,
  onChange
}: WarengruppenUebersichtParamsProps) => {
  const { isPIMModel } = useContext(AppContext)

  const actions = useMemo(
    () => [
      isPIMModel && (
        <UploadButton
          key="upload"
          path="warengruppe/upload"
          api={api}
          label="Importliste hochladen"
          tooltip="EIne Excel- oder CSV-Tabelle mit Warengruppen für das ERP System hochladen. Die Tabelle muss zwei Spalten haben: Nummer und Bezeichnung ohne Headerzeile"
          variant="text"
          accept={UploadAccepts.Tabellen}
        />
      ),
      <SearchButton key="search" onClick={() => onSearch()} />
    ],
    [isPIMModel, onSearch]
  )

  return (
    <ShortCutHandler shortcuts={{ Enter: onSearch }}>
      <CardEx backLink title="Warengruppen" bottomActions={actions}>
        <Grid container spacing={2} direction="column">
          <Grid size={{ xs: 12, sm: 3, md: 2, lg: 2 }}>
            <Checkbox
              label="Listung"
              name="listung"
              checked={value.listung}
              onChange={onChange}
              size="small"
            />
            <Checkbox
              label="ERP"
              name="dezentral"
              checked={value.dezentral}
              onChange={onChange}
              size="small"
            />
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid size={{ xs: 12, sm: 3, md: 2, lg: 2 }}>
              <TextField
                name="nummer"
                label="Nummer"
                value={value.nummer}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 3, md: 2, lg: 2 }}>
              <TextField
                name="name"
                label="Name"
                value={value.name}
                onChange={onChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
