import { AppPaths } from './AppPaths'
import { UserRoles } from './UserRoles'
import { AllianzDatenBereitstellungView } from './components/Allianz/AllianzDatenBereitstellung/AllianzDatenBereitstellungView'
import { AllianzVerteilerMatrixEditor } from './components/Allianz/AllianzVerteilerMatrix/AllianzVerteilerMatrixEditor'
import { ArtikelView } from './components/Artikel/ArtikelAnzeigen/ArtikelView'
import { ArtikelUebersichtView } from './components/Artikel/ArtikelUebersicht/ArtikelUebersichtView'
import { EigenlistungsArtikelPflegeView } from './components/Artikel/EigenlistungsArtikel/EigenlistungsArtikelPflegeView'
import { ArtikelAboView } from './components/ArtikelAbo/ArtikelAbo/ArtikelAboView'
import { ArtikelAboUebersichtView } from './components/ArtikelAbo/ArtikelAboUebersicht/ArtikelAboUebersichtView'
import { ArtikelArtPflegeView } from './components/ArtikelArt/ArtikelArtPflegeView'
import { ArtikelEtikettendruckView } from './components/ArtikelEtikettendruck/ArtikelEtikettendruckView'
import { ArtikelLieferantSyncTemplatePflegeView } from './components/ArtikelLieferantSyncTemplate/ArtikelLieferantSyncTemplatePflege/ArtikelLieferantSyncTemplatePflegeView'
import { ArtikelLieferantSyncTemplateUebersichtView } from './components/ArtikelLieferantSyncTemplate/ArtikelLieferantSyncTemplateUebersicht/ArtikelLieferantSyncTemplateUebersichtView'
import { ArtikelSyncTemplateUebersichtView } from './components/ArtikelLieferantSyncTemplate/ArtikelLieferantSyncTemplateUebersicht/ArtikelSyncTemplateUebersichtView'
import { ArtikelListeView } from './components/ArtikelListe/ArtikelListePflege/ArtikelListeView'
import { ArtikelListeUebersichtView } from './components/ArtikelListe/ArtikelListeUebersicht/ArtikelListeUebersichtView'
import { ArtikelAboSelektionView } from './components/ArtikelSelektion/ArtikelSelektionView'
import { ArtikelVerteilerView } from './components/ArtikelVerteiler/ArtikelVerteiler/ArtikelVerteilerView'
import { ArtikelVerteilerUebersichtView } from './components/ArtikelVerteiler/ArtikelVerteilerUebersicht/ArtikelVerteilerUebersichtView'
import { Dashboard } from './components/Dashboard/Dashboard'
import { ArtikelDatenPoolUebersichtView } from './components/Datenpool/ArtikelDatenpoolUebersichtView'
import { EkPreispflegeView } from './components/EkPreis/EKPreisPflege/EkPreispflegeView'
import { EkPreisAnlageView } from './components/EkPreis/EkPreisAnlage/EkPreisAnlageView'
import { EkPreisExportDialog } from './components/EkPreis/EkPreisExport/EkPreisExportDialog'
import { EkPreisImportKonfigurationView } from './components/EkPreis/EkPreisImportKonfiguration/EkPreisImportKonfigurationView'
import { EkPreisImportPflegeView } from './components/EkPreis/EkPreisImportPflege/EkPreisImportPflegeView'
import { EkPreisImportUebersichtView } from './components/EkPreis/EkPreisImportUebersicht/EkPreisImportUebersichtView'
import { EkPreisKonditionsschemaPflegeView } from './components/EkPreis/EkPreisKonditionsschemaPflege/EkPreisKonditionsschemaPflegeView'
import { EkPreisMassenPflegeView } from './components/EkPreis/EkPreisMassenPflege/EkPreisMassePflegeView'
import { EkPreisUebersichtView } from './components/EkPreis/EkPreisUebersicht/EkPreisUebersichtView'
import { EkRabattgruppenMassenPflegeView } from './components/EkRabattgruppen/EkRabattgruppenMassenPflege/EkRabattgruppenMassenPflegeView'
import { EkRabattgruppenPflegeView } from './components/EkRabattgruppen/EkRabattgruppenPflege/EkRabattgruppenPflegeView'
import { EkRabattgruppenUebersichtView } from './components/EkRabattgruppen/EkRabattgruppenUebersicht/EkRabattgruppenUebersichtView'
import { EosMigrationView } from './components/EosMigration/EosMigrationView'
import { ExportToErpView } from './components/ExportToErp/ExportToErpView'
import { HauptlieferantPflegeView } from './components/Hauptlieferant/HauptlieferantPflegeView'
import { LabelView } from './components/Label/LabelView'
import { EigenlistungsLieferantPflegeView } from './components/Lieferant/EigenlistungsLieferant/EigenlistungsLieferantPflegeView'
import { AboLieferantView } from './components/Lieferant/Lieferant/AboLieferantView'
import { DzLieferantView } from './components/Lieferant/Lieferant/DzLieferantView'
import { ZeLieferantView } from './components/Lieferant/Lieferant/ZeLieferantView'
import { LieferantenUebersichtView } from './components/Lieferant/LieferantenUebersicht/LieferantenUebersichtView'
import { LieferantAboView } from './components/LieferantAbo/LieferantAbo/LieferantAboView'
import { LieferantAboUebersichtView } from './components/LieferantAbo/LieferantAboUebersicht/LieferantAboUebersichtView'
import { LieferantenListeView } from './components/LieferantenListe/LieferantenListePflege/LieferantenListeView'
import { LieferantenListeUebersichtView } from './components/LieferantenListe/LieferantenListeUebersicht/LieferantenListeUebersichtView'
import { LieferantenMappingUebersichtView } from './components/LieferantenMapping/LieferantenMappingView'
import { LieferantenVerteilerView } from './components/LieferantenVerteiler/LieferantenVerteiler/LieferantenVerteilerView'
import { LieferantenVerteilerUebersichtView } from './components/LieferantenVerteiler/LieferantenVerteilerUebersicht/LieferantenVerteilerUebersichtView'
import { PreisAenderungenView } from './components/PreisAenderungen/PreisAenderungenView'
import { AllianzDatenBereitstellungUebersichtView } from './components/Protokolle/AllianzDatenBereitstellungUebersicht/AllianzDatenBereitstellungUebersichtView'
import { AllianzDatenFehlerView } from './components/Protokolle/AllianzDatenFehler/AllianzDatenFehlerView'
import { ExportArtikelFehlerUebersichtView } from './components/Protokolle/ExportFehlerUebersicht/ExportArtikelFehlerUebersichtView'
import { ExportEkKonditionenFehlerUebersichtView } from './components/Protokolle/ExportFehlerUebersicht/ExportEkKonditionenFehlerUebersichtView'
import { ExportLieferantFehlerUebersichtView } from './components/Protokolle/ExportFehlerUebersicht/ExportLieferantFehlerUebersichtView'
import { ExportStandortArtikelFehlerUebersichtView } from './components/Protokolle/ExportFehlerUebersicht/ExportStandortArtikelFehlerUebersichtView'
import { ExportVkPreisFehlerUebersichtView } from './components/Protokolle/ExportFehlerUebersicht/ExportVKPreisFehlerUebersichtView'
import { ExportVkSperreFehlerUebersichtView } from './components/Protokolle/ExportFehlerUebersicht/ExportVkSperreFehlerUebersichtView'
import { ErpExportsUebersichtView } from './components/Protokolle/ExportToErpUebersicht/ExportToErpUebersichtView'
import { ImportArtikelFehlerUebersichtView } from './components/Protokolle/ImportFehlerUebersicht/ImportArtikelFehlerUebersichtView'
import { ImportEkKonditionenFehlerUebersichtView } from './components/Protokolle/ImportFehlerUebersicht/ImportEkKonditionenFehlerUebersichtView'
import { ImportLieferantFehlerUebersichtView } from './components/Protokolle/ImportFehlerUebersicht/ImportLieferantFehlerUebersichtView'
import { ImportVkPreisFehlerUebersichtView } from './components/Protokolle/ImportFehlerUebersicht/ImportVkPreisFehlerUebersichtView'
import { ProtokollDetailsView } from './components/Protokolle/ProtokollDetails/ProtokollDetailsView'
import { ProtokolleUebersichtView } from './components/Protokolle/ProtokolleUebersicht/ProtokolleUebersichtView'
import { SortimentView } from './components/Sortiment/SortimentView'
import { StandortePflegeView } from './components/Standortgruppe/StandortPflegeView'
import { StandortgruppeView } from './components/Standortgruppe/StandortgruppePflege/StandortgruppeView'
import { StandortGruppeUbersichtView } from './components/Standortgruppe/StandortgruppeUebersicht/StandortgruppeUebersichtView'
import { UmsatzsteuerOverview } from './components/Umsatzsteuer/UmsatzsteuerOverview'
import { UmsatzsteuerView } from './components/Umsatzsteuer/UmsatzsteuerView'
import { VkPreisAnlageView } from './components/VkPreis/VkPreisAnlage/VkPreisAnlageView'
import { VkPreisMassenPflegeView } from './components/VkPreis/VkPreisMassenPflege/VkPreisMassePflegeView'
import { VkPreispflegeView } from './components/VkPreis/VkPreisPflege/VkPreispflegeView'
import { VkPreisUebersichtView } from './components/VkPreis/VkPreisUebersicht/VkPreisUebersichtView'
import { VkPreisgruppenRegelnView } from './components/VkPreis/VkPreisgruppenRegelnView/VkPreisgruppenRegelnView'
import { AdminView } from './components/admin/AdminView/AdminView'
import { GesellschafterAllianzPflegeView } from './components/admin/Allianz/GesellschafterAllianzPflege/GesellschafterAllianzPflegeView'
import { DataView } from './components/admin/DataView/DataView'
import { GesellschafterUebersichtView } from './components/admin/Gesellschafter/GesellschafterUebersicht/GesellschafterUebersichtView'
import { FirmaAnlageView } from './components/admin/Gesellschafter/Mandanten/Firma/FirmaAnlageView'
import { OrganisationAnlageView } from './components/admin/Gesellschafter/Mandanten/Organisation/OrganisationAnlageView'
import { PreisEbenenPflegeView } from './components/admin/Gesellschafter/PreisEbenen/PreisEbenenPflegeView'
import { LogView } from './components/admin/LogView'
import { AppRouteDefinition } from '@utils/ui/App/AppRouteMgr'
import { WarengruppenUebersichtView } from "@one/components/Warengruppe/WarengruppenUebersichtView";
import { WarengruppenMappingView } from "@one/components/Warengruppe/WarengruppenMappingView";

// TODO: use createHashRouter from react-router-v6 https://reactrouter.com/en/main/routers/create-hash-router
export const AppRoutes: AppRouteDefinition[] = [
  {
    label: 'Start',
    // icon: Dashboard
    path: '/dashboard',
    component: Dashboard
  },
  {
    admin: 'only',
    label: 'Dashboard',
    path: AppPaths.AdminView,
    component: AdminView,
    role: UserRoles.ADMIN
  },
  {
    admin: 'only',
    label: 'Gesellschafter und Firmen',
    path: AppPaths.Gesellschafter,
    component: GesellschafterUebersichtView,
    role: UserRoles.ADMIN
  },
  {
    admin: 'only',
    path: AppPaths.OrganisationView,
    component: OrganisationAnlageView,
    role: UserRoles.ADMIN
  },
  {
    admin: 'only',
    path: AppPaths.FirmaView,
    component: FirmaAnlageView,
    role: UserRoles.ADMIN
  },
  {
    admin: 'only',
    path: AppPaths.GesellschafterAllianzView,
    component: GesellschafterAllianzPflegeView,
    role: UserRoles.ADMIN
  },
  {
    id: 'artikel',
    label: 'Artikel',
    defaultExpanded: false,
    items: [
      {
        label: 'Artikel',
        path: AppPaths.ArtikelUebersicht,
        component: ArtikelUebersichtView
      },
      {
        windowTitle: 'Artikel',
        path: AppPaths.Artikel,
        component: ArtikelView
      },
      {
        path: AppPaths.artikelliste.ArtikelListePflege,
        component: ArtikelListeView
      },
      {
        label: 'Artikelabos',
        path: AppPaths.ArtikelAboUebersicht,
        component: ArtikelAboUebersichtView
      },
      {
        windowTitle: 'Artikelabo',
        path: AppPaths.ArtikelAboPflege,
        component: ArtikelAboView
      },
      {
        windowTitle: 'Artikelselektion',
        path: AppPaths.ArtikelAboSelektionPflege,
        component: ArtikelAboSelektionView
      },
      {
        label: 'Artikellisten',
        path: AppPaths.artikelliste.ArtikelListeUebersicht,
        component: ArtikelListeUebersichtView
      },
      {
        windowTitle: 'Artikel-Label',
        label: 'Artikel-Label',
        path: AppPaths.LabelListe,
        component: LabelView
      },
      {
        windowTitle: 'Artikeldatenpool',
        label: 'Artikeldatenpool',
        path: AppPaths.ArtikelDatenPoolUebersicht,
        component: ArtikelDatenPoolUebersichtView,
        disabled: (appContext) => !appContext.isDatapoolNEON,
        role: UserRoles.ARTIKELDATENPOOL_EDITOR
      },
      {
        windowTitle: "Warengruppen",
        label: "Warengruppen",
        path: AppPaths.WarengruppenUebersicht,
        component: WarengruppenUebersichtView
      }
    ]
  },
  {
    id: 'artikelEtiketten',
    windowTitle: 'Artikeletikettendruck',
    component: ArtikelEtikettendruckView,
    path: AppPaths.ArtikelEtikettenDruck
  },
  {
    id: 'lieferanten',
    label: 'Lieferanten',
    defaultExpanded: false,
    items: [
      {
        label: 'Lieferanten',
        path: AppPaths.LieferantUebersicht,
        component: LieferantenUebersichtView
      },
      {
        windowTitle: 'ERP-Lieferant',
        path: AppPaths.Lieferant,
        component: DzLieferantView
      },
      {
        windowTitle: 'Listungslieferant',
        path: AppPaths.LieferantListung,
        component: ZeLieferantView
      },
      {
        windowTitle: 'Allianzlieferant',
        path: AppPaths.LieferantAllianz,
        component: AboLieferantView
      },

      {
        windowTitle: 'Hauptlieferanten',
        path: AppPaths.HauptlieferantPflege,
        component: HauptlieferantPflegeView
      },
      {
        label: 'Lieferantenabos',
        path: AppPaths.LieferantAboUebersicht,
        component: LieferantAboUebersichtView
      },
      {
        windowTitle: 'Lieferantenabo',
        path: AppPaths.LieferantAboPflege,
        component: LieferantAboView
      },
      {
        label: 'Lieferantenlisten',
        path: AppPaths.lieferanteliste.LieferantenListeUebersicht,
        component: LieferantenListeUebersichtView
      },
      {
        path: AppPaths.lieferanteliste.LieferantenListePflege,
        component: LieferantenListeView
      },
      {
        path: AppPaths.EigenlistungsLieferantPflege,
        component: EigenlistungsLieferantPflegeView,
        disabled: (appContext) => !appContext.isPIMModel,
        role: UserRoles.EIGENPFLEGE_EDITOR
      },
      {
        path: AppPaths.EigenlistungsArtikelPflege,
        component: EigenlistungsArtikelPflegeView,
        disabled: (appContext) => !appContext.isPIMModel,
        role: UserRoles.EIGENPFLEGE_EDITOR
      }
    ]
  },
  {
    path: AppPaths.Preisaenderungen,
    component: PreisAenderungenView
  },
  {
    id: 'ekpreise',
    label: 'EK-Preise',
    defaultExpanded: false,
    items: [
      {
        label: 'Einkaufspreise',
        path: AppPaths.EkPreisUebersicht,
        component: EkPreisUebersichtView,
        // role: UserRoles.EKPREIS_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        label: 'Import Einkaufspreise',
        path: AppPaths.EkPreisImportUbersicht,
        component: EkPreisImportUebersichtView,
        // role: UserRoles.EKPREIS_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        windowTitle: 'Einkaufspreiseimport',
        path: AppPaths.EkPreisImportPflege,
        component: EkPreisImportPflegeView,
        // role: UserRoles.EKPREIS_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        windowTitle: 'Einkaufspreisliste anlegen',
        path: AppPaths.EkPreisAnlage,
        component: EkPreisAnlageView,
        // role: UserRoles.EKPREIS_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        windowTitle: 'Einkaufspreis',
        path: AppPaths.EkPreisPflege,
        component: EkPreispflegeView,
        // role: UserRoles.EKPREIS_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        windowTitle: 'Einkaufspreise',
        path: AppPaths.EkPreisMassenPflege,
        component: EkPreisMassenPflegeView,
        // role: UserRoles.EKPREIS_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        windowTitle: 'EK-Rabattgruppen',
        label: 'EK-Rabattgruppen',
        path: AppPaths.EkRabattgruppenUebersicht,
        component: EkRabattgruppenUebersichtView,
        // role: { required: [UserRoles.EKRABATTGRUPPEN_EDITOR, UserRoles.EKPREIS_EDITOR] }
        role: UserRoles.EKRABATTGRUPPEN_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        windowTitle: 'EK-Rabattgruppe',
        path: AppPaths.EkRabattgruppenPflege,
        component: EkRabattgruppenPflegeView,
        // role: { required: [UserRoles.EKRABATTGRUPPEN_EDITOR, UserRoles.EKPREIS_EDITOR] }
        role: UserRoles.EKRABATTGRUPPEN_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        windowTitle: 'EK-Rabattgruppen',
        path: AppPaths.EkRabattgruppenMassenPflege,
        component: EkRabattgruppenMassenPflegeView,
        // role: { required: [UserRoles.EKRABATTGRUPPEN_EDITOR, UserRoles.EKPREIS_EDITOR] }
        role: UserRoles.EKRABATTGRUPPEN_EDITOR,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        label: 'EK-Preis-Export',
        dialog: EkPreisExportDialog,
        disabled: (appContext) => !appContext.isWithEkPreis
      }
    ]
  },
  {
    label: 'Verkaufspreise',
    path: AppPaths.VkPreisUebersicht,
    // role: UserRoles.VKPREIS_EDITOR,
    component: VkPreisUebersichtView,
    disabled: (appContext) => appContext.isAllianz || !appContext.isWithVkPreis
  },
  {
    windowTitle: 'Verkaufspreisliste anlegen',
    path: AppPaths.VkPreisAnlage,
    // role: UserRoles.VKPREIS_EDITOR,
    component: VkPreisAnlageView,
    disabled: (appContext) => appContext.isAllianz || !appContext.isWithVkPreis
  },
  {
    windowTitle: 'Verkaufspreisliste kopieren',
    path: AppPaths.VkPreisAnlageClone,
    // role: UserRoles.VKPREIS_EDITOR,
    component: VkPreisAnlageView,
    disabled: (appContext) => appContext.isAllianz || !appContext.isWithVkPreis
  },
  {
    windowTitle: 'Verkaufspreis',
    path: AppPaths.VkPreisPflege,
    // role: UserRoles.VKPREIS_EDITOR,
    component: VkPreispflegeView,
    disabled: (appContext) => appContext.isAllianz || !appContext.isWithVkPreis
  },
  {
    windowTitle: 'Verkaufspreise',
    path: AppPaths.VkPreisMassenPflege,
    // role: UserRoles.VKPREIS_EDITOR,
    component: VkPreisMassenPflegeView,
    disabled: (appContext) => appContext.isAllianz || !appContext.isWithVkPreis
  },
  {
    label: 'ERP Export',
    path: AppPaths.ExportToErpUebersicht,
    component: ExportToErpView,
    disabled: (appContext) => appContext.isWithoutErp,
    nocache: true
  },
  {
    id: 'allianz',
    label: 'Allianz',
    items: [
      {
        label: 'Allianzdatenbereitstellung',
        path: AppPaths.AllianzDatenBereitstellungView,
        component: AllianzDatenBereitstellungView,
        disabled: (appContext) => !appContext.isAllianz,
        nocache: true
      },
      {
        windowTitle: 'Allianzverteilermatrix',
        label: 'Allianzverteilermatrix',
        path: AppPaths.AllianzVerteilerMatrixEditor,
        component: AllianzVerteilerMatrixEditor,
        disabled: (appContext) => !appContext.isAllianz
      }
    ]
  },
  {
    path: AppPaths.ExportExc,
    component: ExportToErpView
  },
  {
    path: AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflege,
    component: ArtikelLieferantSyncTemplatePflegeView,
    admin: 'both'
  },
  {
    id: 'protokolle',
    label: 'Protokolle',
    items: [
      {
        label: 'Jobs',
        path: '/protokolle/uebersicht',
        component: ProtokolleUebersichtView,
        admin: 'both'
      },
      {
        label: 'ERP Exporte',
        path: AppPaths.ErpExportsUebersicht,
        component: ErpExportsUebersichtView,
        disabled: (appContext) => appContext.isWithoutErp,
        nocache: true
      },
      {
        label: 'Allianzdatenbereitstellungen',
        path: AppPaths.AllianzDatenBereitstellungUebersichtView,
        component: AllianzDatenBereitstellungUebersichtView,
        disabled: (appContext) => !appContext.isAllianz,
        nocache: true
      },
      {
        id: 'erpimportfehler',
        label: 'ERP Importfehler',
        disabled: (appContext) =>
          appContext.isWithoutErp || !appContext.isWithErpImport || appContext.isPIMModel,
        items: [
          {
            label: 'Artikel',
            path: AppPaths.ImportArtikelFehler,
            component: ImportArtikelFehlerUebersichtView
          },
          {
            label: 'Lieferant',
            path: AppPaths.ImportLieferantFehler,
            component: ImportLieferantFehlerUebersichtView
          },
          {
            label: 'EK-Konditionen',
            path: AppPaths.ImportEkKonditionenFehler,
            component: ImportEkKonditionenFehlerUebersichtView
          },
          {
            label: 'VK-Preis',
            path: AppPaths.ImportVkPreisFehler,
            component: ImportVkPreisFehlerUebersichtView
          }
        ]
      },
      {
        id: 'erpexportfehler',
        label: 'ERP Exportfehler',
        disabled: (appContext) => appContext.isWithoutErp,
        items: [
          {
            label: 'Artikel',
            path: AppPaths.ExportArtikelFehler,
            component: ExportArtikelFehlerUebersichtView
          },
          {
            label: 'Lieferant',
            path: AppPaths.ExportLieferantFehler,
            component: ExportLieferantFehlerUebersichtView
          },
          {
            label: 'Standortartikel',
            disabled: (appContext) => !appContext.isPIMModel,
            path: AppPaths.ExportStandortArtikelFehler,
            component: ExportStandortArtikelFehlerUebersichtView
          },
          {
            label: 'EK-Konditionen',
            path: AppPaths.ExportEkKonditionenFehler,
            component: ExportEkKonditionenFehlerUebersichtView,
            disabled: (appContext) => !appContext.isWithEkPreis
          },
          {
            label: 'VK-Preis',
            path: AppPaths.ExportVkPreisFehler,
            component: ExportVkPreisFehlerUebersichtView,
            disabled: (appContext) => !appContext.isWithVkPreis
          },
          {
            label: 'VK-Sperre',
            path: AppPaths.ExportVkSperreFehler,
            component: ExportVkSperreFehlerUebersichtView,
            disabled: (appContext) => !appContext.isPIMModel
          }
        ]
      }
    ]
  },
  {
    path: AppPaths.ProtokollDetailsView,
    component: ProtokollDetailsView,
    admin: 'both'
  },
  {
    path: AppPaths.AllianzDatenFehlerView,
    component: AllianzDatenFehlerView,
    nocache: true
  },
  {
    id: 'verteilung',
    label: 'Verteilung',
    defaultExpanded: false,
    items: [
      {
        label: 'Artikelverteiler',
        path: AppPaths.artikelverteiler.ArtikelVerteilerUebersicht,
        component: ArtikelVerteilerUebersichtView,
        disabled: (appContext) => !appContext.isInterCompany
      },
      {
        path: AppPaths.artikelverteiler.ArtikelVerteilerPflege,
        component: ArtikelVerteilerView,
        disabled: (appContext) => !appContext.isInterCompany
      },
      {
        label: 'Lieferantenverteiler',
        path: AppPaths.lieferantenverteiler.LieferantenVerteilerUebersicht,
        component: LieferantenVerteilerUebersichtView,
        disabled: (appContext) => !appContext.isInterCompany
      },
      {
        path: AppPaths.lieferantenverteiler.LieferantenVerteilerPflege,
        component: LieferantenVerteilerView,
        disabled: (appContext) => !appContext.isInterCompany
      }
    ]
  },
  {
    id: 'sortiment',
    label: 'Sortiment',
    defaultExpanded: false,
    items: [
      {
        windowTitle: 'Gobale EH VK-Preisgruppenregeln',
        label: 'Globale EH-VK-PG-Regeln',
        path: AppPaths.VkPreisgruppenRegeln,
        component: VkPreisgruppenRegelnView,
        disabled: (appContext) => !appContext.isSortimente
      },
      {
        // Achtung; für !isSortimente im anderen menu!
        windowTitle: 'Standorte',
        disabled: (ctx) => !ctx.isSortimente,
        forgetWhenDisabled: true,
        label: 'Standorte',
        path: AppPaths.StandortePflege,
        component: StandortePflegeView,
        role: [UserRoles.STANDORT_EDITOR]
      },
      {
        windowTitle: 'Standortgruppen',
        label: 'Standortgruppen',
        path: AppPaths.StandortgruppeUebersicht,
        component: StandortGruppeUbersichtView,
        role: [UserRoles.STANDORT_EDITOR],
        disabled: (appContext) => !appContext.isSortimente
      },
      {
        windowTitle: 'Standortgruppe',
        path: AppPaths.StandortgruppePflege,
        component: StandortgruppeView,
        role: [UserRoles.STANDORT_EDITOR],
        disabled: (appContext) => !appContext.isSortimente
      },
      {
        windowTitle: 'Sortiment',
        path: AppPaths.SortimentPflege,
        component: SortimentView,
        disabled: (appContext) => !appContext.isSortimente
      }
    ]
  },
  {
    id: 'einstellungen',
    label: 'Einstellungen',
    defaultExpanded: false,
    items: [
      {
        label: 'LiSST/ArSST',
        path: AppPaths.synchronisation.ArtikelLieferantSyncTemplateUebersicht,
        component: ArtikelLieferantSyncTemplateUebersichtView
      },
      {
        label: 'Vordefinierte ArSST',
        path: AppPaths.synchronisation.ArtikelSyncTemplateUebersicht,
        component: ArtikelSyncTemplateUebersichtView,
        admin: 'only',
        role: UserRoles.ADMIN
      },
      {
        label: 'Ignorierte Artikelarten',
        path: AppPaths.ArtikelArt,
        component: ArtikelArtPflegeView,
        role: [UserRoles.GS_EIN_EDITOR]
      },
      {
        label: 'EK-Preis-Import Schritte',
        path: AppPaths.EkPreisImportKonfiguration,
        component: EkPreisImportKonfigurationView,
        role: [UserRoles.EKPREISIMPORTSTEP_EDITOR],
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        label: 'EK-Kalkulationsschema',
        path: AppPaths.EkPreisKalkulationsschema,
        component: EkPreisKonditionsschemaPflegeView,
        // role: [UserRoles.GS_EIN_EDITOR],
        disabled: (appContext) => !appContext.isWithEkPreis || !appContext.isPIMModel
      },
      {
        windowTitle: 'Mengeneinheit-Mapping',
        label: 'Mengeneinheit-Mapping',
        path: AppPaths.LieferantenMengenMapping,
        component: LieferantenMappingUebersichtView
      },
      {
        windowTitle: 'Preisebenen',
        label: 'Preisebenen',
        path: AppPaths.PreisEbenenPflege,
        component: PreisEbenenPflegeView,
        disabled: (appContext) => !appContext.isWithEkPreis
      },
      {
        // Achtung; für isSortimente im anderen menu!
        windowTitle: 'Standorte',
        disabled: (ctx) => ctx.isSortimente,
        forgetWhenDisabled: true,
        label: 'Standorte',
        path: AppPaths.StandortePflege,
        component: StandortePflegeView,
        role: [UserRoles.STANDORT_EDITOR]
      },
      {
        label: 'Umsatzsteuer',
        path: AppPaths.Umsatzsteuer.UmsatzsteuerOverview,
        component: UmsatzsteuerOverview,
        admin: 'only',
        role: UserRoles.ADMIN
      },
      {
        path: AppPaths.Umsatzsteuer.UmsatzsteuerEdit,
        component: UmsatzsteuerView,
        admin: 'only',
        role: UserRoles.ADMIN
      },
      {
        label: "BDB zu ERP Warengruppen Mapping",
        path: AppPaths.WarengruppenMapping,
        component: WarengruppenMappingView
      }
    ]
  },
  {
    id: 'system',
    label: 'System',
    items: [
      {
        label: 'Log-Meldungen',
        path: AppPaths.LogView,
        component: LogView,
        admin: 'both',
        role: [UserRoles.ADMIN, UserRoles.LOG_VIEWER]
      },
      {
        label: 'Datenordner',
        path: AppPaths.DataView,
        component: DataView,
        admin: 'both',
        role: [UserRoles.ADMIN, UserRoles.DATA_VIEWER]
      },
      {
        label: 'EOS Migration',
        path: AppPaths.EosMigration,
        component: EosMigrationView,
        role: UserRoles.EOS_MIGRATION_OP
      }
    ]
  }
]
