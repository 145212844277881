import { WerteCell } from '@one/components/common/DataPreisCell'
import { usePreisEbenenCache } from '@one/datacaches/usePreisEbenenCache'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelVkBlattJson,
  MengeneinheitDisplayTinyJson,
  MengeneinheitJson,
  VkPreisGruppeJson,
  VkPreisJson,
  VkPreisQuelle
} from '@one/typings/apiTypings'
import { formatDateRange } from '@utils/dateutils'
import { useEnums } from '@utils/enums'
import { compareNumber, formatNumber } from '@utils/numberutils'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable, RowGrouper } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { ThemeContext } from '@utils/ui/Theme'
import { buildMap, compareStrings, formatInteger, formatMoney2 } from '@utils/utils'
import { useContext, useMemo } from 'react'
import { Medal } from '@utils/ui/fields/Medal'
import Grid from '@mui/material/Grid2'
import { Typography } from '@mui/material'

export interface ArtikelErpVkPreiseProps {
  vkPreise: ArtikelVkBlattJson[]
  preisGruppen?: VkPreisGruppeJson[]
  mengeneinheiten: MengeneinheitDisplayTinyJson[]
}

export interface VkPreisStaffelJson {
  grouperId?: string
  standortId?: number
  quelle?: VkPreisQuelle
  quelleStaffel?: VkPreisQuelle
  forEinzelhandel?: boolean
  kassenpreisgruppeId?: number
  gueltigVon?: string
  gueltigBis?: string
  preisGruppeId?: number
  needsExportErp?: boolean
  verkaufME?: MengeneinheitJson
  verkaufPreismenge?: number
  preise: VkPreisJson[]
}

export const ArtikelErpVkPreise = ({
  vkPreise,
  preisGruppen,
  mengeneinheiten
}: ArtikelErpVkPreiseProps) => {
  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)
  const { data: preisEbenen } = usePreisEbenenCache()
  const preisGruppenMap = useMemo(() => buildMap(preisGruppen, (i) => i.id), [preisGruppen])
  const mengeneinheitenMap = useMemo(
    () => buildMap(mengeneinheiten, (i) => i.id),
    [mengeneinheiten]
  )
  const groupedPreiseByPGs = useMemo(() => {
    const grouped = vkPreise
      .map(
        (blatt) =>
          Object.values(
            blatt.preise.reduce((acc, preis) => {
              const key = blatt.id + '-' + preis.preisGruppeId
              let pg = acc[key] as VkPreisStaffelJson
              if (pg == null) {
                pg = {
                  grouperId: blatt.standortId + '-' + blatt.gueltigVon,
                  standortId: blatt.standortId,
                  gueltigVon: blatt.gueltigVon,
                  gueltigBis: blatt.gueltigBis,
                  preisGruppeId: preis.preisGruppeId,
                  quelle: blatt.quelle,
                  quelleStaffel: blatt.quelleStaffel,
                  forEinzelhandel: blatt.forEinzelhandel,
                  kassenpreisgruppeId: blatt.referenzPreisgruppeId,
                  needsExportErp: blatt.needsExportErp,
                  verkaufME: blatt.verkaufME,
                  verkaufPreismenge: blatt.verkaufPreismenge,
                  preise: []
                } as VkPreisStaffelJson
                acc[key] = pg
              }
              pg.preise.push(preis)
              return acc
            }, {} as any)
          ) as VkPreisStaffelJson[]
      )
      .flatMap((x) => x)

    grouped.forEach((x) => x.preise.sort((a, b) => compareNumber(a.staffelmenge, b.staffelmenge)))

    grouped.sort((a, b) => {
      let rc = compareNumber(a.standortId, b.standortId)
      if (rc == 0) {
        const pgA = preisGruppenMap.get(a.preisGruppeId)
        const pgB = preisGruppenMap.get(b.preisGruppeId)
        rc = compareStrings(pgA?.name, pgB?.name)
      }
      return rc
    })

    return grouped
  }, [preisGruppenMap, vkPreise])

  const columns = useMemo((): Column<VkPreisStaffelJson>[] => {
    const noFormat = (val) => val
    const cols: Column<VkPreisStaffelJson>[] = [
      {
        field: 'preise.preisGruppeId',
        header: 'Preisgruppe',
        width: '3em',
        body: (wert) => (
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <span>{`${preisGruppenMap.get(wert.preisGruppeId)?.name}`}</span>
            {
              /*wert.forEinzelhandel &&*/ wert.preisGruppeId === wert.kassenpreisgruppeId ? (
                <Grid>
                  <Medal text="ZKasse" />
                </Grid>
              ) : null
            }
          </Grid>
        )
      },
      {
        field: 'preise.brutto',
        header: 'Brutto',
        width: '3em',
        align: 'center',
        type: 'boolean',
        valueGetter: (row) => row.preise?.map((p) => p.brutto)
      },
      {
        field: 'preise.fix',
        header: 'Fix',
        width: '3em',
        align: 'center',
        valueGetter: (row) => row.preise?.map((p) => p.fix)
      },
      {
        field: 'preise.staffelmenge',
        header: 'Staffelmenge',
        width: '3em',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.staffelmenge),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="staffelmenge"
            formatter={formatInteger}
            darkMode={darkMode}
          />
        )
      },
      {
        field: 'preise.basisTyp',
        header: 'BasisTyp',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => et(HkmEnum.VkPreisBasisTyp, p.basisTyp)),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="basisTyp"
            formatter={(val) => et(HkmEnum.VkPreisBasisTyp, val)}
            darkMode={darkMode}
            align="left"
          />
        )
      },
      {
        field: 'preise.etikettpreis',
        header: 'Etikettpreis',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.etikettpreis),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="etikettpreis"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        field: 'preise.basis',
        header: 'Basis',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.basis),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="basis"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        field: 'preise.preis',
        header: 'Preis',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.preis),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="preis"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        field: 'preise.spanne',
        header: 'Spanne',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.spanne),
        body: (row) => (
          <WerteCell werte={row.preise} field="spanne" formatter={noFormat} darkMode={darkMode} />
        )
      },
      {
        field: 'preise.aufschlag',
        header: 'Aufschlag',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.aufschlag),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="aufschlag"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        field: 'preise.staffelRabattAbs',
        header: 'R-Abs',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.staffelRabattAbs),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="staffelRabattAbs"
            formatter={formatMoney2}
            darkMode={darkMode}
          />
        )
      },
      {
        field: 'preise.staffelRabattProz',
        header: 'R-Prz %',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.staffelRabattProz),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="staffelRabattProz"
            formatter={noFormat}
            darkMode={darkMode}
          />
        )
      },
      {
        field: 'preise.staffelTyp',
        header: 'Staffel-Typ',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => et(HkmEnum.VkPreisStaffelTyp, p.staffelTyp)),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="staffelTYp"
            formatter={(val) => et(HkmEnum.VkPreisStaffelTyp, val)}
            darkMode={darkMode}
            align="left"
          />
        )
      },
      {
        field: 'preise.vpeinheitId',
        header: 'VPE',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => mengeneinheitenMap.get(p.vpeinheitId)?.kurz),
        body: (row) => (
          <WerteCell
            werte={row.preise}
            field="vpeinheitId"
            formatter={(vpeinheitId) => mengeneinheitenMap.get(vpeinheitId)?.kurz}
            darkMode={darkMode}
            align="left"
          />
        )
      },
      {
        field: 'preise.vpmenge',
        header: 'Ab VPE',
        cellPadding: 0,
        valueGetter: (row) => row.preise?.map((p) => p.vpmenge),
        body: (row) => (
          <WerteCell werte={row.preise} field="vpmenge" formatter={noFormat} darkMode={darkMode} />
        )
      }
    ]

    return cols
  }, [darkMode, et, mengeneinheitenMap, preisGruppenMap])

  const grouper: RowGrouper<VkPreisStaffelJson> = {
    field: 'grouperId',
    valueGetter: (row) => {
      const pe = preisEbenen?.find((pe) => pe.id === row.standortId)
      if (pe == null) {
        return null
      }

      let text =
        pe.nr + '-' + (pe.alias ?? pe.name) + ' ' + formatDateRange(row.gueltigVon, row.gueltigBis)

      text += ', Preiseinheit ' + formatNumber(row.verkaufPreismenge) + ' ' + row.verkaufME?.kurz

      if (row.forEinzelhandel) {
        const quelle = row.quelle ?? VkPreisQuelle.LISTUNG
        text += ', Quelle: ' + et(HkmEnum.VkPreisQuelle, quelle)
        if (quelle != row.quelleStaffel && row.quelleStaffel != null) {
          text += ', Quelle Staffel: ' + et(HkmEnum.VkPreisQuelle, row.quelleStaffel)
        }
      }

      if (row.needsExportErp) {
        text += ' --- ERP Export ausstehend!'
      }
      return text
    }
  }

  return (
    <CardEx name="ErpVkPreise" title="VK Preise">
      <ScrollPanel autoHeight autoHeightMax="420px">
        <DataTable
          name="ErpVkPreiseTable"
          dense
          columns={columns}
          value={groupedPreiseByPGs}
          emptyMessage="Keine Daten"
          groupBy={grouper}
        />
        <div style={{ height: 20 }} />
      </ScrollPanel>
    </CardEx>
  )
}
