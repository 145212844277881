import { Add, Close, LockOutlined, QuestionMark } from '@mui/icons-material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { EkKonditionenRabattVerwendung } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { SelectField } from '@utils/ui/fields/SelectField'

export interface RabattverwendungFieldProps {
  label?: string
  value?: any
  name?: string
  onChange?: (e: any) => void
  readonly?: boolean
  required?: boolean
  withText?: boolean
}

const options = [
  EkKonditionenRabattVerwendung.NUR_RABATTGRUPPE,
  EkKonditionenRabattVerwendung.ADDITIV_ABS_RABATT,
  EkKonditionenRabattVerwendung.IGNORIERE_ABS_RABATT
]

export const RabattverwendungField = ({
  label,
  withText = false,
  value,
  name,
  onChange,
  readonly,
  required
}: RabattverwendungFieldProps) => {
  const { et } = useEnums()

  const handleChange = (e: { target: { value: any } }) => {
    if (onChange) {
      onChange(name ? { name, value: e.target.value } : e.target.value)
    }
  }

  const renderIcon = (v: EkKonditionenRabattVerwendung | null) => {
    switch (v) {
      case EkKonditionenRabattVerwendung.NUR_RABATTGRUPPE:
        return <LockOutlined fontSize="small" />
      case EkKonditionenRabattVerwendung.ADDITIV_ABS_RABATT:
        return <Add fontSize="small" />
      case EkKonditionenRabattVerwendung.IGNORIERE_ABS_RABATT:
        return <Close fontSize="small" />
      default:
        return <QuestionMark fontSize="small" />
    }
  }

  const render = (v: EkKonditionenRabattVerwendung | null, dotext = true) => {
    const icon = renderIcon(v)
    const text = et(HkmEnum.EkKonditionenRabattVerwendung, v)
    return (
      <TooltipWrapper title={text} arrow>
        <span>{icon}</span>
        {dotext ? <span style={{ marginLeft: 8, verticalAlign: 'top' }}>{text}</span> : null}
      </TooltipWrapper>
    )
  }

  const renderSelected = (v: EkKonditionenRabattVerwendung | null) => {
    const body = render(v, withText)
    return <div style={{ marginBottom: -4 }}>{body}</div>
  }

  if (readonly && label == null) {
    if (value == null) {
      return null
    }

    return renderSelected(value)
  }

  return (
    <SelectField
      label={label}
      value={value}
      options={options}
      onChange={handleChange}
      optionValue={(a) => a}
      renderItem={render}
      renderSelected={renderSelected}
      required={required}
      disabled={readonly}
      emptyText={withText ? undefined : ' '}
      fullWidth
    />
  )
}
