import {
  EkKonditionenDefinitionJson,
  EkKonditionenEbeneJson,
  EkKonditionenJson,
  EkKonditionenRabattgruppenViewJson,
  EkKonditionenRabattgruppeVersionJson,
  EkKonditionenRabattVerwendung,
  EkKonditionenWertJson,
  EkPreiseBearbeitenJson,
  PreisEbeneDisplayJson,
  StaffelgruppeDisplayJson
} from '@one/typings/apiTypings'
import { isAsyncTaskActive } from '@utils/asynctask'
import { compareDates } from '@utils/dateutils'
import { compareStrings, updateObjectField } from '@utils/utils'
import {
  EkKonditionenDefinitionEx,
  EkKonditionenEbeneEdit,
  EkKonditionenEdit,
  EkKonditionenWertEdit,
  EkPreiseBearbeitenEdit
} from './EkPreiseTypes'

const emptyEkKondiWert = {
  rabattgruppeId: null,
  sonderpreis: null,
  ekFreiHaus: null,
  ekBrutto: null,
  zuschlAbs1: null,
  zuschlAbs2: null,
  rabattProz1: null,
  rabattProz2: null,
  rabattProz3: null,
  rabattProz4: null,
  rabattProz5: null,
  zuschlAbs3: null,
  zuschlAbs4: null,
  zuschlAbs5: null
} as EkKonditionenWertJson

/**
 * Ermittelt, ob für das Preisblatt Sonderpreise rabattiert wurden oder wg. Artikelrabattgruppe werden
 *
 * @param neueKondi Preisblatt
 * @param kondiDefinition Kondidefinition
 * @returns true wenn Sonderreise rabattiert wurden oder wg. Artikelrabattgruppe werden
 */
const ermittleSonderpreisRabattierbarFlag = (
  neueKondi: EkKonditionenEdit,
  kondiDefinition: EkKonditionenDefinitionEx
): boolean => {
  return (
    kondiDefinition.allianz ||
    neueKondi.artikelRabattgruppeId != null ||
    neueKondi.konditionsEbenen.find((ke) =>
      ke.rabattgruppeId != null ||
      ke.werte?.find(
        (wert) =>
          wert.sonderpreis != null && //
          ((kondiDefinition.zuschlAbs1 && wert.zuschlAbs1) ||
            (kondiDefinition.zuschlAbs2 && wert.zuschlAbs2) ||
            (kondiDefinition.rabattProz1 && wert.rabattProz1) ||
            (kondiDefinition.rabattProz2 && wert.rabattProz2) ||
            (kondiDefinition.rabattProz3 && wert.rabattProz3) ||
            (kondiDefinition.rabattProz4 && wert.rabattProz4) ||
            (kondiDefinition.rabattProz5 && wert.rabattProz5) ||
            (kondiDefinition.zuschlAbs3 && wert.zuschlAbs3) ||
            (kondiDefinition.zuschlAbs4 && wert.zuschlAbs4) ||
            (kondiDefinition.zuschlAbs5 && wert.zuschlAbs5))
      )
    ) != null
  )
}

/**
 * Prüft, ob die Ebene abgeschlossen werden kann
 * @param neueKondi
 * @returns true/false
 */
export const pruefeAbschliessbar = (neueKondi: EkKonditionenEdit): boolean => {
  return (
    neueKondi.konditionsEbenen.find(
      (ke) =>
        ke.werte?.find((w) => w.standortEk && w.sonderpreis == null && w.listenpreis == null) !=
        null
    ) == null
  )
}

/**
 * Prüft, ob Staffelmengen werte haben
 *
 * @param kondiEbene
 * @returns Object mit {"staffelmenge": true/false}
 */
export const checkStaffelwerte = (kondiEbene: EkKonditionenEbeneEdit): object => {
  const usedStaffelwerte = kondiEbene?.werte.reduce((a, w) => {
    a[w.staffelmenge] =
      w.sonderpreis != null ||
      w.zuschlAbs1 ||
      w.zuschlAbs2 ||
      w.rabattProz1 ||
      w.rabattProz2 ||
      w.rabattProz3 ||
      w.rabattProz4 ||
      w.rabattProz5 ||
      w.zuschlAbs3 ||
      w.zuschlAbs4 ||
      w.zuschlAbs5
    return a
  }, {})

  return usedStaffelwerte
}

/**
 * Berechnet Zuschlagswert unter Berücksichtung der Rabattgruppe und Verwendung
 * @param zuschlAbs Zuschlag
 * @param zuschlAbsRG Zuschlag aus Rabattgruppe
 * @param verwendung Veerwendung
 * @returns berechneter Wert
 */
const calcZuschlag = (
  zuschlAbs: number | null,
  zuschlAbsRG: number | null,
  verwendung: EkKonditionenRabattVerwendung
) => {
  if (zuschlAbsRG != null) {
    switch (verwendung) {
      case EkKonditionenRabattVerwendung.ADDITIV_ABS_RABATT:
        return zuschlAbs ? zuschlAbsRG + zuschlAbs : zuschlAbsRG
      case EkKonditionenRabattVerwendung.IGNORIERE_ABS_RABATT:
        return zuschlAbs
      case EkKonditionenRabattVerwendung.NUR_RABATTGRUPPE:
        return zuschlAbsRG
    }
  }
  return zuschlAbs
}

/**
 * Formel zur Berechnung der EK-Preis-Freihaus
 * @param wert Wert(ezeile)
 * @param kd Kondiedefinition
 * @returns berechneter Wert
 */
export const calcEKPFH = (wert: EkKonditionenWertEdit, kd: EkKonditionenDefinitionEx) => {
  const zuschlAbs1 = calcZuschlag(
    wert.zuschlAbs1,
    wert.effektiveRabattgruppeWerte?.zuschlAbs1,
    wert.ebeneRabattVerwendung
  )

  const zuschlAbs2 = calcZuschlag(
    wert.zuschlAbs2,
    wert.effektiveRabattgruppeWerte?.zuschlAbs2,
    wert.ebeneRabattVerwendung
  )

  const zuschlAbs3 = calcZuschlag(
    wert.zuschlAbs3,
    wert.effektiveRabattgruppeWerte?.zuschlAbs3,
    wert.ebeneRabattVerwendung
  )

  const zuschlAbs4 = calcZuschlag(
    wert.zuschlAbs4,
    wert.effektiveRabattgruppeWerte?.zuschlAbs4,
    wert.ebeneRabattVerwendung
  )

  const zuschlAbs5 = calcZuschlag(
    wert.zuschlAbs5,
    wert.effektiveRabattgruppeWerte?.zuschlAbs5,
    wert.ebeneRabattVerwendung
  )

  const rabattProz1 =
    wert.effektiveRabattgruppeWerte == null
      ? wert.rabattProz1
      : wert.effektiveRabattgruppeWerte.rabattProz1

  const rabattProz2 =
    wert.effektiveRabattgruppeWerte == null
      ? wert.rabattProz2
      : wert.effektiveRabattgruppeWerte.rabattProz2

  const rabattProz3 =
    wert.effektiveRabattgruppeWerte == null
      ? wert.rabattProz3
      : wert.effektiveRabattgruppeWerte.rabattProz3

  const rabattProz4 =
    wert.effektiveRabattgruppeWerte == null
      ? wert.rabattProz4
      : wert.effektiveRabattgruppeWerte.rabattProz4

  const rabattProz5 =
    wert.effektiveRabattgruppeWerte == null
      ? wert.rabattProz5
      : wert.effektiveRabattgruppeWerte.rabattProz5

  const basispreis = wert.sonderpreis || wert.listenpreis || 0

  const z1 = basispreis + (kd.zuschlAbs1 && zuschlAbs1 ? zuschlAbs1 : 0)
  const z2 = z1 + (kd.zuschlAbs2 && zuschlAbs2 ? zuschlAbs2 : 0)
  const z3 = z2 - z2 * (kd.rabattProz1 && rabattProz1 ? rabattProz1 / 100 : 0)
  const z4 = z3 - z3 * (kd.rabattProz2 && rabattProz2 ? rabattProz2 / 100 : 0)
  const z5 = z4 - z4 * (kd.rabattProz3 && rabattProz3 ? rabattProz3 / 100 : 0)
  const z6 = z5 - z5 * (kd.rabattProz4 && rabattProz4 ? rabattProz4 / 100 : 0)
  const z7 = z6 - z6 * (kd.rabattProz5 && rabattProz5 ? rabattProz5 / 100 : 0)
  const z8 = z7 + (kd.zuschlAbs3 && zuschlAbs3 ? zuschlAbs3 : 0)
  const z9 = z8 + (kd.zuschlAbs4 && zuschlAbs4 ? zuschlAbs4 : 0)
  const z10 = z9 + (kd.zuschlAbs5 && zuschlAbs5 ? zuschlAbs5 : 0)

  return z10
}

/**
 * Berechnung der Differenz aus aktuellem und altem EK-Preis-Freihaus
 * @param wert Wertezeile
 * @returns Ergebnis
 */
const calcEKPFHDiff = (wert: EkKonditionenWertEdit) => {
  return wert.ekFreiHaus > 0 && wert.ekFreiHausOld > 0
    ? ((wert.ekFreiHaus - wert.ekFreiHausOld) / wert.ekFreiHausOld) * 100
    : null
}

/**
 * Aktualisiere Werte abhängig von Sonderpreis oder nicht
 *
 * @param model
 * @param kondiDefinition
 * @returns
 */
export const updateKondi = (
  model: EkKonditionenWertEdit,
  kondiDefinition: EkKonditionenDefinitionEx,
  sonderpreisRabattierbar: boolean,
  letzeRabattgruppe: number
) => {
  const m = { ...model } || ({} as EkKonditionenWertEdit)
  const kd = kondiDefinition || ({} as EkKonditionenDefinitionEx)
  const resetRabatte =
    model.ebeneRabattgruppeId != null &&
    model.ebeneRabattgruppeId !== letzeRabattgruppe &&
    model.effektiveRabattgruppeWerte != null

  if ((m.sonderpreis != null && !sonderpreisRabattierbar) || resetRabatte) {
    m.zuschlAbs1 = kondiDefinition.zuschlAbs1 || resetRabatte ? null : m.zuschlAbs1
    m.zuschlAbs2 = kondiDefinition.zuschlAbs2 || resetRabatte ? null : m.zuschlAbs2
    m.zuschlAbs3 = kondiDefinition.zuschlAbs3 || resetRabatte ? null : m.zuschlAbs3
    m.zuschlAbs4 = kondiDefinition.zuschlAbs4 || resetRabatte ? null : m.zuschlAbs4
    m.zuschlAbs5 = kondiDefinition.zuschlAbs5 || resetRabatte ? null : m.zuschlAbs5
    m.rabattProz1 = kondiDefinition.rabattProz1 || resetRabatte ? null : m.rabattProz1
    m.rabattProz2 = kondiDefinition.rabattProz2 || resetRabatte ? null : m.rabattProz2
    m.rabattProz3 = kondiDefinition.rabattProz3 || resetRabatte ? null : m.rabattProz3
    m.rabattProz4 = kondiDefinition.rabattProz4 || resetRabatte ? null : m.rabattProz4
    m.rabattProz5 = kondiDefinition.rabattProz5 || resetRabatte ? null : m.rabattProz5
  }

  if (m.sonderpreis != null && !sonderpreisRabattierbar) {
    m.ekFreiHaus = m.sonderpreis
    if (kd.sonderPreisZusch) {
      if (m.listenpreis == null) {
        m[kd.sonderPreisZusch] = null
      } else {
        m[kd.sonderPreisZusch] = -(m.listenpreis - m.sonderpreis)
      }
    }
  } else {
    m.ekFreiHaus = calcEKPFH(m, kondiDefinition)
    if (kd.sonderPreisZusch) {
      m[kd.sonderPreisZusch] = null
    }
  }
  m.ekFreiHausDiff = calcEKPFHDiff(m)
  return m
}

/**
 * Kombiniert zwei Listen von Staffelmengen in eine konsolidierte und sortierte
 *
 * @param last
 * @param werte
 * @returns
 */
export const joinStaffelmengen = (last: number[], werte: number[]) => {
  const result = [...(last || []), ...(werte || [])].filter((w, i, s) => s.indexOf(w) === i)
  result.sort((a, b) => a - b)
  return result
}

/**
 * Kennzeichen sonderpreisOnly aktualisieren
 *
 * @param werte
 * @param row0
 * @param kondiDefinition
 * @param sonderpreisRabattierbar
 * @returns
 */
export const adjustSonderpreisOnly = (
  werte: EkKonditionenWertEdit[],
  row0: EkKonditionenWertEdit | null,
  kondiDefinition: EkKonditionenDefinitionEx,
  sonderpreisRabattierbar: boolean
): EkKonditionenWertEdit[] => {
  if (row0 == null) {
    return werte
  }
  const sonderpreisOnly = row0.sonderpreis != null || row0.listenpreis == null

  return werte.map((wert) => {
    if (wert.sonderpreisOnly === sonderpreisOnly) {
      return wert
    }
    const cr1 = {
      ...wert,
      sonderpreisOnly
    }
    return updateKondi(cr1, kondiDefinition, sonderpreisRabattierbar, wert.ebeneRabattgruppeId)
  })
}

/**
 * Erweitere um alle fehlenden Orte und Staffeln
 *
 * @param kondi
 * @param readonly
 * @param preisEbenenMap
 * @param preisEbenen
 * @param staffelgruppen
 * @param kondiDefinition
 * @param level
 * @param last
 * @returns
 */
export const trimmKondis = (
  kondi: EkKonditionenEdit,
  readonly: boolean,
  preisEbenenMap: Map<number, PreisEbeneDisplayJson>,
  preisEbenen: PreisEbeneDisplayJson[],
  staffelgruppen: StaffelgruppeDisplayJson[],
  kondiDefinition: EkKonditionenDefinitionEx,
  level: number,
  last: EkKonditionenEdit = null
) => {
  if (kondi == null) {
    return
  }

  const makeLastWerteMap = (ke: EkKonditionenEbeneEdit) => {
    if (last == null) {
      return null
    }
    const neueKondiEbene = last.konditionsEbenen.find((i) => i.id === ke.id)
    return new Map(
      (neueKondiEbene?.werte || [])
        .filter((wert) => wert.standortEk === true)
        .map((wert) => [wert.staffelmenge, wert])
    )
  }

  kondi.warAbgeschlossen = kondi.abgeschlossen
  kondi.konditionsEbenen = kondi.konditionsEbenen || []

  // Expand auf alle Kondiebenen
  if (level === 0) {
    const widx = new Set(kondi.konditionsEbenen.map((ke) => ke.id))
    preisEbenen
      .filter((pe) => !pe.ausgeblendet)
      .forEach((stdort) => {
        if (!widx.has(stdort.id)) {
          const ke = {
            id: stdort.id,
            werte: []
          } as EkKonditionenEbeneEdit
          kondi.konditionsEbenen.push(ke)
        }
      })
  }

  kondi.konditionsEbenen.forEach((ke) => {
    const preisEbene = preisEbenenMap.get(ke.id)
    const staffelgruppe = staffelgruppen.find((sg) => sg.id === ke.staffelgruppeId)
    ke.werte = ke.werte || []
    ke.preisEbene = preisEbene
    ke.effektiveRabattgruppeId = kondi.artikelRabattgruppeId || ke.rabattgruppeId
    ke.staffelgruppeFlex = staffelgruppe?.flex
  })

  const ke0 = kondi.konditionsEbenen.find((it) => it.preisEbene?.defaultStandort)
  const row0 = ke0?.werte.find((it) => it.staffelmenge === 0)

  kondi.listenpreisEx = kondi.listenpreisAusAlterKondition || kondi.listenpreis

  kondi.konditionsEbenen.forEach((ke) => {
    const wlast = makeLastWerteMap(ke)

    let staffelmengen = joinStaffelmengen(
      [0],
      ke.werte.map((w) => w.staffelmenge)
    )

    if (ke.staffelgruppeId == null) {
      ke.staffelgruppeId = ke0?.staffelgruppeId
    }

    const staffelgruppe = staffelgruppen.find((sg) => sg.id === ke.staffelgruppeId)

    if (staffelgruppe != null) {
      if (level === 0 && !readonly) {
        staffelmengen = joinStaffelmengen(staffelmengen, staffelgruppe.staffelmengen)
      }
    }

    // Expand auf alle Staffelwerte
    if (level === 0 && !readonly) {
      const widx = new Set(ke.werte.map((wert) => wert.staffelmenge))
      staffelmengen.forEach((sm) => {
        if (!widx.has(sm)) {
          const wert = {
            standortEk: ke.preisEbene?.defaultStandort,
            standortId: ke.id,
            staffelmenge: sm
          } as EkKonditionenWertEdit
          ke.werte.push(wert)
        }
      })
    }

    ke.werte.forEach((wert) => {
      wert.standortEk = wert.id != null /*&& wert.level === 0*/ || ke.preisEbene?.defaultStandort
      wert.readonly = readonly
      wert.level = level
      wert.preisEbene = ke.preisEbene
      wert.listenpreis = wert.listenpreis != null ? wert.listenpreis : kondi.listenpreisEx
      wert.mengenEinheit = kondi.mengenEinheit
      wert.preismenge = kondi.preismenge
      wert.ekFreiHaus = calcEKPFH(wert, kondiDefinition)
      wert.standortEkOrg = wert.standortEk

      wert.ebeneDefaultstandort = ke.preisEbene?.defaultStandort
      wert.ebeneRabattVerwendung = ke.rabattVerwendung
      wert.ebeneRabattgruppeId = ke.rabattgruppeId
      wert.ebeneAllianzRabattgruppenId = ke.allianzRabattgruppeId
      wert.ebeneStaffelgruppeId = ke.staffelgruppeId

      if (wlast) {
        const wl = wlast.get(wert.staffelmenge)
        if (wl) {
          wl.ekFreiHausOld = wert.ekFreiHaus
          wl.ekFreiHausDiff = calcEKPFHDiff(wl)
          if (level === 1) {
            // Den 1. historischen alten mit auswählen
            wert.standortEk = wl.standortEk
          }
        }
      }
    })

    ke.werte = adjustSonderpreisOnly(ke.werte, row0, kondiDefinition, kondi.sonderpreisRabattierbar)
  })
}

/**
 * Standort-Kennzeichen füllen
 *
 * @param state
 * @param row
 * @param checked
 * @returns
 */
const setStandortEk = (
  state: EkPreiseBearbeitenEdit,
  row: EkKonditionenWertEdit,
  checked: boolean
): EkPreiseBearbeitenEdit => {
  const setAll = (werte: EkKonditionenWertEdit[], standortEk: boolean): EkKonditionenWertEdit[] =>
    werte.map((w) => {
      const cr0 = { ...w, standortEk }
      return updateKondi(
        cr0,
        state.kondiDefinition,
        state.neueKondi.sonderpreisRabattierbar,
        w.ebeneRabattgruppeId
      )
    })

  const ke = state.neueKondi.konditionsEbenen.find((it) => it.id === row.standortId)
  if (ke == null) {
    throw new Error('Ungültige row ' + row)
  }

  const cw = setAll(ke.werte, checked)
  state = updateObjectField(state, `neueKondi.konditionsEbenen.[id=${row.standortId}].werte`, cw)

  // Vorgänger auswählen
  const vergangeneListe = state.vergangeneListe
  if (vergangeneListe && vergangeneListe.length > 0) {
    const v = vergangeneListe[0]
    const vke = v.konditionsEbenen.find((it) => it.id === row.standortId)
    if (vke != null) {
      const vwerte = setAll(vke.werte, checked)
      state = updateObjectField(state, `vergangeneListe.[id=${row.standortId}].werte`, vwerte)
    }
  }

  return state
}

/**
 * Wert setzen und Wertezeile in aktuelle Ebene aktualisieren
 *
 * @param state
 * @param row
 * @param field
 * @param value
 * @returns
 */
const setKondiValue = (
  state: EkPreiseBearbeitenEdit,
  row: EkKonditionenWertEdit,
  field: string,
  value: any
) => {
  row = updateKondi(
    {
      ...row,
      [field]: value
    },
    state.kondiDefinition,
    state.neueKondi.sonderpreisRabattierbar,
    row.ebeneRabattgruppeId
  )

  state = updateObjectField(
    state,
    `neueKondi.konditionsEbenen.[id=${row.standortId}].werte.[staffelmenge=${row.staffelmenge}]`,
    row
  )

  const ke = state.neueKondi.konditionsEbenen.find((it) => it.id === row.standortId)
  if (ke == null) {
    console.error('Ungültige row', row, state)
    throw new Error('Ungültige row')
  }

  if (ke.preisEbene?.defaultStandort && row.staffelmenge === 0) {
    const ke0 = state.neueKondi.konditionsEbenen.find((it) => it.preisEbene?.defaultStandort)
    const row0 = ke0?.werte.find((it) => it.staffelmenge === 0)

    const neueKondi = {
      ...state.neueKondi,
      konditionsEbenen: state.neueKondi.konditionsEbenen.map((ke) => ({
        ...ke,
        werte: adjustSonderpreisOnly(
          ke.werte,
          row0,
          state.kondiDefinition,
          state.neueKondi.sonderpreisRabattierbar
        )
      }))
    }

    state = { ...state, neueKondi }
  }

  state.neueKondi.abgeschlossen =
    state.neueKondi.abgeschlossen && pruefeAbschliessbar(state.neueKondi)

  return state
}

/**
 * Aktualisiert die Rabattgruppen an den Werten als Redundanz
 *
 * @param state
 * @param prepareMode
 * @returns
 */
const updateEffektiveRabattgruppeWerte = (
  state: EkPreiseBearbeitenEdit,
  prepareMode?: boolean
): EkPreiseBearbeitenEdit => {
  const udpateKondi = (kondi: EkKonditionenEdit, isNeueKondi: boolean) => {

    const ke0 = kondi.konditionsEbenen.find((it) => it.preisEbene?.defaultStandort)
    const row0 = ke0?.werte.find((it) => it.staffelmenge === 0)
    return {
      ...kondi,
      konditionsEbenen: kondi.konditionsEbenen.map((ke) => {
        let keRabattgruppeVersion: EkKonditionenRabattgruppeVersionJson = null

        if (isNeueKondi) {
          const artikelRabattgruppe =
            kondi.artikelRabattgruppeId &&
            state.ekRabattgruppen.find((it) => it.id === kondi.artikelRabattgruppeId)

          const keRabattgruppeView =
            (ke.rabattgruppeId &&
              state.ekRabattgruppen.find((it) => it.id === ke.rabattgruppeId)) ||
            artikelRabattgruppe

          keRabattgruppeVersion = state.allianzModus
            ? keRabattgruppeView?.preisEbenenVersions?.find((v) => v.preisEbeneId === ke.id)
            : keRabattgruppeView?.version
        } else {
          const rgs = state.ekRabattgruppenHistorische
            .filter((it) => it.kopf.id === (kondi.artikelRabattgruppeId || ke.rabattgruppeId))
            .filter((it) => (state.allianzModus ? it.preisEbeneId === ke.id : true))
            .filter(
              (it) =>
                it.gueltigVon <= kondi.gueltigVon &&
                (it.gueltigBis == null || it.gueltigBis >= kondi.gueltigVon)
            )
          if (rgs.length > 0) {
            rgs.sort((a, b) => compareDates(b.gueltigVon, a.gueltigVon))
            keRabattgruppeVersion = rgs[0]
          }
        }

        const keRabattgruppe = keRabattgruppeVersion?.kopf
        const keRabattgruppeId: number = ke.rabattgruppeId || keRabattgruppe?.id

        const staffelgruppeId = keRabattgruppeVersion?.staffelgruppeId
          ? keRabattgruppeVersion?.staffelgruppeId
          : ke.staffelgruppeId

        const staffelgruppe =
          staffelgruppeId == null
            ? null
            : state.staffelgruppen?.find((sg) => sg.id === staffelgruppeId)

        const rabattstaffeln = keRabattgruppeVersion?.staffelwerte

        const hatStaffelgruppe =
          staffelgruppe?.staffelmengen?.length > 0 || rabattstaffeln?.length > 0

        let staffelmengen: number[] =
          rabattstaffeln?.map((rs) => rs.staffelmenge) || staffelgruppe?.staffelmengen
        if (staffelmengen == null || staffelmengen.length === 0) {
          staffelmengen = [0]
        } else if (staffelmengen.find((staffelmenge) => staffelmenge === 0) == null) {
          staffelmengen = [0, ...staffelmengen]
        }

        const rabattVerwendung =
          keRabattgruppeId != null
            ? ke.rabattVerwendung || EkKonditionenRabattVerwendung.NUR_RABATTGRUPPE
            : null

        const sonderpreisRabattierbar = kondi.sonderpreisRabattierbar

        const fehlendeStaffelwerte = isNeueKondi
          ? staffelmengen
              .filter(
                (staffelmenge) =>
                  ke.werte?.find((wert) => wert.staffelmenge === staffelmenge) == null
              )
              .map((staffelmenge) => {
                return updateKondi(
                  {
                    staffelmenge,
                    standortEkOrg: false,
                    standortId: ke.preisEbene?.id,
                    standortEk: ke.preisEbene?.defaultStandort,
                    preisEbene: ke.preisEbene,
                    level: 0,
                    listenpreis: kondi.listenpreisEx,
                    mengenEinheit: kondi.mengenEinheit,
                    preismenge: kondi.preismenge,
                    ebeneDefaultstandort: ke.preisEbene?.defaultStandort,
                    ebeneRabattVerwendung: ke.rabattVerwendung,
                    ebeneRabattgruppeId: ke.rabattgruppeId,
                    ebeneAllianzRabattgruppenId: ke.allianzRabattgruppeId,
                    ebeneStaffelgruppeId: ke.staffelgruppeId
                  },
                  state.kondiDefinition,
                  sonderpreisRabattierbar,
                  null
                )
              })
          : []

        const werte = ke.werte
          .concat(fehlendeStaffelwerte)
          .sort((a, b) => a.staffelmenge - b.staffelmenge)
          .map((wert) => {
            const rabattstaffelwerte = rabattstaffeln?.find(
              (it) => it.staffelmenge === wert.staffelmenge
            )
            if (
              hatStaffelgruppe &&
              staffelmengen.find((staffelmenge) => staffelmenge === wert.staffelmenge) == null &&
              staffelgruppe?.flex !== true
            ) {
              return null
            }

            const lastStaffelgruppenWerte = rabattstaffelwerte // || lastStaffelgruppenWerte
            const ebeneRabattgruppeId =
              wert.listenpreis != null || sonderpreisRabattierbar ? keRabattgruppeId : null

            return updateKondi(
              {
                ...wert,
                ebeneRabattVerwendung:
                  wert.listenpreis != null || sonderpreisRabattierbar ? rabattVerwendung : null,

                ebeneRabattgruppeId,

                ebeneStaffelgruppeId: staffelgruppeId,

                effektiveRabattgruppeWerte:
                  wert.listenpreis != null || sonderpreisRabattierbar
                    ? lastStaffelgruppenWerte
                    : null
              },
              state.kondiDefinition,
              sonderpreisRabattierbar,
              prepareMode ? ebeneRabattgruppeId : wert.ebeneRabattgruppeId
            )
          })
          .filter(Boolean)

        adjustSonderpreisOnly(werte, row0, state.kondiDefinition, sonderpreisRabattierbar)

        return {
          ...ke,
          effektiveRabattgruppeId: keRabattgruppeId,
          staffelgruppeId,
          effektiveStaffelgruppeId: staffelgruppeId,
          rabattVerwendung: rabattVerwendung,
          staffelgruppeFlex: staffelgruppe?.flex,
          werte
        }
      })
    }
  }

  return {
    ...state,
    neueKondi: udpateKondi(state.neueKondi, true),
    vergangeneListe: prepareMode
      ? state.vergangeneListe?.map((kondi) => udpateKondi(kondi, false))
      : state.vergangeneListe
  }
}

/**
 * Artikelrabattgruppe setzen, alle Konditionsebenen anpassen
 *
 * @param state
 * @param rabattgruppe
 * @returns
 */
const setArtikelEkRabattgruppe = (
  state: EkPreiseBearbeitenEdit,
  rabattgruppe: EkKonditionenRabattgruppenViewJson
): EkPreiseBearbeitenEdit => {
  // const needResetWerte = state.neueKondi.artikelRabattgruppeId == null && rabattgruppe?.id != null
  const neueKondi = {
    ...state.neueKondi,
    artikelRabattgruppeId: rabattgruppe?.id
  } as EkKonditionenEdit

  // if (needResetWerte) {
  //   neueKondi.konditionsEbenen = neueKondi.konditionsEbenen.map((ke) => ({
  //     ...ke,
  //     werte: xxxx
  //   }))
  // }

  neueKondi.sonderpreisRabattierbar = ermittleSonderpreisRabattierbarFlag(
    neueKondi,
    state.kondiDefinition
  )

  state = {
    ...state,
    neueKondi
  }

  state = updateEffektiveRabattgruppeWerte(state)

  return state
}

/**
 * EK-Rabattgruppe in Kondiebene setzen, Werte anpassen
 * @param state
 * @param kondiEbeneId
 * @param rabattgruppe
 * @returns
 */
const setEkRabattgruppe = (
  state: EkPreiseBearbeitenEdit,
  kondiEbeneId: number,
  rabattgruppe: EkKonditionenRabattgruppenViewJson
): EkPreiseBearbeitenEdit => {
  const neueKondi = {
    ...state.neueKondi,
    konditionsEbenen: state.neueKondi.konditionsEbenen.map((ke) => {
      if (ke.id !== kondiEbeneId) {
        return ke
      }
      return {
        ...ke,
        rabattgruppeId: rabattgruppe?.id
      } as EkKonditionenEbeneEdit
    })
  } as EkKonditionenEdit

  state = {
    ...state,
    neueKondi
  }

  state = updateEffektiveRabattgruppeWerte(state)

  return state
}

/**
 * Rabattverwendung in Kondiebene setzen
 *
 * @param state
 * @param kondiEbeneId
 * @param rabattVerwendung
 * @returns
 */
const setEkRabattverwendung = (
  state: EkPreiseBearbeitenEdit,
  kondiEbeneId: number,
  rabattVerwendung: EkKonditionenRabattVerwendung
): EkPreiseBearbeitenEdit => {
  let neueKondi = {
    ...state.neueKondi,
    konditionsEbenen: state.neueKondi.konditionsEbenen.map((ke) => {
      if (ke.id !== kondiEbeneId) {
        return ke
      }
      return {
        ...ke,
        rabattVerwendung,
        werte: ke.werte.map((wert) =>
          rabattVerwendung === EkKonditionenRabattVerwendung.NUR_RABATTGRUPPE
            ? {
                ...wert,
                ebeneRabattVerwendung: rabattVerwendung,
                rabattProz1: null,
                rabattProz2: null,
                rabattProz3: null,
                rabattProz4: null,
                zuschlAbs1: null,
                zuschlAbs2: null,
                zuschlAbs3: null,
                zuschlAbs4: null
              }
            : {
                ...wert,
                ebeneRabattVerwendung: rabattVerwendung,
                rabattProz1: null,
                rabattProz2: null,
                rabattProz3: null,
                rabattProz4: null
              }
        )
      }
    })
  } as EkKonditionenEdit

  state = {
    ...state,
    neueKondi
  }

  return state
}

/**
 * Staffelgruppe für Kondieebene setzen, werte an Staffel anpassen
 *
 * @param state
 * @param kondiEbeneId
 * @param staffelgruppeId
 * @returns
 */
const setStaffelgruppe = (
  state: EkPreiseBearbeitenEdit,
  kondiEbeneId: number,
  staffelgruppeId: number
): EkPreiseBearbeitenEdit => {
  let neueKondi = {
    ...state.neueKondi,
    konditionsEbenen: state.neueKondi.konditionsEbenen.map((ke) => {
      if (ke.id !== kondiEbeneId) {
        return ke
      }
      return {
        ...ke,
        staffelgruppeId
      }
    })
  } as EkKonditionenEdit

  state = {
    ...state,
    neueKondi
  }

  state = updateEffektiveRabattgruppeWerte(state)

  return state
}

/**
 * Werte der Ebene an die neuen Staffelwerte anpassen
 *
 * @param state
 * @param kondiEbeneId
 * @param staffelwerte
 * @returns
 */
const setStaffelwerte = (
  state: EkPreiseBearbeitenEdit,
  kondiEbeneId: number,
  staffelwerte: [number, number][]
): EkPreiseBearbeitenEdit => {
  const ke = state.neueKondi.konditionsEbenen.find((it) => it.id === kondiEbeneId)
  if (ke == null) {
    throw new Error('Invalid kondiEbeneId=' + kondiEbeneId)
  }

  const vke =
    state.vergangeneListe?.length >= 1
      ? state.vergangeneListe[0].konditionsEbenen.find((it) => it.id === kondiEbeneId)
      : null

  const wlast = new Map(
    (vke?.werte || [])
      .filter((wert) => wert.standortEk === true)
      .map((wert) => [wert.staffelmenge, wert])
  )

  const unchanged = ke.werte.filter(
    (w) => staffelwerte.find((s) => s[0] === w.staffelmenge && s[1] === w.staffelmenge) != null
  )

  const deleted = ke.werte.filter((w) => staffelwerte.find((s) => s[0] === w.staffelmenge) == null)

  const neue = staffelwerte
    .filter((s) => s[1] == null) // nur neue!
    .map((s) => s[0])
    .filter((s) => deleted.find((w) => w.staffelmenge === s) == null)

  let werte = unchanged

  neue.forEach((staffelmenge) => {
    const wert = {
      ...emptyEkKondiWert,
      standortId: ke.preisEbene?.id,
      standortEk: ke.preisEbene?.defaultStandort,
      preisEbene: ke.preisEbene,
      staffelmenge,
      level: 0,
      listenpreis: state.neueKondi.listenpreisEx,
      mengenEinheit: state.neueKondi.mengenEinheit,
      preismenge: state.neueKondi.preismenge,
      ebeneDefaultstandort: ke.preisEbene?.defaultStandort,
      ebeneRabattVerwendung: ke.rabattVerwendung,
      ebeneRabattgruppeId: ke.rabattgruppeId,
      ebeneAllianzRabattgruppenId: ke.allianzRabattgruppeId,
      ebeneStaffelgruppeId: ke.staffelgruppeId
    } as EkKonditionenWertEdit

    wert.ekFreiHaus = calcEKPFH(wert, state.kondiDefinition)
    const wl = wlast.get(staffelmenge)
    if (wl) {
      wl.ekFreiHausOld = wert.ekFreiHaus
      wl.ekFreiHausDiff = calcEKPFHDiff(wl)
    }

    werte.push(wert)
  })

  werte = werte.map((wert) => ({ ...wert, standortEk: true }))

  state = {
    ...state,
    neueKondi: {
      ...state.neueKondi,
      konditionsEbenen: state.neueKondi.konditionsEbenen.map((ke) =>
        ke.id === kondiEbeneId
          ? {
              ...ke,
              staffelgruppeId: ke.staffelgruppeId || state.staffelgruppeManuellId,
              werte
            }
          : ke
      )
    }
  }

  state = updateEffektiveRabattgruppeWerte(state)

  return state
}

/**
 * Hauptstaffel markieren
 * Für gegebene staffelmenge setzen/löschen, in allen anderen Werten löschen
 * @param state
 * @param kondiEbeneId
 * @param staffelmenge
 * @param checked
 * @returns state
 */
const setHauptstaffel = (
  state: EkPreiseBearbeitenEdit,
  kondiEbeneId: number,
  staffelmenge: number,
  checked: boolean
): EkPreiseBearbeitenEdit => {
  const ke = state.neueKondi.konditionsEbenen.find((it) => it.id === kondiEbeneId)
  if (ke == null) {
    throw new Error('Invalid kondiEbeneId=' + kondiEbeneId)
  }

  const mapStaffelmenge = (wert: EkKonditionenWertEdit): EkKonditionenWertEdit => {
    if (wert.staffelmenge === staffelmenge) {
      if (wert.hauptstaffel !== checked) {
        return { ...wert, hauptstaffel: checked }
      }
    } else if (wert.hauptstaffel) {
      return { ...wert, hauptstaffel: false }
    }
    return wert
  }

  return {
    ...state,
    neueKondi: {
      ...state.neueKondi,
      konditionsEbenen: state.neueKondi.konditionsEbenen.map((ke) =>
        ke.id === kondiEbeneId
          ? {
              ...ke,
              werte: ke.werte.map(mapStaffelmenge)
            }
          : ke
      )
    }
  }
}

/**
 * Aktuelle Ebene als abgeschlossen setzen, wenn möglich
 *
 * @param state Model
 * @param checked true/false
 * @returns Model
 */
const setEbeneAbgeschlossen = (
  state: EkPreiseBearbeitenEdit,
  checked: boolean
): EkPreiseBearbeitenEdit => {
  return {
    ...state,
    neueKondi: {
      ...state.neueKondi,
      abgeschlossen: checked && pruefeAbschliessbar(state.neueKondi)
    }
  }
}

const setSonderpreisRabattierbar = (
  state: EkPreiseBearbeitenEdit,
  checked: boolean
): EkPreiseBearbeitenEdit => {
  const copy = {
    ...state,
    neueKondi: {
      ...state.neueKondi,
      sonderpreisRabattierbar:
        checked || ermittleSonderpreisRabattierbarFlag(state.neueKondi, state.kondiDefinition)
    }
  }
  return updateEffektiveRabattgruppeWerte(copy)
}

const setStaffelEkRabattgruppe = (
  state: EkPreiseBearbeitenEdit,
  kondiEbeneId: number,
  staffelmenge: number,
  rabattgruppe: any
): EkPreiseBearbeitenEdit => {
  const copy = {
    ...state,
    neueKondi: {
      ...state.neueKondi,
      konditionsEbenen: state.neueKondi.konditionsEbenen.map((ke) =>
        ke.id === kondiEbeneId
          ? {
              ...ke,
              werte: ke.werte.map((wert) =>
                wert.staffelmenge === staffelmenge
                  ? { ...wert, rabattgruppeId: rabattgruppe?.id }
                  : wert
              )
            }
          : ke
      )
    }
  }
  return copy
}

/**
 * Usecase/Reducer
 */
export const EkPreiseUsecase = {
  SETEKRABATTGRUPPE: 'SETEKRABATTGRUPPE',
  SETSTAFFELGRUPPE: 'SETSTAFFELGRUPPE',
  SETEKRABATTVERWENDUNG: 'SETEKRABATTVERWENDUNG',
  SETKONDIVALUE: 'SETKONDIVALUE',
  SETSTANDORTEK: 'SETSTANDORTEK',
  SETARTIKELEKRABATTGRUPPE: 'SETARTIKELEKRABATTGRUPPE',
  SETSTAFFELWERTE: 'SETSTAFFELWERTE',
  SETEBENEABGESCHLOSSEN: 'SETEBENEABGESCHLOSSEN',
  SETHAUPTSTAFFEL: 'SETHAUPTSTAFFEL',
  SETSONDERPREISRABATTIERBAR: 'SETSONDERPREISRABATTIERBAR',
  SETSTAFFELEKRABATTGRUPPE: 'SETSTAFFELEKRABATTGRUPPE',

  reducer: (state: EkPreiseBearbeitenEdit, action: any) => {
    switch (action.type) {
      case EkPreiseUsecase.SETEBENEABGESCHLOSSEN:
        return setEbeneAbgeschlossen(state, action.value)
      case EkPreiseUsecase.SETHAUPTSTAFFEL:
        return setHauptstaffel(state, action.kondiEbeneId, action.staffelmenge, action.value)
      case EkPreiseUsecase.SETSTAFFELWERTE:
        return setStaffelwerte(state, action.kondiEbeneId, action.value)
      case EkPreiseUsecase.SETSTAFFELGRUPPE:
        return setStaffelgruppe(state, action.kondiEbeneId, action.value)
      case EkPreiseUsecase.SETEKRABATTGRUPPE:
        return setEkRabattgruppe(state, action.kondiEbeneId, action.value)
      case EkPreiseUsecase.SETEKRABATTVERWENDUNG:
        return setEkRabattverwendung(state, action.kondiEbeneId, action.value)
      case EkPreiseUsecase.SETARTIKELEKRABATTGRUPPE:
        return setArtikelEkRabattgruppe(state, action.value)
      case EkPreiseUsecase.SETKONDIVALUE:
        return setKondiValue(state, action.row, action.field, action.value)
      case EkPreiseUsecase.SETSTANDORTEK:
        return setStandortEk(state, action.row, action.checked)
      case EkPreiseUsecase.SETSONDERPREISRABATTIERBAR:
        return setSonderpreisRabattierbar(state, action.value)
      case EkPreiseUsecase.SETSTAFFELEKRABATTGRUPPE:
        return setStaffelEkRabattgruppe(
          state,
          action.kondiEbeneId,
          action.staffelmenge,
          action.value
        )
      default:
        console.error('Unexpected action', action)
        return state
    }
  }
}

/**
 * Validierung des Modells
 * @param model Modell
 * @returns Fehlermeldungen
 */
export const validateEkPreisModel = (model: EkPreiseBearbeitenEdit) => {
  const errors = {} as any
  if (model.allianzModus) {
    model.neueKondi?.konditionsEbenen?.forEach((ke) => {
      ke.werte
        ?.filter((w) => w.sonderpreis != null && w.effektiveRabattgruppeWerte != null)
        .forEach((w) => {
          errors[ke.preisEbene.id + '_' + w.staffelmenge] =
            'Bei Verwendung eines Sonderpreises darf nur eine leere oder keine Rabattgruppe gewählt werden!'
        })
    })
  }
  return errors
}

/**
 * Die Konditionsdefinition vollständig initialsieren, ggf. mit Defaults
 *
 * @param kondiDefinition
 * @param allianz
 * @returns
 */
export const enrichKondiDefinition = (
  kondiDefinition: EkKonditionenDefinitionJson,
  allianz: boolean
): EkKonditionenDefinitionEx => {
  const buildText = (text: string, unit: string = null) => `${text} ${unit}`

  const correctTooltip = (prefix:string, p1:string, p2:string, ekpfh:boolean)=> {
    let p3 = ekpfh ? '' : 'nicht EKPFH relevant';
    if(p1 ?? '' === p2 ?? '') {
      return `${prefix} ${p1} ${p3}`
    }
    return `${prefix ?? ''} ${p1 ?? ''} ${p2 ?? ''} ${p3 ?? ''}`;
  }

  const waehrung = kondiDefinition?.waehrung || '€'

  return {
    allianz,
    waehrung,
    standortLabel: allianz ? 'Preisebene' : 'Standort',
    standort1Label: allianz ? 'PE-Nr.' : 'Std-Nr.',
    standort1Tip: allianz ? 'Nummer der Preisebene' : 'Standortnummer',
    standort2Label: allianz ? 'PE-Name' : 'Std-Name',
    standort2Tip: allianz ? 'Name der Preisebene' : 'Standortname',
    rabattgruppeLabel: 'EK-Rabattgruppe',
    staffelRabattgruppeLabel: 'EK-RG Staffel',
    standortEkLabel: allianz ? 'Aktiv' : 'Std-Ek',
    standortEkTip: allianz ? 'Aktivierte Preisebenen' : 'Aktivierte Standorte',
    staffelmengeLabel: 'Staffel',
    staffelmengeTip: 'Staffelmenge',
    listenpreisLabel: buildText('L-Preis', waehrung),
    listenpreisTip: buildText('Listenpreis', waehrung),
    sonderpreisLabel: buildText('S-Preis', waehrung),
    sonderpreisTip: buildText('Sonderpreis', waehrung),
    ekKondiFreiHausLabel: buildText('EKPFH', waehrung),
    ekKondiFreiHausTip: buildText('EK frei Haus', waehrung),
    ekKondiFreiDiffLabel: 'Delta %',
    ekKondiFreiDiffTip: 'EK frei Haus Veränderung in %',
    hauptstaffelLabel: 'HS',
    hauptstaffelTip: 'Hauptstaffel',
    //
    zuschlAbs1: kondiDefinition?.zuschlAbs1,
    zuschlAbs1Label: buildText('Zuschlag 1', waehrung),
    zuschlAbs1Tip: correctTooltip(
        'Absoluter Zuschlag 1',
        kondiDefinition?.kurzZuschlAbs1,
        kondiDefinition?.bezZuschlAbs1,
        kondiDefinition?.zuschlAbs1
    ),

    zuschlAbs2: kondiDefinition?.zuschlAbs2,
    zuschlAbs2Label: buildText('Zuschlag 2', waehrung),
    zuschlAbs2Tip: correctTooltip(
        'Absoluter Zuschlag 2',
        kondiDefinition?.kurzZuschlAbs2,
        kondiDefinition?.bezZuschlAbs2,
        kondiDefinition?.zuschlAbs2
    ),

    zuschlAbs3: kondiDefinition?.zuschlAbs3,
    zuschlAbs3Label: buildText('Zuschlag 3', waehrung),
    zuschlAbs3Tip: correctTooltip(
        'Absoluter Zuschlag 3',
        kondiDefinition?.kurzZuschlAbs3,
        kondiDefinition?.bezZuschlAbs3,
        kondiDefinition?.zuschlAbs3
    ),

    zuschlAbs4: kondiDefinition?.zuschlAbs4,
    zuschlAbs4Label: buildText('Zuschlag 4', waehrung),
    zuschlAbs4Tip: correctTooltip(
        'Absoluter Zuschlag 4',
        kondiDefinition?.kurzZuschlAbs4,
        kondiDefinition?.bezZuschlAbs4,
        kondiDefinition?.zuschlAbs4
    ),

    zuschlAbs5: kondiDefinition?.zuschlAbs5,
    zuschlAbs5Label: buildText('Zuschlag 5', waehrung),
    zuschlAbs5Tip: correctTooltip(
        'Absoluter Zuschlag 5',
        kondiDefinition?.kurzZuschlAbs5,
        kondiDefinition?.bezZuschlAbs5,
        kondiDefinition?.zuschlAbs5
    ),

    rabattProz1: kondiDefinition?.rabattProz1,
    rabattProz1Label: 'RZ1 %',
    rabattProz1Tip: correctTooltip(
        'Prozentualer Rabatt 1 ',
        kondiDefinition?.kurzRabattProz1,
        kondiDefinition?.bezRabattProz1,
        kondiDefinition?.rabattProz1
    ),

    rabattProz2: kondiDefinition?.rabattProz2,
    rabattProz2Label: 'RZ2 %',
    rabattProz2Tip: correctTooltip(
        'Prozentualer Rabatt 2',
        kondiDefinition?.kurzRabattProz2,
        kondiDefinition?.bezRabattProz2,
        kondiDefinition?.rabattProz2
    ),

    rabattProz3: kondiDefinition?.rabattProz3,
    rabattProz3Label: 'RZ3 %',
    rabattProz3Tip: correctTooltip(
        'Prozentualer Rabatt 3',
        kondiDefinition?.kurzRabattProz3,
        kondiDefinition?.bezRabattProz3,
        kondiDefinition?.rabattProz3
    ),

    rabattProz4: kondiDefinition?.rabattProz4,
    rabattProz4Label: 'RZ4 %',
    rabattProz4Tip: correctTooltip(
        'Prozentualer Rabatt 4 ',
        kondiDefinition?.kurzRabattProz4,
        kondiDefinition?.bezRabattProz4,
        kondiDefinition?.rabattProz4
    ),

    rabattProz5: kondiDefinition?.rabattProz5,
    rabattProz5Label: 'RZ5 %',
    rabattProz5Tip:correctTooltip(
        'Prozentualer Rabatt 5',
        kondiDefinition?.kurzRabattProz5,
        kondiDefinition?.bezRabattProz5,
        kondiDefinition?.rabattProz5
    ),
    //
    sonderPreisZusch:
      kondiDefinition?.sonderpreisInRabatt >= 1 && kondiDefinition?.sonderpreisInRabatt <= 5
        ? `zuschlAbs${kondiDefinition?.sonderpreisInRabatt}`
        : null // null ist erlaubt, dann wir der Sonderpreis ohne Rabatt erfasst
  } as EkKonditionenDefinitionEx
}

/**
 * Modell für Bearbeitung vorbereiten
 * Das Modell wird mit aufbereitet, insbs. mit redundanten Feldern, um die Bearbeitung zu vereinfachen
 * @param input geladenes Modell
 * @returns Edit-Modell
 */
export const prepareEkPreisModelEdit = (input: EkPreiseBearbeitenJson): EkPreiseBearbeitenEdit => {
  const data = ({ ...input } || {}) as EkPreiseBearbeitenEdit

  if (data.neueKondi == null) {
    data.neueKondi = { konditionsEbenen: [] }
    data.invalid = true
  }

  if (data.vergangeneListe == null) {
    data.vergangeneListe = []
  }

  if (isAsyncTaskActive(input.state.asyncTask)) {
    return data
  }

  const kondiDefinition = enrichKondiDefinition(data.kondiDefinition, data.allianzModus)

  if (data.neueKondi == null && data.freigegeben !== true) {
    data.neueKondi = {
      gueltigVon: null,
      gueltigBis: null,
      konditionsEbenen: []
    }
  }

  data.neueKondi.sonderpreisRabattierbar = ermittleSonderpreisRabattierbarFlag(
    data.neueKondi,
    kondiDefinition
  )

  data.vergangeneListe = data.vergangeneListe || []

  const flattenEx = (pe: PreisEbeneDisplayJson, depth: number = 0) => {
    if (pe.parent && depth < 10) {
      return [pe, ...flattenEx(pe.parent, depth + 1)]
    }
    return [pe]
  }

  // Geschachtelte Ebenen ausfalten, damit der Index alle enthält
  const preisEbenen = data.preisEbenen
    .map((pe) => flattenEx(pe))
    .flat(4)
    .filter((v, i, a) => a.findIndex((s) => s.id === v.id) === i)

  // Falls Hierarchie relevant wird...
  // const addLevelEx = (pe: PreisEbeneDisplayJsonEx) => {
  //   if (pe.parent == null) {
  //     pe.level = 0
  //   } else {
  //     if (pe.parent.level == null) {
  //       addLevelEx(pe.parent)
  //     }
  //     pe.level = pe.parent.level + 1
  //   }
  // }
  // preisEbenen.forEach((pe) => addLevelEx(pe))

  const preisEbenenMap = new Map(preisEbenen.map((s) => [s.id, s]))
  const standort0 = data.preisEbenen.find((d) => d.defaultStandort)

  preisEbenen.sort((a, b) => compareStrings(a.nr, b.nr)) // a.nr - b.nr)
  // preisEbenen.sort((e1, e2) => {
  //   let d = e1.level - e2.level
  //   if (d === 0) {
  //     d = compareStrings(e1.nr, e2.nr, 'sort')
  //   }
  //   return d
  // })

  const staffelgruppen = [...data.staffelgruppen]
  staffelgruppen.sort((a, b) => compareStrings(a.name, b.name))
  staffelgruppen.forEach((sg) => sg.staffelmengen?.sort((a, b) => a - b))

  const ekRabattgruppen = [...(data.ekRabattgruppen ?? [])]
  ekRabattgruppen.sort((a, b) => compareStrings(a.kopf?.name, b.kopf?.name))

  trimmKondis(
    data.neueKondi,
    data.freigegeben,
    preisEbenenMap,
    preisEbenen,
    staffelgruppen,
    kondiDefinition,
    0,
    null
  )

  if (data.vergangeneListe != null) {
    let last = data.neueKondi

    data.vergangeneListe = data.vergangeneListe.filter((vke) => vke.konditionsEbenen?.length > 0)
    data.vergangeneListe.forEach((vke, idx) => {
      trimmKondis(
        vke,
        true,
        preisEbenenMap,
        preisEbenen,
        staffelgruppen,
        kondiDefinition,
        data.vergangeneListe.length - idx,
        last
      )
      last = vke
    })
  }

  let state = {
    ...data,
    warAbgeschlossen: data.neueKondi.abgeschlossen,
    kondiDefinition,
    preisEbenen,
    staffelgruppen,
    ekRabattgruppen,
    standort0
  } as EkPreiseBearbeitenEdit

  state = updateEffektiveRabattgruppeWerte(state, true)

  return state
}

/**
 * Daten zum Speichern vorbereiten (säubern)
 *
 * @param data Edit-Daten
 * @returns Speicherdaten
 */
export const prepareEkPreisModelSave = (data: EkPreiseBearbeitenEdit): EkKonditionenJson => {
  const neueKondi = data.neueKondi
  return {
    id: neueKondi.id,
    version: neueKondi.version,
    abgeschlossen: neueKondi.abgeschlossen,
    listenpreis: neueKondi.listenpreisEx,
    preismenge: neueKondi.preismenge,
    mengenEinheit: neueKondi.mengenEinheit,
    gueltigVon: neueKondi.gueltigBis,
    gueltigBis: neueKondi.gueltigVon,
    artikelRabattgruppeId: neueKondi.artikelRabattgruppeId,
    konditionsEbenen: neueKondi.konditionsEbenen
      .map(
        (ke) =>
          ({
            id: ke.id,
            rabattgruppeId: ke.rabattgruppeId,
            staffelgruppeId: ke.staffelgruppeId,
            rabattVerwendung: ke.rabattVerwendung,
            werte: ke.werte
              .filter((wert) => wert.standortEk === true)
              .map((wert) => ({
                id: wert.id,
                version: wert.version,
                standortId: wert.standortId,
                listenpreis: wert.listenpreis,
                sonderpreis: wert.sonderpreis,
                ekFreiHaus: wert.ekFreiHaus,
                staffelmenge: wert.staffelmenge,
                hauptstaffel: wert.hauptstaffel,
                zuschlAbs1: wert.zuschlAbs1,
                zuschlAbs2: wert.zuschlAbs2,
                rabattProz1: wert.rabattProz1,
                rabattProz2: wert.rabattProz2,
                rabattProz3: wert.rabattProz3,
                rabattProz4: wert.rabattProz4,
                rabattProz5: wert.rabattProz5,
                zuschlAbs3: wert.zuschlAbs3,
                zuschlAbs4: wert.zuschlAbs4,
                zuschlAbs5: wert.zuschlAbs5,
                rabattgruppeId: wert.rabattgruppeId
              }))
          }) as EkKonditionenEbeneJson
      )
      .filter((ke) => ke.werte?.length > 0)
  } as EkKonditionenJson
}

/**
 * Event aus Daten ableiten
 * @param model Das Modell
 * @returns die Event-Daten
 */
export const prepareEkPreisModelSaveEvent = (model: EkPreiseBearbeitenJson) => model.listeDisplay
