import { TextField, Typography } from '@mui/material'
import { formatNumber } from '@utils/numberutils'
import { FormField } from '@utils/ui/fields/FormField'
import { autoCompleteOff } from '@utils/ui/uiutils'
import clsx from 'clsx'
import React from 'react'
import { NumberFormatValues, NumericFormat, SourceInfo } from 'react-number-format'
import { makeStyles } from 'tss-react/mui'

export interface NumberCellROProps<T = any> {
  id?: any
  model: any
  field: string
  fraction?: number
  defaultValue?: number
  getSelected?: () => Set<T>
}

export type NumberCellOnChange<T = any> = (
  model: T | Set<T>,
  field: string,
  value: number | null
) => void

export interface NumberCellXProps extends NumberCellROProps {
  onChange: NumberCellOnChange
  readonly?: boolean
  allowNegative?: boolean
  min?: number
  max?: number
  defaultValue?: number
  noDefault?: boolean
}

export interface NumberCellEditProps extends NumberCellXProps {
  title?: string
  style?: any
  suffix?: string
  defaultValue?: number
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  textfield: {
    width: '100%',
    // minHeight: 44,
    // top: 4,
    '& input': {
      textAlign: 'right',
      // paddingTop: 0,
      paddingBottom: 0
    },
    '& label[data-shrink="true"]': {
      marginTop: '12px!important'
    }
  },
  numlabel: {
    marginTop: '-16px!important',
    '& label.MuiInputLabel-shrink': {
      marginTop: 8
    },
    '& label:not(.MuiInputLabel-shrink)': {
      textAlign: 'right',
      width: '100%'
    }
  },
  noLabel: {
    '& input': { padding: 0 }
  },
  readonly: {
    textAlign: 'right'
  },
  rof: {
    paddingTop: 8,
    marginTop: -4,
    marginBottom: -4
  }
}))

export const NumberCellEdit = ({
  model,
  defaultValue,
  field,
  onChange,
  min,
  max,
  style,
  suffix,
  title,
  readonly = false,
  fraction = 2,
  allowNegative = false,
  noDefault,
  id,
  getSelected
}: NumberCellEditProps) => {
  const { classes } = useStyles()

  const value = model && model[field]

  const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo): void => {
    onChange(
      getSelected ? getSelected() : model,
      field,
      values.value == null || values.value.length === 0 ? null : Number(values.value)
    )
  }

  const validate = (values: NumberFormatValues) => {
    const val = Number(values.value)
    return (
      values.formattedValue === '-' || ((min == null || val >= min) && (max == null || val <= max))
    )
  }

  const label = noDefault ? null : defaultValue != null ? formatNumber(defaultValue, fraction) : ' '

  return (
    <NumericFormat
      id={id}
      className={clsx(classes.textfield, noDefault ? classes.noLabel : classes.numlabel)}
      decimalScale={fraction}
      fixedDecimalScale
      thousandSeparator="."
      decimalSeparator=","
      value={value == null ? '' : value}
      // isNumericString
      allowNegative={allowNegative}
      onValueChange={handleChange}
      disabled={readonly}
      customInput={TextField}
      variant="standard"
      isAllowed={validate}
      title={title}
      style={style}
      suffix={suffix ? ` ${suffix}` : undefined}
      label={label}
      autoComplete={autoCompleteOff}
    />
  )
}

export const NumberCellRO = ({ model, field, defaultValue, fraction = 2 }: NumberCellROProps) => {
  const { classes } = useStyles()

  let value = model[field]
  if (value === defaultValue || value === 0) {
    value = null
  }
  const num1 = formatNumber(value, fraction)
  const num2 = formatNumber(defaultValue, fraction)
  const label = defaultValue != null && value != null ? num2 : ''
  return (
    <FormField label={label} className={classes.rof} fullWidth>
      <Typography className={classes.readonly}>{num1 ?? num2}</Typography>
    </FormField>
  )
}

export const NumberCellX = ({
  model,
  field,
  defaultValue,
  onChange,
  min,
  max,
  readonly = false,
  fraction = 2,
  allowNegative = false,
  noDefault,
  id,
  getSelected
}: NumberCellXProps) => {
  if (readonly) {
    return (
      <NumberCellRO defaultValue={defaultValue} model={model} field={field} fraction={fraction} />
    )
  }

  return (
    <NumberCellEdit
      id={id}
      defaultValue={defaultValue}
      model={model}
      field={field}
      onChange={onChange}
      fraction={fraction}
      allowNegative={allowNegative}
      min={min}
      max={max}
      noDefault={noDefault}
      getSelected={getSelected}
    />
  )
}

export const NumberCell = React.memo(NumberCellX)
