import { GesellschafterDisplayJson, WarengruppeDisplayJson } from '@one/typings/apiTypings'
import { SearcherResultType } from '@utils/searcher'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMemo } from 'react'

export interface WarengruppenUebersichtTableProps {
  result: SearcherResultType<GesellschafterDisplayJson>
  reload: () => void
}

export const WarengruppenUebersichtTable = ({
  result,
  reload
}: WarengruppenUebersichtTableProps) => {
  const columns = useMemo<Column<WarengruppeDisplayJson>[]>(
    () => [
      {
        header: 'Nummer',
        field: 'nummer',
        align: 'right',
        width: '1em',
        sortable: true
      },
      {
        header: 'Name',
        field: 'name',
        sortable: true
      },
      {
        header: 'Gelöscht',
        field: 'deleted',
        type: 'boolean',
        sortable: true,
        hideIfEmpty: true
      },
      {
        header: 'Typ',
        field: 'typ',
        sortable: true,
        valueGetter: (row) => (row.zeId ? 'Listung' : 'ERP')
      }
    ],
    []
  )

  return (
    <DataTableCard
      title="Suchergebnis"
      name="GesellschafterTabelle"
      columns={columns}
      dense
      selectMode="none"
      rowFiller
      result={result}
      initialOrderBy={['nummer', 'typ']}
    />
  )
}
