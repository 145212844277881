/* eslint-disable react/jsx-props-no-spreading */
import { clearTimer, ifString, restartTimer, safeArray, trimStringToBlank } from '@utils/utils'
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AutocompleteBaseProps, AutocompleteEx } from './AutocompleteEx'

export interface AutocompleteAsyncProps extends AutocompleteBaseProps {
  queryMinChars?: number
  queryOptions?: any
  queryTimeout?: any
  defaultOptions?: any
  loading?: boolean
  loadResetToggle?: any
  apiRef?: any
}

export const AutocompleteAsync = ({
  id,
  name,
  required,
  value,
  label,
  fieldCtrl,
  error,
  helperText,
  disabled,
  fullWidth,
  onChange,
  emptyText,
  renderOption,
  renderItem,
  optionLabel,
  optionValue,
  multiple,
  isOptionEqualToValue,
  actions,
  startAdornments,
  endAdornments,
  queryMinChars,
  queryOptions,
  queryTimeout,
  defaultOptions,
  groupBy,
  className,
  loading,
  loadResetToggle,
  apiRef
}: AutocompleteAsyncProps) => {
  const timerRef = useRef()

  const [asyncLoading, setAsyncLoading] = useState(false)
  const [loaded, setLoaded] = useState([])
  const loadResetToggleRef = useRef(loadResetToggle)

  useEffect(() => {
    if (loadResetToggleRef.current !== loadResetToggle) {
      loadResetToggleRef.current = loadResetToggle
      setLoaded([])
    }
  }, [loadResetToggle])

  const options = useMemo(() => {
    if (defaultOptions != null) {
      return [...safeArray(defaultOptions), ...safeArray(loaded)]
    }
    return safeArray(loaded)
  }, [defaultOptions, loaded])

  const onSuccess = useCallback(
    (data) => {
      const arr = safeArray(data)
      setAsyncLoading(false)
      //@ts-ignore
      setLoaded(arr)
      if (arr.length === 1) {
        onChange({ name, value: arr[0] })
      }
    },
    [name, onChange]
  )

  const onError = useCallback(() => {
    setAsyncLoading(false)
    setLoaded([])
  }, [])

  const queryRef = useRef<string>()
  const timedSearch = useCallback(() => {
    restartTimer(
      timerRef,
      () => {
        setAsyncLoading(true)
        queryOptions({
          reason: 'query',
          query: queryRef?.current,
          onSuccess,
          onError
        })
      },
      queryTimeout > 0 ? queryTimeout : 800
    )
  }, [onError, onSuccess, queryOptions, queryTimeout])

  useImperativeHandle(
    apiRef,
    () => ({
      search: timedSearch
    }),
    [timedSearch]
  )

  const onInputChange = useCallback(
    (e: any, v: string, reason: string) => {
      if (reason !== 'input') {
        return
      }
      clearTimer(timerRef)
      // if (value != null || (Array.isArray(value) && value.length !== 0)) {
      //   onChange({ name, value: multiple ? [] : null })
      //   return
      // }
      const query = trimStringToBlank(v)
      if (
        query.length >= (queryMinChars == null ? 1 : queryMinChars) ||
        (queryRef.current != null && queryRef.current.length > 0 && query.length === 0)
      ) {
        queryRef.current = query
        timedSearch()
      }
    },
    [queryMinChars, timedSearch]
  )

  useEffect(() => {
    queryOptions({ onSuccess, onError, reason: 'init' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions])

  const onChangeInt = (e) => {
    // Filter zurücksetzen, wenn Auswahl gelöscht wird
    if (
      (e.target?.value === null ||
        (Array.isArray(e.target?.value) && e.target.value.length == 0)) &&
      queryRef.current != null &&
      queryRef.current.length > 0
    ) {
      queryRef.current = ''
      clearTimer(timerRef)
      timedSearch()
    }
    onChange(e)
  }

  return (
    <AutocompleteEx
      id={id}
      name={name}
      value={value}
      label={label}
      fieldCtrl={fieldCtrl}
      error={!!error || fieldCtrl?.error}
      helperText={ifString(error) || fieldCtrl?.helperText || helperText}
      required={required || fieldCtrl?.required}
      disabled={disabled || fieldCtrl?.disabled}
      fullWidth={fullWidth}
      onChange={onChangeInt}
      onInputChange={onInputChange}
      emptyText={emptyText}
      renderOption={renderOption}
      renderItem={renderItem}
      optionLabel={optionLabel}
      optionValue={optionValue}
      options={options}
      // filterOptions={filterOptions}
      loading={asyncLoading || loading}
      loadingText="Suche..."
      multiple={multiple}
      isOptionEqualToValue={isOptionEqualToValue}
      actions={actions}
      startAdornments={startAdornments}
      endAdornments={endAdornments}
      groupBy={groupBy}
      className={className}
    />
  )
}
