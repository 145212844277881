import Grid from '@mui/material/Grid2'
import { api } from '@one/api'
import { useMengeneinheitenCache } from '@one/datacaches/useMengeneinheitenCache'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  EkPreisListeImportDefinitionMappingJson,
  EkPreisListeImportDefinitionMappingType,
  MengeneinheitDisplayTinyJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useModelMgr } from '@utils/modelmgr'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { PerformButton } from '@utils/ui/Buttons/PerformButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { TextField } from '@utils/ui/fields/TextField'
import { stopPropagation } from '@utils/ui/uiutils'
import { dataFromEvent } from '@utils/utils'
import { useEffect, useMemo, useState } from 'react'
export interface LieferantenMappingDialogProps {
  open: boolean
  onClose: any
  type: EkPreisListeImportDefinitionMappingType
  lieferantId: number | null
  mappingId: number | null
  defExtern?: string
}

export const LieferantenMappingDialog = ({
  open,
  onClose,
  type,
  lieferantId,
  mappingId,
  defExtern
}: LieferantenMappingDialogProps) => {
  const [valid, setValid] = useState(false)
  const { data: mengeneinheiten } = useMengeneinheitenCache()

  const { model, save, onValueChange, errors, isChanged } =
    useModelMgr<EkPreisListeImportDefinitionMappingJson>({
      noid: true,
      api,
      title: 'Lieferanten-Mengeneinheiten-Mapping',
      rest: 'ekpreislisteimport/definition/mapping',
      unwrapField: 'data',
      restps: {
        suLieferantId: lieferantId,
        mappingType: type
      },
      init: {},
      editMutator(data) {
        if (mappingId == null) {
          return {
            ...data,
            mappings: [...data.mappings, { id: null, defExtern }]
          }
        }
        return data
      },
      saveMutator(model) {
        return {
          ...model,
          mappingType: type,
          suLieferantId: lieferantId
        } as EkPreisListeImportDefinitionMappingJson
      }
    })

  const { et } = useEnums()

  const typeText = et(HkmEnum.EkPreisListeImportDefinitionMappingType, type)
  const mapping = useMemo(
    () => model?.mappings?.find((m) => m.id === mappingId) || {},
    [mappingId, model.mappings]
  )
  const mappingPath = `mappings.[${model?.mappings?.findIndex((m) => m.id === mappingId)}]`

  const onValueChangeEx = (e) => {
    const data = dataFromEvent(e)
    data.name = mappingPath + '.' + data.name
    onValueChange(data)
  }

  const onSave = () => {
    save({ then: onClose })
  }

  const onCancel = () => {
    onClose()
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      stopPropagation(e)
      onSave()
    }
  }

  useEffect(() => {
    if (mapping.defExtern && mapping.defIntern) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [mapping])

  return (
    <DialogEx
      disabledFullscreen
      open={open}
      onClose={onCancel}
      title={mappingId ? `${typeText} Mapping ändern` : `${typeText} Mapping hinzufügen`}
      actions={
        <>
          <PerformButton
            onClick={onSave}
            label={mappingId ? 'Ändern' : 'Hinzufügen'}
            disabled={!isChanged || !valid}
          />
          <CancelButton onClick={onCancel} />
        </>
      }
    >
      <Grid container spacing={2} direction="column" padding={2}>
        <Grid>
          <TextField
            name="defExtern"
            value={mapping.defExtern}
            onChange={onValueChangeEx}
            label={`Bezeichnung für Lieferanten ${typeText}`}
            error={errors.defExtern}
            onKeyDown={onKeyDown}
            required
            fullWidth
          />
        </Grid>
        <Grid>
          <AutocompleteEx<MengeneinheitDisplayTinyJson>
            name="defIntern"
            value={mapping.defIntern}
            onChange={onValueChangeEx}
            label={`Bezeichnung für interne ${typeText}`}
            error={errors.defIntern}
            options={mengeneinheiten}
            optionValue={(option) => option.kurz}
            optionLabel={(option) => option.kurz}
            renderItem={(option) => `${option.kurz} [${option.bez}]`}
            required
            fullWidth
          />
        </Grid>
      </Grid>
    </DialogEx>
  )
}
