import { Visibility } from '@mui/icons-material'
import Grid from '@mui/material/Grid2'
import { AppPaths } from '@one/AppPaths'
import { formatLieferant } from '@one/components/common/formatters'
import {
  PimArtikelJson,
  PimArtikelVersionLieferantJson,
  PimArtikelVersionLieferantLandJson,
  SuchLieferantTyp
} from '@one/typings/apiTypings'
import { formatDate } from '@utils/dateutils'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'
import { singleFromSet } from '@utils/utils'
import { useEffect, useMemo, useState } from 'react'

export interface ArtikelPimLieferantenProps {
  artikel: PimArtikelJson
}

export const ArtikelPimLieferanten = ({ artikel }: ArtikelPimLieferantenProps) => {
  const [selected, setSelected] = useState<Set<PimArtikelVersionLieferantJson>>()

  const lieferanten = artikel?.version?.lieferanten?.filter((l) => l.kontext != null) ?? []

  const columns = useMemo<Column<PimArtikelVersionLieferantJson>[]>(
    () => [
      {
        field: 'lieferant.name1',
        header: 'Lieferant-Name'
      },
      {
        field: 'lieferant.nummer',
        header: 'Lieferant-Nummer'
      },
      {
        field: 'lieferant.iln',
        header: 'ILN'
      },
      {
        field: 'ian',
        header: 'IAN'
      },
      {
        field: 'name1',
        header: 'Name 1'
      },
      {
        field: 'name2',
        header: 'Name 2'
      },
      {
        field: 'lieferant.geloescht',
        header: 'Gelöscht'
      },
      {
        field: 'warengruppe',
        header: 'Warengruppe'
      },
      {
        field: 'unterWarengruppe',
        header: 'Unterwarengruppe'
      },
      {
        field: 'einkaeufergruppe',
        header: 'Einkäufergruppe',
        type: 'number'
      },
      {
        field: 'ekSperreAb',
        header: 'EK-Sperre ab',
        type: 'date'
      },
      {
        field: 'ekSperreBis',
        header: 'EK-Sperre bis',
        type: 'date'
      },
      {
        field: 'importKennzeichen',
        header: 'Import-Kennzeichen',
        type: 'boolean'
      },
      {
        field: 'teilsortiment',
        header: 'Teilsortiment'
      },
      {
        field: 'fscClaim',
        header: 'FSC-Claim'
      },
      {
        field: 'fscZertifiziert',
        header: 'FSC-Zertifiziert',
        type: 'boolean'
      },
      {
        field: 'pefcClaim',
        header: 'PEFC-Claim'
      },
      {
        field: 'pefcZertifiziert',
        header: 'PEFC-Zertifiziert',
        type: 'boolean'
      },
      {
        field: 'lieferzeitZurHagebauLogistik',
        header: 'Lieferzeit zur Hagebau Logistik'
      },
      {
        field: 'herstellerartikelnummer',
        header: 'Herstellerartikelnummer'
      },
      {
        field: 'sourcingOffice',
        header: 'Sourcing Office'
      }
    ],
    []
  )

  const laenderColumns = useMemo<Column<PimArtikelVersionLieferantLandJson>[]>(
    () => [
      {
        field: 'pimLand.name',
        header: 'Land'
      },
      {
        field: 'frachtkosten',
        header: 'Frachtkosten'
      }
    ],
    []
  )

  const tableActions = useMemo(
    (): DataTableAction<PimArtikelVersionLieferantJson>[] => [
      {
        icon: <Visibility />,
        direct: true,
        tooltip: 'Lieferant öffnen',
        getLink: (row) => {
          return row.lieferant.typ === SuchLieferantTyp.ZENTRAL
            ? AppPaths.LieferantListungFn(row.lieferant.id)
            : AppPaths.LieferantFn(row.lieferant.id)
        }
      }
    ],
    []
  )

  useEffect(() => {
    const sel = new Set()
    if (artikel.version.lieferanten?.length > 0) {
      sel.add(lieferanten[0])
    }
    setSelected(sel)
  }, [lieferanten])

  if (!artikel || !artikel?.version?.lieferanten) {
    return null
  }

  const selectedRow = singleFromSet(selected)
  const laender = selectedRow?.laender
  const lk = selectedRow?.kontext

  //console.log(lieferanten)

  return (
    <CardEx title="Lieferanten" name="Lieferanten">
      <Grid container direction="column" spacing={2} width="100%">
        <Grid width="100%">
          <ScrollPanel autoHeight autoHeightMax="600px">
            <DataTable
              name="PimLieferantenTabelle"
              value={lieferanten}
              columns={columns}
              actions={tableActions}
              selectMode="single"
              onSelect={setSelected}
              selected={selected}
              emptyMessage="Keine Daten"
              dense
            />
          </ScrollPanel>
        </Grid>

        {lk ? (
          <Grid width="100%">
            <CardEx
              borderless
              title={`Kontextdaten zu '${formatLieferant(selectedRow.lieferant)}' ${selectedRow.seErgaenzung ? ' -- Eigenpflege' : ''}`}
              titleSize="14px"
              name="Lieferanten-Artikel-Kontext"
              nopadding
            >
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <Checkbox
                    disabled
                    labelNotDisabled
                    indeterminate
                    label="Aktiv"
                    checked={lk?.aktiv}
                    name="aktiv"
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <Checkbox
                    disabled
                    labelNotDisabled
                    indeterminate
                    label="Ausgelistet"
                    checked={lk?.ausgelistet}
                    name="ausgelistet"
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <Checkbox
                    disabled
                    labelNotDisabled
                    indeterminate
                    label="Reiner Werbeartikel"
                    checked={lk?.reinerWerbeartikel}
                    name="reinerWerbeartikel"
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Listungsstatus"
                    value={lk.listungsstatus}
                    name="listungsstatus"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Listungsstatus Gültig ab"
                    value={formatDate(lk.listungsstatusGueltigAb)}
                    name="listungsstatusGueltigAb"
                    empty
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <StaticField label="Kontext" value={lk.kontext?.name} name="kontext" />
                </Grid> */}
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="BME"
                    value={lk.bme ? `${lk.bme?.name}` : ''}
                    name="bme"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="LME 1"
                    value={lk.lme1 ? `${lk.lme1?.name}` : ''}
                    name="lme1"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="LME 2"
                    value={lk.lme2 ? `${lk.lme2?.name}` : ''}
                    name="lme2"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="LME 3"
                    value={lk.lme3 ? `${lk.lme3?.name}` : ''}
                    name="lme3"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="LME 4"
                    value={lk.lme4 ? `${lk.lme4?.name}` : ''}
                    name="lme4"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="LME 5"
                    value={lk.lme5 ? `${lk.lme5?.name}` : ''}
                    name="lme5"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField label="incoterms" value={lk.incoterms} name="incoterms" empty />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Incoterms-Abgangshafen"
                    value={lk.incotermsAbgangshafen}
                    name="incotermsAbgangshafen"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Incoterms-Empfangshafen"
                    value={lk.incotermsEmpfangshafen}
                    name="incotermsEmpfangshafen"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Versandweg Endkunde"
                    value={lk.versandwegEndkunde}
                    name="versandwegEndkunde"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Versandsystem Endkunde"
                    value={lk.versandsystemEndkunde}
                    name="versandsystemEndkunde"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Steuerung Transport-Dienstleister"
                    value={lk.steuerungTransportDienstleister}
                    name="steuerungTransportDienstleister"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Retourenziel"
                    value={lk.retourenziel}
                    name="retourenziel"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Retouren Wiederverwertungskennzeichen"
                    value={lk.retourenWiederverwertungskennzeichen}
                    name="retourenWiederverwertungskennzeichen"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Warenrücksendung-Lieferantenretoure"
                    value={lk.warenruecksendungLieferantenretoure}
                    name="warenruecksendungLieferantenretoure"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField label="Kombityp" value={lk.kombityp} name="kombityp" empty />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="MAM Gültig ab"
                    value={formatDate(lk.mamGueltigAb)}
                    name="mamGueltigAb"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="MAM Gültig bis"
                    value={formatDate(lk.mamGueltigBis)}
                    name="mamGueltigBis"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Freigabe Änderungsdienst"
                    value={lk.freigabeAenderungsdienst}
                    name="freigabeAenderungsdienst"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Sortimentskennzeichen Hagebau Logistik"
                    value={lk.sortimentsKennzeichenHagebauLogistik}
                    name="sortimentsKennzeichenHagebauLogistik"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Keine Anmahnung Bestandsmeldung bis"
                    value={formatDate(lk.keineAnmahnungBestandsmeldungBis)}
                    name="keineAnmahnungBestandsmeldungBis"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Mindestbestellmenge Lieferant"
                    value={lk.mindestbestellmengeLieferant}
                    name="mindestbestellmengeLieferant"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Bereitstellungszeit"
                    value={lk.bereitstellungszeit}
                    name="bereitstellungszeit"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField label="Lieferzeit" value={lk.lieferzeit} name="lieferzeit" empty />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="alternative Artikel-Nr."
                    value={lk.alternativeArtikelNummer}
                    name="alternativeArtikelNummer"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Pflanzenschutzregistrier-Nummer"
                    value={lk.pflanzenschutzregistrierNummer}
                    name="pflanzenschutzregistrierNummer"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Pflanzenschutzregistrier-Nummer Zulassungsende"
                    value={formatDate(lk.pflanzenschutzregistrierNummerZulassungsende)}
                    name="pflanzenschutzregistrierNummerZulassungsende"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Biozid-Zulassungsnummer"
                    value={lk.biozidZulassungsnummer}
                    name="biozidZulassungsnummer"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="Biozid-Zulassungsnummer Zulassungsende"
                    value={formatDate(lk.biozidZulassungsnummerZulassungsende)}
                    name="biozidZulassungsnummerZulassungsende"
                    empty
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
                  <StaticField
                    label="MHD Restlaufzeit"
                    value={lk.mhdRestlaufzeit}
                    name="mhdRestlaufzeit"
                    empty
                  />
                </Grid>
              </Grid>
            </CardEx>
          </Grid>
        ) : null}
        {lk ? (
          <Grid width="100%">
            <CardEx
              borderless
              title={`Frachtkosten je Land zu '${formatLieferant(selectedRow.lieferant)}'`}
              titleSize="14px"
              name="Lieferanten-Artikel-Laender"
              nopadding
            >
              <Grid container direction="column" spacing={2} width="100%">
                <Grid width="100%">
                  <ScrollPanel autoHeight autoHeightMax="600px">
                    <DataTable
                      name="PimLieferantenLaenderTabelle"
                      value={laender}
                      columns={laenderColumns}
                      selectMode="none"
                      emptyMessage="Keine Daten"
                      dense
                    />
                  </ScrollPanel>
                </Grid>
              </Grid>
            </CardEx>
          </Grid>
        ) : null}
      </Grid>
    </CardEx>
  )
}
