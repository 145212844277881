import { MessageJson, MessageSeverity } from '@one/typings/apiTypings'
import { MutableRefObject } from 'react'
import isEqual from 'react-fast-compare'
import { v4 as uuidv4 } from 'uuid'
import { debugLog, errorLog } from './logging'
import { formatNumber } from './numberutils'
import { ErrorsType } from '@utils/modelmgr'

export type CallbackType<T> = {
  then: (data: T) => void
  catch?: (error: any) => void
}

let globalLocale = undefined

export const setGlobalLocale = (locale: string): void => {
  globalLocale = locale
}

export const getGlobalLocale = (): string => {
  return globalLocale
}

export const addKey = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((a) => {
      const copy = addKey(a)
      if (a.items) {
        copy.items = addKey(a.items)
      }
      return copy
    })
  }
  if (typeof obj === 'object') {
    const raw = uuidv4()
    const key = raw.substr(raw.lastIndexOf('-') + 1)
    return {
      ...obj,
      key
    }
  }
  return obj
}

export interface KeyValuePair<T = any> {
  key: string
  value: T
}

export const fieldsToArray = (obj: any): KeyValuePair[] => {
  if (obj == null) return []

  return Object.keys(obj).map((key) => {
    return { key, value: obj[key] }
  })
}

/**
 * Prüfen, ob Object "leer".
 * Leer ist es, wenn es null ist oder keine Felder hat oder im Falle eines Arrays keine Elemente
 *
 * @param obj Das zu prüfende Objekt
 * @returns true/false
 */ export const isEmpty = (obj: any): any => {
  if (obj == null) {
    return true
  }
  if (Array.isArray(obj) || typeof obj === 'string') {
    return obj.length === 0
  }
  if (typeof obj === 'object') {
    return Object.keys(obj).length === 0
  }
  return false
}

/**
 * Prüfen, ob Object "leer".
 * Leer ist es, wenn es null ist, oder im Falle eines Arrays keine Elemente,
 * keine Felder oder nur Felder mit Inhalt null/undefined bzw leerem Array besitzt
 *
 * @param obj Das zu prüfende Objekt
 * @returns true/false
 */
export const isEmptyEx = (obj: any): any => {
  if (obj == null) {
    return true
  }
  if (Array.isArray(obj) || typeof obj === 'string') {
    return obj.length === 0
  }
  if (typeof obj === 'object') {
    const keys = Object.keys(obj)
    if (keys.length === 0) {
      return true
    }
    return keys.find((key) => !isEmptyEx(obj[key])) == null
  }
  return false
}

/**
 * Es sollte typeof arg === 'function' für TS direkt genutzt werden, da sonst Type-warnings bleiben:
 *
 * Beispiel: typeof arg === 'function' && arg(xxx)
 * @deprecated
 */
export const isFunction = (arg: any): boolean => typeof arg === 'function'

export const isString = (arg: any): boolean => typeof arg === 'string'

export const isStringBlank = (arg: any): boolean =>
  arg == null || (isString(arg) && arg.trim().length === 0)

export const trimStringToNull = (arg: string): string => {
  if (arg == null || !isString(arg)) {
    return null
  }
  const trimmed = arg.trim()
  if (trimmed.length === 0) {
    return null
  }
  return trimmed
}

export const trimStringToBlank = (arg: string): string => {
  const temp = trimStringToNull(arg)
  return temp == null ? '' : temp
}

export const stringLength = (arg: string): number => (arg == null ? 0 : arg.length)

export const zeroPad = (num: any, digits: number): string =>
  num == null ? null : String(num).padStart(digits, '0')

export const calcHash = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i)
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr
    // eslint-disable-next-line no-bitwise
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const safeObject = <T = any>(arr: T | T[]): T => {
  if (Array.isArray(arr)) {
    if (arr.length > 0) {
      return arr[0]
    }
  } else if (isObject(arr)) {
    return arr
  }
  return null
}

export const safeArray = <T = any>(arr: T[] | T | null): T[] => {
  return Array.isArray(arr) ? arr : []
}

export const ensureArray = <T = any>(arr: any): T[] => {
  if (Array.isArray(arr)) {
    return arr
  }
  if (arr) {
    return [arr]
  }
  return []
}

export const ensureArrayT = <T = any>(arr: T | T[] | null): T[] => {
  if (Array.isArray(arr)) {
    return arr
  }
  if (arr) {
    return [arr]
  }
  return []
}

export const join = (...args) => {
  const result = []
  args.filter(Boolean).forEach((arg) => result.push(arg))
  return result
}

export function isObject(obj) {
  return obj && typeof obj === 'object'
}

export const ensureObject = (obj) => {
  return isObject(obj) ? obj : {}
}

/**
 * Filter eindeutige Einträge
 * @param arr Array ggf null
 * @param identFn Optional, Funktion die ein Attribute rauschreicht, mit dem die Eindeutigkeit ermittelt werden soll
 * @returns gefiltertes Array
 */
export const distinctItems = <T>(arr: T[] | null, identFn: (i: T) => any = (i) => i) => {
  if (Array.isArray(arr)) {
    return arr.filter((v, i, s) => {
      const iv = identFn(v)
      return s.findIndex((p) => identFn(p) === iv) === i
    })
  }
  return arr
}

export const asNumber = (obj) => {
  if (obj != null) {
    if (typeof obj === 'string') {
      const val = parseInt(obj, 10)
      if (Number.isNaN(val)) {
        return null
      }
      return val
    }
    if (typeof obj === 'number') {
      return obj
    }
  }
  return null
}

export const asNumberArray = (numberStringList: string | null): number[] => {
  if (numberStringList == null) {
    return []
  }
  return numberStringList.split(',').map((s) => asNumber(s))
}

export function formatMoney2(val: number) {
  // eslint-disable-next-line prefer-template
  return val != null ? val.toLocaleString(getGlobalLocale(), { minimumFractionDigits: 2 }) : ''
}

export function formatInteger(val: number) {
  // eslint-disable-next-line prefer-template
  return val != null ? val.toLocaleString(getGlobalLocale(), { minimumFractionDigits: 0 }) : ''
}

export const formatValues = (separator, ...rest) => {
  return Object.keys(rest)
    .filter((key) => rest[key] != null)
    .map((key) => rest[key])
    .join(separator)
}

export const arrayItemReplace = <T>(
  arr: T[],
  findFnc: (i: T) => boolean,
  replace?: T | null
): T[] => {
  if (findFnc == null) {
    return arr
  }

  if (arr == null || arr.length === 0) {
    return replace == null ? [] : [replace]
  }

  const idx = arr.findIndex(findFnc)
  if (idx === -1) {
    return arr
  }
  // return arr.map((o: any) => (findFnc(o) ? replace : o)).filter(Boolean)
  const copy = [...arr]
  if (replace) {
    copy.splice(idx, 1, replace)
  } else {
    copy.splice(idx, 1)
  }
  return copy
}

export const arrayMerge = (source, forReplace, identFn) => {
  if (source == null || forReplace == null) {
    return source
  }
  const idx = new Map(forReplace.map((x) => [identFn(x), x]))
  const replaced = source.map((x) => {
    const id = identFn(x)
    const r = idx.get(id)
    if (r) {
      idx.delete(id)
      return r
    }
    return x
  })
  if (idx.size > 0) {
    return [...replaced, ...Array.from(idx)]
  }
  return replaced
}

export const arrayMergeUnique = (firstArray, secondArray, id) => {
  const helperObj = {}
  const simpleArray = [...ensureArray(firstArray), ...ensureArray(secondArray)]
  simpleArray.forEach((item) => {
    helperObj[item[id]] = item
  })
  return Object.keys(helperObj).map((_id) => helperObj[_id])
}

export function* counter(start = 0, step = 1) {
  let value = start
  while (true) {
    yield value
    value += step
  }
}

export const isNumberFormat = (val) => {
  if (val != null) {
    let v = val.replace('.', '')
    v = v.replace(',', '.')
    return !Number.isNaN(Number(v)) && !Number.isNaN(parseFloat(v))
  }
  return false
}

// eslint-disable-next-line eqeqeq
export const eqeq = (a, b) => a == b

const byKey = /\[(.+)=(.+)\]/

const byIdx = /\[(\d+)\]/

const isNameArrayIdx = (name) => byKey.exec(name) != null || byIdx.exec(name) != null

const resolveArrayIdx = (current, name) => {
  let match = byKey.exec(name)
  if (match) {
    const [, field, key] = match
    if (Array.isArray(current)) {
      return current.findIndex((o) => eqeq(o[field], key))
    }
    errorLog('index on non array', name, current)
    return -1
  }
  match = byIdx.exec(name)
  if (match) {
    if (Array.isArray(current)) {
      return Number(match[1])
    }
    errorLog('index on non array', name, current)
    return -1
  }
  return null
}

const copyArray = (entry, nextIsIdx) =>
  (entry == null && nextIsIdx && []) || (Array.isArray(entry) ? [...entry] : { ...entry })

export const updateObjectField = (obj, path, value) => {
  if (obj == null || path == null) {
    return null
  }

  const pathArr = path.split('.')
  const result = { ...obj }
  let it = result

  for (let pos = 0; pos < pathArr.length - 1; pos += 1) {
    const name = pathArr[pos]
    const nextIsIdx = pos < pathArr.length - 1 && isNameArrayIdx(pathArr[pos + 1])
    let copy
    const idx = resolveArrayIdx(it, name)
    if (idx != null) {
      while (it.length < idx) {
        // fill up with null
        it.push(null)
      }
      const entry = it[idx]
      copy = copyArray(entry, nextIsIdx)
      if (idx === -1) {
        it.push(copy)
      } else {
        it.splice(idx, 1, copy)
      }
    } else {
      const x = it[name]
      if (x == null && nextIsIdx) {
        copy = []
      } else {
        copy = Array.isArray(x) ? [...x] : { ...x }
      }
      it[name] = copy
    }
    it = copy
  }

  const name = pathArr[pathArr.length - 1]
  const entryIdx = resolveArrayIdx(it, name)
  if (entryIdx === null) {
    //@ts-ignore
    if (isEqual((it[name], value))) {
      return obj
    }
    it[name] = value
  } else if (value === null) {
    if (entryIdx !== -1) {
      it.splice(entryIdx, 1)
    }
  } else if (entryIdx === -1) {
    it.push(value)
  } else {
    it.splice(entryIdx, 1, value)
  }

  return result
}

export const resolveObjectField = (obj, path) => {
  if (obj == null || path == null) {
    return null
  }
  const pathArr = path.split('.')
  let it = obj
  for (let i = 0; i < pathArr.length; i += 1) {
    const name = pathArr[i]
    const entryIdx = resolveArrayIdx(it, name)
    if (entryIdx != null) {
      const entry = entryIdx === -1 ? null : it[entryIdx]
      if (entry == null) {
        return null
      }
      it = entry
    } else {
      it = it[name]
      if (it == null) {
        return null
      }
    }
  }
  return it
}

export const copyOfArray = <T>(o: T[]) => {
  return o == null ? [] : [...o]
}

export const copyOfObject = <T>(o: T) => {
  return o == null ? o : { ...o }
}

export const buildMap = <T, K, V = T>(
  arr: T[],
  toKey: (e: T) => K,
  toValue: (e: T) => V = (e: any) => e
): Map<K, V> => {
  if (Array.isArray(arr)) {
    return new Map(arr.filter(Boolean).map((e) => [toKey(e), toValue(e)]))
  }
  return new Map()
}

const keygen = counter(-1, -1)

export const nextKey = () => keygen.next().value as number

export type NamedValue = {
  name?: string
  value?: any
}

export type ValueDecorator = Record<string, Function> | ((field: string, value: any) => any)

export const dataFromEvent = (e: any, valueDecorator?: ValueDecorator): NamedValue => {
  const data = e.target || e
  if (data) {
    const { name } = data
    let raw = data.type === 'checkbox' ? data.checked : data.value
    if (raw === '') {
      raw = null
    }
    if (valueDecorator == null) {
      return { name, value: raw }
    }
    if (typeof valueDecorator === 'function') {
      return {
        name,
        value: valueDecorator(name, raw)
      }
    }
    const value = typeof valueDecorator[name] === 'function' ? valueDecorator[name](raw) : raw
    return { name, value }
  }
  return {}
}

export const singleFromSet = <T = any>(s: Set<T>): T =>
  (s instanceof Set && s.size === 1 && s.values().next().value) || null

export const arrayFromSet = <T = any>(s: Set<T>): T[] => (s instanceof Set ? Array.from(s) : [])

export const arrayFindDups = (arr: any): any => {
  if (arr == null) {
    return []
  }

  const object = {}
  //@ts-ignore
  const result = []

  //@ts-ignore
  arr.forEach((item) => {
    //@ts-ignore
    if (!object[item]) {
      //@ts-ignore
      object[item] = 0
    }
    //@ts-ignore
    object[item] += 1
  })

  Object.keys(object).forEach((k) => {
    //@ts-ignore
    if (object[k] > 1) {
      result.push(k)
    }
  })

  //@ts-ignore
  return result
}

export const safeExecute = <T>(fnc: () => T): T => {
  try {
    if (fnc) {
      return fnc()
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  return undefined
}

export const toString = (o: any): string => {
  if (o == null) {
    return ''
  }
  if (isString(o)) {
    return o
  }
  if (Array.isArray(o)) {
    return o.join('|')
  }
  if (typeof o === 'object') {
    // eslint-disable-next-line no-console
    console.error('Object not supported', o)
  }
  return `${o}`
}
export const ifString = (str: any) => (isString(str) ? str : null)

export const compareStrings = (a: any, b: any, usage: 'search' | 'sort' = 'sort'): number => {
  if (a === b) {
    return 0
  }
  if (a == null) {
    return -1
  }
  if (b == null) {
    return +1
  }
  return toString(a).localeCompare(toString(b), getGlobalLocale(), {
    sensitivity: 'base',
    numeric: true,
    usage
  })
}

let oid = 0

/** get unique object id - each instance has a different id */
export const oidOf = (o: any): number | null => {
  if (o == null) {
    return null
  }
  if (o.__oid == null) {
    Object.defineProperty(o, '__oid', {
      // eslint-disable-next-line no-plusplus
      value: ++oid,
      enumerable: false,
      writable: false
    })
  }
  return o.__oid
}

/** get ancestor id - oid of ancester, automaticly copied to identify clones */
export const aidOf = (o: any): number | null => {
  if (o == null) {
    return null
  }
  if (o.__aid == null) {
    Object.defineProperty(o, '__aid', {
      value: oidOf(o),
      enumerable: true,
      writable: false
    })
  }
  return o.__aid
}

/** remove aid */
export const aidClear = (o) => {
  if (o != null && o.__aid != null) {
    delete o.__aid
  }
  return o
}

export const flattenArray = <T>(
  arr: T[],
  childResolver: string | ((item: T) => T[]),
  filter?: (item: T) => boolean
): T[] => {
  if (arr == null) {
    return [] as T[]
  }
  return arr.reduce((flat: T[], item: T) => {
    let rc = flat
    if (filter == null || filter(item)) {
      rc = rc.concat(item)
    }
    return rc.concat(
      flattenArray(
        typeof childResolver === 'function' ? childResolver(item) : item[childResolver],
        childResolver,
        filter
      )
    )
  }, [])
}

/**
 * Sortiert das Array direkt
 *
 * @param arr
 * @param compare
 * @returns
 */
export const sortArray = <T>(
  arr: T[],
  compare: ((a: T, B: T) => number) | ((a: T, B: T) => number)[]
): T[] => {
  if (arr != null || arr.length > 1) {
    if (Array.isArray(compare)) {
      arr.sort((a, b) => compare.map((fn) => fn(a, b)).find((e) => e != 0))
    } else arr.sort(compare)
  }
  return arr
}

export const checkRequired = <T = any>(
  model: T,
  error: any,
  field: keyof T,
  label: string
): any => {
  const value = model[field] as any
  if (model[field] == null || value.length === 0) {
    error[field] = `'${label}': Darf nicht leer sein`
  }
}

export const asNull = <T = any>(obj: T): T => {
  return obj === undefined ? null : obj
}

export const restartTimer = (
  timerRef: MutableRefObject<any>,
  callback: (args: void) => void,
  timeout: number,
  repeat: boolean = false
) => {
  if (timerRef.current != null) {
    clearTimeout(timerRef.current)
  }
  timerRef.current = (repeat ? setInterval : setTimeout)(callback, timeout)
}

export const clearTimer = (timerRef: MutableRefObject<any>) => {
  if (timerRef.current != null) {
    clearTimeout(timerRef.current)
    timerRef.current = null
  }
}

const doRestoreReferences = (data: any, store: any) => {
  if (data == null || store == null) {
    return data
  }
  if (Array.isArray(data)) {
    return data.map((o) => doRestoreReferences(o, store))
  } else if (typeof data === 'object') {
    if (data.__ref != null) {
      const sub = store[data.__ref]
      if (sub == null) {
        errorLog(
          'json reference missing source - Incomplete json!',
          'unresolved __ref=' + data.__ref,
          data
        )
        // Keine ungültigen weiterverarbeiten!
        throw new Error('Der Server hat inkonsistente Daten gesendet')
      }
      return sub
    }

    Object.keys(data).forEach((k) => {
      const field = data[k]
      if (field !== null && k !== '__ref') {
        data[k] = doRestoreReferences(field, store)
      }
    })
  }
  return data
}

const findSources = (data: any, store: any): boolean => {
  let hasRefs = false
  if (data == null || store == null) {
    return hasRefs
  }

  if (Array.isArray(data)) {
    data.forEach((o) => {
      hasRefs = findSources(o, store) || hasRefs
    })
  } else if (typeof data === 'object') {
    const src = data.__src
    if (src != null) {
      store[src] = data
      delete data.__src
    }

    Object.keys(data).forEach((k) => {
      hasRefs ||= k === '__ref'
      const sub = data[k]
      // if (k === '__src') {
      //   store[data.__src] = data
      //   delete data.__src
      // } else
      if (sub != null) {
        hasRefs = findSources(sub, store) || hasRefs
      }
    })
  }

  return hasRefs
}

export const restoreReferences = (data: any) => {
  if (data == null) {
    return null
  }
  const start = Date.now()
  const store = {}
  if (findSources(data, store)) {
    data = doRestoreReferences(data, store)
    const end = Date.now()
    debugLog('restoreReferences', `duration: ${end - start} ms`)
  }
}

export const isSeverityFatal = (severity: MessageSeverity | null) =>
  severity === MessageSeverity.FATAL

export const isSeverityError = (severity: MessageSeverity | null) =>
  severity === MessageSeverity.ERR || severity === MessageSeverity.FATAL

export const isSeverityWarning = (severity: MessageSeverity | null) =>
  severity != null || severity === MessageSeverity.WARN

export const isSeverityOk = (severity: MessageSeverity | null) =>
  severity == null || severity === MessageSeverity.INFO

export const messageToErrors = (
  messages: MessageJson[],
  errorStyle: 'structured' | 'flat' = 'flat'
) =>
  messages?.filter(Boolean).reduce((a, m) => {
    if (isString(m.hint)) {
      if (errorStyle === 'structured') {
        const lr = m.hint.split('::')
        if (lr.length > 1) {
          a[lr[0]] = updateObjectField(a[lr[0]] || {}, lr[1].replaceAll('[', '.['), m.message)
        } else {
          a = updateObjectField(a, m.hint.replaceAll('[', '.['), m.message)
        }
      } else {
        a[m.hint] = m.message
      }
    }
    return a
  }, {} as ErrorsType) || {}

/**
 * Formatiere Bytezahl lesbar
 * @param bytes Bytezahl oder null
 * @returns null oder Aufbereiteter String, z.B. "1 MB", "769 KB", "130 B"
 */
export const formatBytes = (bytes: number | null): string | null => {
  if (bytes == null || isNaN(bytes)) {
    return null
  }
  if (bytes < 1024) {
    return formatNumber(bytes, 0) + ' B'
  }
  if (bytes < 1024 * 1024) {
    return formatNumber(bytes / 1024, 0) + ' KB'
  }
  if (bytes < 1024 * 1024 * 1024) {
    return formatNumber(bytes / (1024 * 1024), 0) + ' MB'
  }
  return formatNumber(bytes / (1024 * 1024 * 1024), 0) + ' GB'
}

export const download = (filename: string, data: any, type: string = 'octet/stream') => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  const blob = new Blob([data], { type })
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export const copyToClipboard = (paste: string) => {
  navigator.clipboard.writeText(paste)
}

export const distinct = <T>(e: T, idx: number, arr: T[]) => arr.indexOf(e) === idx

export const nameOf = <T>(name: keyof T) => name

export const pathOf = <T1, T2, T3 = any, T4 = any>(
  name1: keyof T1,
  name2: keyof T2,
  name3: keyof T3 | null = null,
  name4: keyof T4 | null = null
) => {
  if (name4 != null) return `${String(name1)}.${String(name2)}.${String(name3)}.${String(name4)}`
  if (name3 != null) return `${String(name1)}.${String(name2)}.${String(name3)}`
  return `${String(name1)}.${String(name2)}`
}

export const makeQueryParams = (params: object) => {
  return (
    params &&
    Object.entries(params)
      .filter((pair) => pair.length === 2 && pair[1] != null)
      .map((pair) => [pair[0], toString(pair[1])])
      .filter((pair) => pair[1] != null && pair[1] !== '')
      .map((pair) => pair.map(encodeURIComponent).join('='))
      .join('&')
  )
}

export const toNull = <T = any>(v: T): T | null => (v == null ? null : v)

export const isValidGTIN = (gtin: string | null | undefined): boolean => {
  if (gtin == null || !/^\d+$/.test(gtin)) {
    return false
  }
  const length = gtin.length
  if (length !== 8 && length !== 12 && length !== 13 && length !== 14) {
    return false
  }
  let sum = 0
  let isOdd = true
  for (let i = gtin.length - 1; i >= 0; i--) {
    const digit = parseInt(gtin.charAt(i), 10)
    sum += isOdd ? digit : digit * 3
    isOdd = !isOdd
  }
  return sum % 10 === 0
}
