import Grid from '@mui/material/Grid2'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { useFormState } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { useEffect } from 'react'
export interface LieferantenMappingKopfProps {
  value: any
  onSearch(value: any): void
}

export const LieferantenMappingKopf = ({ value, onSearch }: LieferantenMappingKopfProps) => {
  const [searchValues, onChangeSearchValues] = useFormState(value)

  const handleSearch = () => {
    onSearch(searchValues)
  }

  const shortcuts = {
    Enter: handleSearch
  }

  useEffect(() => {
    onChangeSearchValues(() => value)
  }, [onChangeSearchValues, value])

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        title="Mengeneinheiten-Mapping für EK-Preisimport"
        subheader="Mapping von Mengeneinheiten-Bezeichnern von Lieferanten und dem ERP. Verwendet im Import EK-Preise."
        backLink
        bottomActions={<SearchButton onClick={handleSearch} />}
      >
        <Grid container spacing={3}>
          <Grid size={{ xs:12, sm:8, md:6 }}>
            <LieferantField
              value={searchValues.lieferant}
              label="Lieferant"
              name="lieferant"
              onChange={onChangeSearchValues}
              modus="dezentral"
              placeholder="Keiner - Global für alle Lieferanten"
              fullWidth
            />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
