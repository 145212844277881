import { Grid } from '@mui/material'
import { api } from '@one/api'
import { KafkaImporterJobStatusJson } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { formatNumber } from '@utils/numberutils'
import { Button } from '@utils/ui/Buttons/Button'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AdminViewPanel } from './AdminViewPanel'

export type AVPIMImportSchedulerProps = {
  zoomed: boolean
  setZoomed: () => void
}

export const AVPIMImportScheduler = ({ zoomed, setZoomed }: AVPIMImportSchedulerProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [infos, setInfos] = useState<KafkaImporterJobStatusJson>(null)

  const { et } = useEnums()

  const apiBusyRef = useRef(false)
  apiBusyRef.current = apiBusy

  const startScheduler = () => {
    setInfos(null)
    apiCall<KafkaImporterJobStatusJson>({
      method: 'GET',
      rest: 'pim/startJob',
      onSuccess: (data) => setInfos(data)
    })
  }

  const stopScheduler = () => {
    setInfos(null)
    apiCall<KafkaImporterJobStatusJson>({
      method: 'GET',
      rest: 'pim/stopJob',
      onSuccess: (data) => setInfos(data)
    })
  }

  const checkScheduler = useCallback(() => {
    if (apiBusyRef.current) {
      return
    }
    apiCall<KafkaImporterJobStatusJson>({
      method: 'GET',
      rest: 'pim/status',
      onSuccess: (data) => setInfos(data)
    })
  }, [apiCall])

  const abosAktualisieren = useCallback(() => {
    if (apiBusyRef.current) {
      return
    }
    apiCall<any>({
      method: 'GET',
      rest: 'import/forceUpdateArtikelSelektion'
      // onSuccess: (data) => setInfos(data)
    })
  }, [apiCall])

  useEffect(() => {
    checkScheduler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (visible) {
  //     const timeout = setInterval(checkScheduler, 5000)
  //     return () => clearTimeout(timeout)
  //   }
  //   return undefined
  // }, [checkScheduler, visible])

  return (
    <AdminViewPanel
      // zoomed={zoomed}
      // setZoomed={setZoomed}
      title="PIM Import Scheduler"
      subtitle="Job für Import von PIM Nachrichten"
      actions={[
        <Button
            key="recalc"
            label="Abos aktualisieren"
            onClick={abosAktualisieren}
            disabled={apiBusy}
        />,
        <Button
          key="start"
          label="Start"
          onClick={startScheduler}
          disabled={apiBusy || !infos?.configured}
        />,
        <Button
          key="stop"
          label="Stop"
          onClick={stopScheduler}
          disabled={apiBusy || !infos?.configured}
        />,
        <Button key="check" label="Aktualisieren" onClick={checkScheduler} disabled={apiBusy} />
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StaticField
              label="System-Info"
              value={infos?.systemInfo}
              fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <StaticField
            label="Status"
            value={
              infos?.configured
                ? infos?.running
                  ? 'Gestartet'
                  : 'Nicht gestartet'
                : 'Nicht konfiguriert'
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <StaticField
            label="Interval (min)"
            value={infos?.timeout ? formatNumber(infos?.timeout / 60, 0) : ''}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Position aktuell" value={infos?.aktPosition} fullWidth />
        </Grid>
        <Grid item xs={6}>
          <StaticField label="Position Ende" value={infos?.endPosition} fullWidth />
        </Grid>
      </Grid>
    </AdminViewPanel>
  )
}
