import Grid from '@mui/material/Grid2'
import { PimMengeneinheitField } from '@one/components/common/PimMengeneinheitField'
import {
  PimArtikelVersionJson,
  PimMengeneinheitDisplayJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ErrorsType, ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { RowForm } from '@utils/ui/fields/RowForm'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'
import { PimArtikelMengeneinheitMemo } from './PimArtikelMengeneinheit'
import { resolveObjectField } from '@utils/utils'

export interface PimArtikelMengeneinheitenProps {
  pimArtikelVersion: PimArtikelVersionJson
  onChange?: ValueChangeFn<SeArtikelJson>
  mengeneinheiten?: PimMengeneinheitDisplayJson[]
  dispatch?: (action: ModelAction) => void
  errors?: ErrorsType
  readonly?: boolean
}

/**
 * PimArtikelMengeneinheiten wird im EigenlistungsArtikelPflegeView editierbar
 * und im PimArtikelView als readonly genutzt.
 * @param pimArtikelVersion
 * @param onChange
 * @param mengeneinheiten
 * @param kontext
 * @param dispatch
 * @param errors
 * @param readonly
 * @constructor
 */
export const PimArtikelMengeneinheiten = ({
  pimArtikelVersion,
  onChange,
  mengeneinheiten,
  dispatch,
  errors,
  readonly
}: PimArtikelMengeneinheitenProps) => {
  const onAddMengeneinheit = () => {
    dispatch({ type: EigenlistungsArtikelUseCase.ADDMENGENEINHEIT })
  }

  return (
    <CardEx title="Mengeneinheiten" contentStyle={{ padding: 0, paddingBottom: 0 }}>
      <Grid container spacing={2} margin={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 1 }}>
          <PimMengeneinheitField
            label="Verkaufsverpackungmengeneinheit"
            value={pimArtikelVersion.verkaufsverpackungMengeneinheit}
            name="pimArtikel.version.verkaufsverpackungMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.verkaufsverpackungMengeneinheit')}
            values={mengeneinheiten}
            fullWidth
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 1 }}>
          <PimMengeneinheitField
            label="Einzelmengeneinheit"
            value={pimArtikelVersion.einzelMengeneinheit}
            name="pimArtikel.version.einzelMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.einzelMengeneinheit')}
            values={mengeneinheiten}
            fullWidth
            disabled={readonly}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} padding={0}>
        <RowForm
          rows={pimArtikelVersion.mengeneinheiten}
          onAdd={readonly ? null : onAddMengeneinheit}
          addLabel="Mengeneinheit hinzufügen"
          borderlessItems
          body={(me, idx) => (
            <PimArtikelMengeneinheitMemo
              pimArtikelVersionMengeneinheit={me}
              mengeneinheiten={mengeneinheiten}
              onChange={onChange}
              dispatch={dispatch}
              errors={errors}
              idx={idx}
              readonly={readonly}
            />
          )}
        />
      </Grid>
    </CardEx>
  )
}

export const PimArtikelMengeneinheitenMemo = React.memo(PimArtikelMengeneinheiten)
