import { NumberCell } from '@one/components/common/NumberCell'
import { VkPreisBasisTyp, VkPreisQuelle } from '@one/typings/apiTypings'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { Medal } from '@utils/ui/fields/Medal'
import { useMemo } from 'react'
import { KassenPreisBasisField } from './fields/KassenPreisBasisField'
import { VkPreisBasisField } from './fields/VkPreisBasisField'
import { VkPreisEdit } from './model/VkPreisTypes'
import { AwesomeIcon } from '@utils/ui/fields/AwesomeIcon'

export interface VkPreisPflegeTableProps {
  preise: VkPreisEdit[]
  preisgruppen: any[]
  setPreisValue: (model: any, field: any, value: any) => void
  setReferenzPreisGruppeId: (e?: any) => void
  selected?: any
  onSelect?: (next: any) => void
  referenzPreisgruppeId: number
  basisPreisgruppe?: any
  ekFreiHaus?: number
  listenpreis?: number
  readonly?: boolean
  kalkulationsvorschlagAktiv?: boolean
  forEinzelhandel?: boolean
  quelle: VkPreisQuelle
  festpreis: boolean
}

export const VkPreisPflegeTable = ({
  preise,
  setPreisValue,
  referenzPreisgruppeId,
  setReferenzPreisGruppeId,
  preisgruppen,
  basisPreisgruppe,
  selected,
  onSelect,
  ekFreiHaus,
  listenpreis,
  readonly,
  kalkulationsvorschlagAktiv,
  forEinzelhandel,
  quelle,
  festpreis
}: VkPreisPflegeTableProps) => {
  const columns = useMemo<Column<VkPreisEdit>[]>(
    () => [
      {
        field: 'preisGruppeName',
        sortable: false,
        header: 'Preisgruppe',
        width: '7rem'
      },
      {
        field: 'preisGruppeBez',
        sortable: false,
        header: 'Bezeichnung',
        width: '14rem',
        body: (row) => (
          <span>
            {row.preisGruppeBez} {row.brutto && !forEinzelhandel ? <Medal text="Brutto" /> : null}{' '}
          </span>
        )
      },
      {
        field: 'zkasse',
        sortable: false,
        header: 'ZKasse',
        align: 'center',
        width: '2rem',
        off: !forEinzelhandel,
        body: (row) => (
          <Checkbox
            compact
            checked={row.preisGruppeId === referenzPreisgruppeId}
            onChange={() => setReferenzPreisGruppeId({ value: row.preisGruppeId })}
            disabled={readonly}
          />
        )
      },
      {
        field: 'basisTyp',
        sortable: false,
        header: 'Basis',
        width: '6rem',
        off: forEinzelhandel,
        body: (row) =>
          row.kasse ? (
            <KassenPreisBasisField
              referenzPreisgruppeId={referenzPreisgruppeId}
              onChange={setReferenzPreisGruppeId}
              preisgruppen={preisgruppen}
              readonly={readonly}
              required
            />
          ) : (
            <VkPreisBasisField
              name="basisTyp"
              model={row}
              basisPreisgruppeName={
                basisPreisgruppe && row.preisGruppeId !== basisPreisgruppe.id
                  ? basisPreisgruppe.name
                  : null
              }
              onChange={setPreisValue}
              readonly={
                readonly ||
                kalkulationsvorschlagAktiv ||
                (forEinzelhandel && quelle === VkPreisQuelle.LISTUNG)
              }
              ekFreiHaus={ekFreiHaus}
              listenpreis={listenpreis}
            />
          )
      },
      {
        field: 'preis',
        sortable: false,
        header: 'VK-Preis',
        align: 'right',
        width: '7rem',
        body: (row, { column }) => (
          <NumberCell
            model={row}
            field={column.field as string}
            onChange={setPreisValue}
            readonly={
              readonly ||
              row.kasse ||
              row.basisTyp !== VkPreisBasisTyp.MANUELL ||
              (forEinzelhandel && quelle === VkPreisQuelle.LISTUNG) /*||
                  quelle === VkPreisQuelle.NEUKALKULATION ||
                  (festpreis && row.preisGruppeId !== referenzPreisgruppeId)))*/
            }
          />
        )
      },
      {
        field: 'spanne',
        sortable: false,
        header: 'Spanne %',
        align: 'right',
        width: '5rem',
        body: (row, { column }) =>
          row.kasse ? null : (
            <NumberCell
              model={row}
              field={column.field as string}
              onChange={setPreisValue}
              allowNegative
              max={100}
              readonly={
                readonly ||
                kalkulationsvorschlagAktiv ||
                row.basisTyp == null ||
                /*row.basisTyp === VkPreisBasisTyp.MANUELL ||*/
                (forEinzelhandel && quelle === VkPreisQuelle.LISTUNG)
              }
            />
          )
      },
      {
        field: 'aufschlag',
        sortable: false,
        header: 'Aufschlag %',
        align: 'right',
        width: '5rem',
        off: forEinzelhandel,
        body: (row, { column }) =>
          row.kasse ? null : (
            <NumberCell
              model={row}
              field={column.field as string}
              onChange={setPreisValue}
              allowNegative
              readonly={
                readonly ||
                kalkulationsvorschlagAktiv ||
                row.basisTyp == null ||
                row.basisTyp === VkPreisBasisTyp.MANUELL
              }
            />
          )
      },
      {
        field: 'preisAlt',
        sortable: false,
        header: 'VK-Preis alt',
        type: 'money2',
        width: '7rem'
      },
      {
        field: 'preisDelta',
        sortable: false,
        header: 'Delta %',
        type: 'money2',
        width: '7rem'
      },
      {
        field: 'staffelCount',
        sortable: false,
        header: 'Staffeln',
        align: 'right',
        width: '3rem',
        body: (row) => (
          <span>
            <span>{row.staffelCount}</span>
            {row.staffelErr ? (
              <AwesomeIcon
                iconClass="fas fa-exclamation-triangle"
                title="Staffel hat noch Fehler"
                style={{ color: 'red', marginLeft: 12 }}
              />
            ) : null}
          </span>
        )
      }
    ],
    [
      forEinzelhandel,
      referenzPreisgruppeId,
      readonly,
      setReferenzPreisGruppeId,
      setPreisValue,
      quelle,
      festpreis,
      preisgruppen,
      basisPreisgruppe,
      kalkulationsvorschlagAktiv,
      ekFreiHaus,
      listenpreis
    ]
  )

  return (
    <DataTable
      name="VkPreisPflegeTable"
      columns={columns}
      value={preise}
      size="slim"
      selectMode="single"
      focustype="row"
      selected={selected}
      onSelect={onSelect}
    />
  )
}
