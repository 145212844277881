/* eslint-disable react/jsx-props-no-spreading */
import { api } from '@one/api'
import { WarengruppeDisplayJson } from '@one/typings/apiTypings'
import { CacheStatus, useDataCache } from '@utils/datacache'
import { AutocompleteAsync } from '@utils/ui/fields/AutocompleteAsync'
import { asNumber, compareStrings, safeArray } from '@utils/utils'

export interface WarengruppeFieldProps {
  id?: string
  name?: string
  label?: string
  error?: string
  lieferantId?: number
  value?: any
  onChange?: (e?: any) => void
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  zentraleMitStatistik?: boolean
  gfMitStatistik?: boolean
  auchLeere?: boolean
  emptyText?: string
  idMode?: 'seId' | 'id'
}

export const WarengruppeField = ({
  id,
  value,
  label,
  error,
  onChange,
  name,
  lieferantId,
  emptyText,
  fullWidth = false,
  disabled = false,
  required = false,
  zentraleMitStatistik = false,
  gfMitStatistik = false,
  auchLeere = false,
  idMode
}: WarengruppeFieldProps) => {
  const wgCache = useDataCache<WarengruppeDisplayJson>({
    api,
    datakey: `warengruppen${lieferantId}_${zentraleMitStatistik}_${gfMitStatistik}_${auchLeere}`,
    rest: '/artikel/warengruppen',
    method: 'GET',
    params: { lieferantId, zentraleMitStatistik, gfMitStatistik, auchLeere },
    field: 'warengruppen'
  })

  const optionLabel = (opt) => {
    return `${opt.nummer} ${opt.name}`
  }

  const renderItem = (opt) => {
    return (
      <div style={{ display: 'flex', flexFlow: 'row', width: '100%' }}>
        <div style={{ width: '5em', textAlign: 'right', paddingRight: '1em' }}>{opt.nummer}</div>
        <div style={{ flexGrow: '1' }}>{opt.name}</div>
        {(lieferantId || zentraleMitStatistik || gfMitStatistik) && opt.anzahlArtikel && (
          <div>
            <small>{` ~${opt.anzahlArtikel} Artikel`}</small>
          </div>
        )}
      </div>
    )
  }

  const queryOptions = ({ reason, query, onSuccess, onError }) => {
    if (reason === 'query') {
      const inputValue = query?.toUpperCase()
      const num = asNumber(inputValue)
      setTimeout(() => {
        const seek = () => {
          if (num != null || inputValue == null || inputValue === '') {
            return safeArray(wgCache.data)
              .filter(Boolean)
              .filter((opt) => opt.nummer != null)
              .sort((a, b) => compareStrings(a.nummer, b.nummer))
              .filter((opt) => `${opt.nummer}`.startsWith(inputValue))
          }
          return (
            safeArray(wgCache.data)
              .filter(Boolean)
              .filter((opt) => opt.name != null)
              .sort((a, b) => compareStrings(a.name, b.name))
              .filter((opt) => opt.name && opt.name.toUpperCase().startsWith(inputValue))
          )
        }
        onSuccess(seek())
      }, 50)
    } else if (reason === 'init') {
      wgCache.getData({ then: onSuccess, catch: onError })
    }
  }

  return (
    <AutocompleteAsync
      name={name}
      fullWidth={fullWidth}
      renderItem={renderItem}
      optionLabel={optionLabel}
      optionValue={idMode}
      loading={wgCache.status === CacheStatus.loading}
      value={value}
      onChange={onChange}
      disabled={disabled}
      id={id}
      label={label}
      required={required}
      error={error}
      // defaultOptions={wgCache.data}
      queryOptions={queryOptions}
      emptyText={emptyText}
    />
  )
}
