import { Add, Delete } from '@mui/icons-material'
import { Grid2 as Grid, Paper } from '@mui/material'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { aidOf } from '@utils/utils'
import { makeStyles } from 'tss-react/mui'
import React from 'react'

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: '16px 24px',
    backgroundColor: theme.palette.background.default
  },
  noFrame: {
    padding: 0,
    margin: 0
  }
}))

export interface RowFormProps<T> {
  name?: string
  title?: string
  addLabel?: string
  addVariant?: 'text' | 'outlined' | 'contained'
  rows: T[]
  onAdd: () => void
  onRemove?: (row: T) => void
  canRemove?: (row: T) => boolean
  body: (row: T, idx: number) => React.ReactNode
  borderlessItems?: boolean
  borderless?: boolean
}

export const RowForm = <T = any,>({
  name,
  title,
  addLabel = 'Hinzufügen',
  addVariant = 'contained',
  rows,
  onAdd,
  onRemove,
  canRemove,
  body,
  borderlessItems,
  borderless
}: RowFormProps<T>) => {
  const { classes } = useStyles()
  return (
    <CardEx
      title={title}
      name={name}
      borderless={title == null || borderless}
      nopadding={borderless}
    >
      <Grid container spacing={2}>
        {rows?.map((row, idx) => (
          <Grid key={aidOf(row)} width="100%">
            <Paper
              className={borderlessItems ? classes.noFrame : classes.paper}
              elevation={borderlessItems ? 0 : undefined}
            >
              <Grid
                container
                width="100%"
                direction="row"
                flexWrap="nowrap"
                alignItems="center"
                spacing={2}
              >
                <Grid flexGrow={1}>{body(row, idx)}</Grid>
                <Grid minWidth="3em">
                  {(canRemove == null || canRemove(row)) && onRemove && (
                    <Button
                      size="small"
                      label="Entfernen"
                      StartIcon={Delete}
                      color="warning"
                      onClickVoid={() => onRemove(row)}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
        {onAdd && (
          <Grid>
            <Button label={addLabel} variant={addVariant} StartIcon={Add} onClickVoid={onAdd} />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}
