import { CardEx } from '@utils/ui/CardEx'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UploadAccepts, UploadButton } from '@utils/ui/Buttons/UploadButton'
import { api } from '@one/api'
import { AppContext } from "@utils/ui/App/AppContext";
import { useApiCaller } from "@utils/apicaller";
import { Frame, FrameBody, FrameRow } from "@utils/ui/Frame";
import { DataTableCard } from "@utils/ui/DataTable/DataTableCard";
import { WarengruppenMappingDisplayJson, WarengruppenMappingListeJson } from "@one/typings/apiTypings";
import { Column } from "@utils/ui/DataTable/DataTable";
import { nameOf } from "@utils/utils";
import { formatErpWarengruppe } from "@one/components/common/formatters";
import { IconButton } from "@utils/ui/Buttons/IconButton";
import { Refresh } from "@mui/icons-material";

export const WarengruppenMappingView = (

) => {
    const { isPIMModel } = useContext(AppContext)

    const [apiCall, apiBusy] = useApiCaller(api)

    const [liste,setListe] = useState<WarengruppenMappingDisplayJson[]>()

    const columns = useMemo<Column<WarengruppenMappingDisplayJson>[]>(
        () => [
            {
                header: 'BDB',
                field: nameOf<WarengruppenMappingDisplayJson>("bdWarengruppe"),
                sortable: true
            },
            {
                header: 'ERP',
                field: nameOf<WarengruppenMappingDisplayJson>("erpWarengruppeDisplay"),
                sortable: true,
                valueGetter:row =>formatErpWarengruppe(row.erpWarengruppeDisplay)
            }
        ],
        []
    )

    const refresh = useCallback(
        () =>
            apiCall<WarengruppenMappingListeJson>({
                method: 'GET',
                rest: 'warengruppe/mapping/liste',
                onSuccess: (data) => {
                    setListe(data.items)
                }
            }),
        [apiCall]
    )

    useEffect(() => {
        refresh()
    }, [refresh]);

    const actions = useMemo(
        () => [
            isPIMModel && (
              <UploadButton
                key="upload"
                path="warengruppe/uploadmapping"
                api={api}
                label="Warengruppen Mapping hochladen"
                variant="text"
                accept={UploadAccepts.Tabellen}
                onComplete={refresh}
              />
            )
        ],
        [isPIMModel, refresh]
    )

    return (
        <Frame space>
            <FrameRow>
                <CardEx backLink title="BDB zu ERP Warengruppen Mapping" topActions={actions}>
                    Bitte laden Sie eine CSV-Tabelle mit Warengruppen für das ERP System.
                    Die Tabelle muss zwei Spalten haben:
                    BDB-Warengruppe und ERP-Warengruppe ohne Header-Zeile
                    Referenzierte ERP Warengruppen müssen vorhanden sein.
                    Löschen von Mappings wird aktuell nicht unterstützt.
                </CardEx>
            </FrameRow>
            <FrameBody>
                <DataTableCard
                    name="DataView"
                    title="Mapping"
                    filterMode="both"
                    columns={columns}
                    value={liste}
                    topActions={<IconButton Icon={Refresh} onClick={refresh}/> }
                    loading={apiBusy}
                    dense
                />
            </FrameBody>
        </Frame>
    )
}
