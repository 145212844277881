export enum UserRoles {
  ADMIN = 'ADMIN',

  LOG_VIEWER = 'LOG_VIEWER',
  DATA_VIEWER = 'DATA_VIEWER',

  STAMMDATEN_EDITOR = 'STAMMDATEN_EDITOR',
  EKRABATTGRUPPEN_EDITOR = 'EKRABATTGRUPPEN_EDITOR',
  BENUTZERHILFE_EDITOR = 'BENUTZERHILFE_EDITOR',

  /** Editor-Recht für verschiedene Einstellungen durch einen Gesellschafter */
  GS_EIN_EDITOR = 'GS_EIN_EDITOR',

  STANDORT_EDITOR = 'STANDORT_EDITOR',

  SYSLABEL_EDITOR = 'SYSLABEL_EDITOR',

  EKPREISIMPORTSTEP_EDITOR = 'EKPREISIMPORTSTEP_EDITOR',

  /** Zusaätzliche Features für Tester */
  TESTER = 'TESTER',

  EKPREIS_EDITOR = 'EKPREIS_EDITOR',
  VKPREIS_EDITOR = 'VKPREIS_EDITOR',

  EOS_MIGRATION_OP = 'EOS_MIGRATION_OP',

  ERP_DOWNLOAD = 'ERP_DOWNLOAD',

  EIGENPFLEGE_EDITOR = 'EIGENPFLEGE_EDITOR',

  ARTIKELDATENPOOL_EDITOR = 'ARTIKELDATENPOOL_EDITOR',
}
