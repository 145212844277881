import { Grid, Typography } from '@mui/material'
import { LieferantField } from '@one/components/Lieferant/LieferantField'
import { ArtikelDatenpoolSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { SearchButton } from '@utils/ui/Buttons/SearchButton'
import { CardEx } from '@utils/ui/CardEx'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { TextField } from '@utils/ui/fields/TextField'
import { amber, orange, red, yellow } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import { Medal } from '@utils/ui/fields/Medal'

export interface ArtikelDatenPoolUebersichtParamsProps {
  value: ArtikelDatenpoolSearcherCriteriaJson
  onSearch: VoidFunction
  onChange: ChangeFunction<ArtikelDatenpoolSearcherCriteriaJson>
}

const useStyles = makeStyles()((theme: any) => ({
  alert: {
    fontSize: '16px',
    marginBottom: 8
  }
}))

export const ArtikelDatenPoolUebersichtParams = ({
  value,
  onChange,
  onSearch
}: ArtikelDatenPoolUebersichtParamsProps) => {
  const { classes } = useStyles()

  const shortcuts = {
    Enter: onSearch
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <CardEx
        collapsiable
        title="Artikeldatenpool-Recherche"
        subheader={
          <Medal
            variant="square"
            backgroundColor={yellow[300]}
            text="NICHT GEPRÜFTE DATEN VOM LIEFERANTEN!"
            className={classes.alert}
          />
        }
        backLink
        bottomActions={<SearchButton onClick={onSearch} />}
      >
        <Grid container spacing={3}>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextField
                fullWidth
                label="hage-Nr."
                name="hageNummer"
                value={value.hageNummer}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={16} sm={6} md={6} lg={6}>
              <LieferantField
                fullWidth
                label="Lieferant"
                name="lieferant"
                value={value.lieferant}
                onChange={onChange}
                modus="zentral"
              />
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth label="EAN" name="gtin" value={value.gtin} onChange={onChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <TextField fullWidth label="IAN" name="lan" value={value.lan} onChange={onChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                name="lieferantWarenGruppe"
                label="Warengruppe"
                value={value.lieferantWarenGruppe}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                name="lieferantArtikelBez"
                label="Bezeichung"
                value={value.lieferantArtikelBez}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
