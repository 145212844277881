import {
  MengeneinheitJson,
  VkFrachtArt,
  VkFrachtkostenJson,
  VkPreisBasisTyp,
  VkPreiseBearbeitenJson,
  VkPreisGruppeJson,
  VkPreisJson,
  VkPreisListeBlattJson,
  VkPreisListeEintragJson,
  VkPreisQuelle,
  VkPreisStaffelTyp,
  VkRundungsregelJson
} from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { roundNearest, roundNumber } from '@utils/numberutils'
import {
  arrayFindDups,
  arrayItemReplace,
  buildMap,
  copyOfArray,
  copyOfObject,
  nextKey
} from '@utils/utils'
import isEqual from 'react-fast-compare'
import { VkPreiseBearbeitenKopfEx } from './VkPreisKopf'
import {
  VkPreiseBearbeitenEdit,
  VkPreisEdit,
  VkPreisListeBlattEdit,
  VkPreisListeEintragEdit
} from './VkPreisTypes'

const PG_EK = 'EK'
const PG_LP = 'LP'

const calcVKPreisDiff = (row: VkPreisEdit): number => {
  if (row.preis >= 0 && row.preisAlt > 0) {
    const diff = row.preis - row.preisAlt
    return (diff * 100) / row.preisAlt
  }
  return null
}

const roundByRule = (
  preis: number,
  ganzeCent: number | null | false,
  mwstSatz: number | null | false
) => {
  let val = preis && mwstSatz ? preis + preis * mwstSatz : preis
  val = ganzeCent ? roundNearest(val, ganzeCent) : val
  return roundNumber(val)
}

// eslint-disable-next-line complexity
const onPreisValueChange = (
  mwstSatz: number,
  basisPreisgruppe: VkPreisGruppeJson,
  blatt: VkPreisListeBlattEdit,
  ep: VkPreisEdit,
  name: string,
  forEinzelhandel: boolean,
  mengeneinheiten: MengeneinheitJson[],
  row0?: VkPreisEdit
) => {
  row0 =
    row0 || blatt.preise.find((p) => p.preisGruppeId === ep.preisGruppeId && p.staffelmenge === 0)
  switch (name) {
    case 'basisTyp':
      {
        ep.spanne = null
        ep.aufschlag = null

        const ekFreiHaus = blatt.effektiverEkFreiHaus
        const listenpreis = blatt.effektiverListenpreis

        switch (ep.basisTyp) {
          case VkPreisBasisTyp.KALK_EK:
            ep.preis = ekFreiHaus != null ? ekFreiHaus : listenpreis
            ep.basis = ep.preis
            ep.basisBrutto = false // TODO besser aus Preisgruppe
            ep.preisx = ep.preis
            break

          case VkPreisBasisTyp.LISTENPREIS:
            ep.preis = listenpreis
            ep.basis = ep.preis
            ep.basisBrutto = false // TODO besser aus Preisgruppe
            ep.preisx = ep.preis
            break

          case VkPreisBasisTyp.PREISGRUPPE:
            {
              const pgr =
                basisPreisgruppe &&
                blatt.preise.find((p) => p.preisGruppeId === basisPreisgruppe.id)
              ep.basis = pgr?.preis
              ep.basisBrutto = pgr?.brutto
              ep.preis = ep.basis
              ep.preisx = ep.preis
            }
            break

          case VkPreisBasisTyp.MANUELL:
            {
              const pgr =
                basisPreisgruppe &&
                blatt.preise.find((p) => p.preisGruppeId === basisPreisgruppe.id)
              ep.basis =
                forEinzelhandel || pgr?.brutto
                  ? blatt.effektiverEkPreisBrutto
                  : blatt.effektiverEkPreis
              ep.basisBrutto = pgr?.brutto
              // ep.preis = ep.basis
              // ep.preisx = ep.preis
            }
            break

          default:
            ep.basis = ekFreiHaus != null ? ekFreiHaus : listenpreis
            ep.basisBrutto = false
            ep.preis = null
            ep.preisx = null
            break
        }
        ep.spanne = ep.preis ? -roundNumber((ep.basis / ep.preis - 1) * 100) : null
      }
      break

    case 'preis':
      {
        if (ep.preisx !== ep.preis) {
          if (ep.preis == null || ep.preis === ep.basis) {
            ep.aufschlag = null
            ep.spanne = null
            ep.staffelRabattAbs = null
            ep.staffelRabattProz = null
          } else if (ep.aufschlag != null) {
            if (ep.staffelmenge === 0) {
              ep.aufschlag = ep.basis ? roundNumber((ep.preis / ep.basis - 1) * 100) : null
            } else {
              ep.staffelRabattAbs = null
              ep.staffelRabattProz = null
            }
          } else {
            if (ep.staffelmenge === 0) {
              ep.spanne = ep.preis ? -roundNumber((ep.basis / ep.preis - 1) * 100) : null
            } else {
              ep.staffelRabattAbs = null
              ep.staffelRabattProz = null
            }
          }
        }
        ep.preisx = ep.preis
      }
      break

    case 'spanne':
      {
        // 0 ist erlaubt
        ep.aufschlag = null
        if (ep.spanne != null) {
          ep.spanne = Math.min(ep.spanne, 100)
          if (ep.spanne !== 100) {
            ep.preis = roundByRule(
              ep.basis / (1 - ep.spanne / 100),
              ep.runden && blatt.rundungGanzeCent,
              ep.brutto && !ep.basisBrutto && mwstSatz
            )
          } else if (ep.basis !== 0 && ep.basis != null) {
            ep.preis = roundByRule(
              ep.basis,
              ep.runden && blatt.rundungGanzeCent,
              ep.brutto && !ep.basisBrutto && mwstSatz
            )
          }
        } else {
          ep.preis = roundByRule(
            ep.basis,
            ep.runden && blatt.rundungGanzeCent,
            ep.brutto && !ep.basisBrutto && mwstSatz
          )
        }
        ep.preisx = ep.preis
      }
      break

    case 'aufschlag':
      {
        // 0 ist erlaubt
        ep.spanne = null
        if (ep.aufschlag != null) {
          ep.preis = roundByRule(
            ep.basis * (1 + ep.aufschlag / 100),
            ep.runden && blatt.rundungGanzeCent,
            ep.brutto && !ep.basisBrutto && mwstSatz
          )
        } else {
          ep.preis = roundByRule(
            ep.basis,
            ep.runden && blatt.rundungGanzeCent,
            ep.brutto && !ep.basisBrutto && mwstSatz
          )
        }
        ep.preisx = ep.preis
      }
      break

    case 'vpeinheitId':
    case 'vpmenge':
      {
        const me = ep.vpeinheitId ? mengeneinheiten.find((me) => me.id === ep.vpeinheitId) : null
        if (me != null && me.faktorNenner && me.faktorZaehler && me.faktorNenner && ep.vpmenge) {
          ep.staffelmenge = (ep.vpmenge * me.faktorZaehler) / me.faktorNenner
        } else {
          ep.staffelmenge = null
        }
      }
      break

    case 'staffelTyp':
      {
        switch (ep.staffelTyp) {
          case VkPreisStaffelTyp.ABSOLUT:
            ep.staffelRabattAbs = null
            ep.staffelRabattProz = null
            ep.basisTyp = VkPreisBasisTyp.MANUELL
            break
          case VkPreisStaffelTyp.RABATT_ABS:
            ep.preis = null
            ep.preisx = null
            ep.staffelRabattProz = null
            ep.basisTyp = VkPreisBasisTyp.STAFFEL
            break
          case VkPreisStaffelTyp.RABATT_PROZ:
          case VkPreisStaffelTyp.RABATT_PROZ_VERD:
            ep.preis = null
            ep.preisx = null
            ep.staffelRabattAbs = null
            ep.staffelRabattProz = null
            ep.basisTyp = VkPreisBasisTyp.STAFFEL
            break
          default:
            ep.basisTyp = VkPreisBasisTyp.STAFFEL
            break
        }
      }
      break

    case 'staffelRabattProz':
      {
        if (ep.staffelRabattProz && ep.staffelRabattProz !== 100 && row0.preis) {
          ep.preis = roundByRule(
            row0.preis * (1 + ep.staffelRabattProz / 100),
            ep.runden && blatt.rundungGanzeCent,
            false
          )
        } else {
          ep.preis = null
        }
        ep.preisx = ep.preis
      }
      break

    case 'staffelRabattAbs':
      {
        if (ep.staffelRabattAbs && row0.preis) {
          ep.preis = roundByRule(
            row0.preis + ep.staffelRabattAbs,
            ep.runden && blatt.rundungGanzeCent,
            false
          )
        } else {
          ep.preis = null
        }
        ep.preisx = ep.preis
      }
      break

    case 'reroundpreis':
      {
        ep.preis = roundByRule(
          ep.preisx,
          ep.runden && blatt.rundungGanzeCent,
          ep.brutto && !ep.basisBrutto && mwstSatz
        )
      }
      break

    default:
      break
  }

  ep.preisDelta = calcVKPreisDiff(ep)
}

export const initVkPreisblattPreise = (
  blatt: VkPreisListeBlattEdit,
  preisgruppen: VkPreisGruppeJson[],
  expandPreisGruppen: boolean,
  alteStandortPreise: Map<string, VkPreisJson>,
  rundungsregelnMap: Map<number, VkRundungsregelJson>,
  defaultReferenzPreisgruppeId: number | null,
  rundungsregelGanzeCentId: number | null,
  forEinzelhandel: boolean,
  mengeneinheiten: MengeneinheitJson[],
  mwstSatz: number,
  basisPreisgruppe: VkPreisGruppeJson
) => {
  const groupPreisByPG = (preise: VkPreisEdit[]) => {
    return preise.reduce((map, preis) => {
      let pg = map.get(preis.preisGruppeId)
      if (pg == null) {
        pg = []
        map.set(preis.preisGruppeId, pg)
      }
      pg.push({ ...preis })
      return map
    }, new Map<number, VkPreisEdit[]>())
  }

  const pgEKId = preisgruppen.find((pg) => pg.name === PG_EK)?.id
  const pgLPId = preisgruppen.find((pg) => pg.name === PG_LP)?.id

  const initPreiseInGruppe = (gp: VkPreisEdit[], preisgruppe: VkPreisGruppeJson) => {
    if (
      gp.find((p) => p.staffelmenge === 0) == null &&
      expandPreisGruppen &&
      (!forEinzelhandel || !preisgruppe.kasse)
    ) {
      gp.push({
        preisGruppeId: preisgruppe.id,
        staffelmenge: 0,
        basis: forEinzelhandel ? blatt.effektiverEkPreisBrutto : null,
        basisBrutto: forEinzelhandel,
        preis: null,
        basisTyp: forEinzelhandel ? VkPreisBasisTyp.MANUELL : null
      } as VkPreisEdit)
    }

    gp.forEach((preis) => {
      preis.key = nextKey()
      preis.preisGruppeName = preisgruppe.name
      preis.preisGruppeBez = preisgruppe.bezeichnung
      preis.brutto = preisgruppe.brutto
      preis.kasse = preisgruppe.kasse
      preis.runden = preisgruppe.runden
      preis.basisBrutto = preis.basisBrutto || forEinzelhandel

      if (preis.basisTyp == null) {
        if (preis.kasse) {
          preis.basisTyp = VkPreisBasisTyp.PREISGRUPPE
        } else if (pgEKId && preis.preisGruppeId === pgEKId) {
          preis.basisTyp = VkPreisBasisTyp.KALK_EK
        } else if (pgLPId && preis.preisGruppeId === pgLPId) {
          preis.basisTyp = VkPreisBasisTyp.LISTENPREIS
        } else {
          // preis.basisTyp = VkPreisBasisTyp.PREISGRUPPE
        }
      }

      preis.staffelCount = null
      if (preis.staffelmenge > 0) {
        if (preis.staffelTyp == null) {
          preis.staffelTyp = VkPreisStaffelTyp.ABSOLUT
        }
      }

      if (alteStandortPreise) {
        const ap = alteStandortPreise.get(`${preis.preisGruppeId}-${preis.staffelmenge}`)
        if (ap) {
          preis.preisAlt = ap.preis
          preis.preisDelta = calcVKPreisDiff(preis)
        }
      }

      // onPreisValueChange(
      //   mwstSatz,
      //   basisPreisgruppe,
      //   blatt,
      //   preis,
      //   (preis.spanne != null && 'spanne') ||
      //     (preis.aufschlag != null && 'aufschlag') ||
      //     'basisTyp',
      //   mengeneinheiten
      // )
    })

    if (gp.length > 1) {
      const p0 = gp.find((p) => p.staffelmenge === 0)
      if (p0) {
        p0.staffelCount = gp.length - 1
      }
    }

    return gp
  }

  const initPreise = (preise: VkPreisEdit[]) => {
    const preiseNachGruppeMap = groupPreisByPG(preise)
    let result = []
    preisgruppen.forEach((preisgruppe) => {
      const gp = initPreiseInGruppe(preiseNachGruppeMap.get(preisgruppe.id) || [], preisgruppe)
      result = result.concat(gp)
    })

    return result
  }

  blatt.preise = initPreise(blatt.preise)
  blatt.standortVk =
    /* blatt.standortNr === '0' ||*/
    !!blatt.standortVk ||
    blatt.preise.find(
      (p) =>
        p.spanne != null ||
        p.aufschlag != null ||
        (forEinzelhandel ? p.preis != null : p.basisTyp === VkPreisBasisTyp.MANUELL)
    ) != null

  if (
    !blatt.abgeschlossen &&
    blatt.rundungsregelId == null
    /*&& blatt.id === null sonst geht standort0 nicht, der vom server kommt...  */
  ) {
    blatt.rundungsregelId = rundungsregelGanzeCentId
  }
  const regel = rundungsregelnMap.get(blatt.rundungsregelId)
  blatt.rundungGanzeCent = regel && regel.ganzeCent

  blatt.referenzPreisgruppeId = blatt.referenzPreisgruppeId || defaultReferenzPreisgruppeId // preisgruppen[2].id
}

export const initVkPreise = (
  kopf: VkPreiseBearbeitenKopfEx,
  data: VkPreiseBearbeitenJson
): VkPreiseBearbeitenEdit => {
  const { standorte, preisgruppen, rundungsregeln } = kopf

  const rundungsregelGanzeCentId = rundungsregeln?.find((rr) => rr.ganzeCent === 0)?.id
  const alteStandortPreise = new Map<number, Map<string, VkPreisJson>>()

  if (data.altePreise && data.altePreise.preisblaetter) {
    data.altePreise.preisblaetter
      .filter((pb) => pb.preise)
      .forEach((pb) => {
        const preismap = new Map<string, VkPreisJson>()
        alteStandortPreise.set(pb.standortId, preismap)
        pb.preise.forEach((preis) => {
          const { preisGruppeId, staffelmenge } = preis
          preismap.set(`${preisGruppeId}-${staffelmenge}`, preis)
        })
      })
  }

  const mengeneinheiten = data.mengeneinheiten

  const neueKondiOrg = data.neueKondi ? data.neueKondi : ({} as VkPreisListeEintragJson)

  const listeDisplay = data.listeDisplay || {}
  const gueltigVon = neueKondiOrg.gueltigVon || listeDisplay.gueltigVon
  const gueltigBis = neueKondiOrg.gueltigVon ? neueKondiOrg.gueltigBis : listeDisplay.gueltigBis

  // Test neueKondiOrg.basisPreisgruppeId = 44009

  const basisPreisgruppe =
    neueKondiOrg.basisPreisgruppeId && kopf.preisgruppenMap.get(neueKondiOrg.basisPreisgruppeId)

  const alleEkFreiHaus = neueKondiOrg.alleEkFreiHaus || {}
  const alleListenpreise = neueKondiOrg.alleListenpreise || {}

  const blaetterMap = buildMap(
    copyOfArray(neueKondiOrg.blaetter),
    (b: VkPreisListeBlattJson) => b.standortId
  )

  const blaetter = []
  standorte
    .filter((standort) => blaetterMap.get(standort.id) || !standort.ausgeblendet)
    .forEach((standort) => {
      const blatt = (copyOfObject(blaetterMap.get(standort.id)) || {
        id: null,
        standortId: standort.id,
        preise: []
      }) as VkPreisListeBlattEdit

      blatt.key = nextKey()
      blatt.standortNr = standort.nr
      blatt.warAbgeschlossen = blatt.abgeschlossen
      blatt.effektiverListenpreis = alleListenpreise[standort.id] || neueKondiOrg.listenpreis
      blatt.effektiverEkFreiHaus = alleEkFreiHaus[standort.id] || neueKondiOrg.ekFreiHaus
      blatt.effektiverEkPreis = blatt.effektiverEkFreiHaus || blatt.effektiverListenpreis

      blatt.effektiverEkPreisBrutto =
        blatt.effektiverEkPreis &&
        roundNumber(blatt.effektiverEkPreis * (1 + neueKondiOrg.mwstSatz))

      blatt.quelle = blatt.quelle || (data.forEinzelhandel ? VkPreisQuelle.LISTUNG : null)
      blatt.quelleStaffel =
        blatt.quelleStaffel || (data.forEinzelhandel ? VkPreisQuelle.LISTUNG : null)
      blatt.kalkulationsVorschlagId = data.forEinzelhandel ? null : blatt.kalkulationsVorschlagId // sollte leer sein... war es aber nicht...

      initVkPreisblattPreise(
        blatt,
        preisgruppen,
        true,
        alteStandortPreise.get(standort.id),
        kopf.rundungsregelnMap,
        neueKondiOrg.defaultReferenzPreisgruppeId,
        rundungsregelGanzeCentId,
        data.forEinzelhandel,
        mengeneinheiten,
        neueKondiOrg.mwstSatz,
        basisPreisgruppe
      )

      const refPreis =
        blatt.referenzPreisgruppeId &&
        blatt.preise.find((p) => p.preisGruppeId === blatt.referenzPreisgruppeId)

      // blatt.preise = blatt.preise?.map((preis) => {
      //   if (preis.kasse) {
      //     const ep = {
      //       ...preis,
      //       basis: refPreis?.basis,
      //       basisTyp: refPreis?.basisTyp,
      //       basisBrutto: refPreis?.brutto,
      //       spanne: refPreis?.spanne,
      //       aufschlag: refPreis?.aufschlag,
      //       preis:
      //         refPreis == null
      //           ? null
      //           : roundByRule(
      //               refPreis.preis,
      //               preis.runden && blatt.rundungGanzeCent,
      //               preis.brutto && !refPreis?.brutto && neueKondiOrg.mwstSatz
      //             )
      //     } as VkPreisEdit
      //     ep.preisDelta = calcVKPreisDiff(ep)
      //     return ep
      //   }
      //   return preis
      // })

      blaetter.push(blatt)
    })

  const neueKondi = {
    ...neueKondiOrg,
    gueltigVon,
    gueltigBis,
    blaetter,
    basisPreisgruppe
    // ekFreiHausMap
  } as VkPreisListeEintragEdit

  const standort0Id =
    blaetter.find((b) => b.standortVk)?.standortId ||
    blaetter.map((b) => b.standortId).find(Boolean) ||
    standorte.filter((s) => !s.ausgeblendet).find((s) => s.defaultStandort)?.id

  return {
    ...data,
    neueKondi,
    alteStandortPreise,
    rundungsregelnMap: kopf.rundungsregelnMap,
    preisgruppen: data.forEinzelhandel
      ? kopf.preisgruppen.filter((p) => p.brutto)
      : kopf.preisgruppen,
    kalkulationsvorschlaegeMap: kopf.kalkulationsvorschlaegeMap,
    standorte: kopf.standorte,
    standort0Id,
    kalkulationsvorschlaege: kopf.kalkulationsvorschlaege,
    rundungsregeln: kopf.rundungsregeln,
    currency: '€',
    kontext: data.listeDisplay?.kontext,
    betriebstyp: data.listeDisplay?.betriebstyp,
    rundungsregelGanzeCentId
  } as VkPreiseBearbeitenEdit
}

export const updatePreis = (
  model: VkPreiseBearbeitenEdit,
  standortId: number,
  preis: VkPreisEdit,
  name: string,
  value: any
) => {
  const { mengeneinheiten } = model
  const rb = model.neueKondi.blaetter.find((i) => i.standortId === standortId)
  if (rb == null) {
    return model
  }
  const rp = preis
  // const rp = rb.preise.find((i) => i === preis)
  // if (rp == null) {
  //   return model
  // }

  if (isEqual(rp[name], value)) {
    return model
  }

  let eb = { ...rb }

  if (model.forEinzelhandel && value != null) {
    eb.referenzPreisgruppeId = rp.preisGruppeId
  }

  const ep = { ...rp, [name]: value }

  onPreisValueChange(
    model.neueKondi.mwstSatz,
    model.neueKondi.basisPreisgruppe,
    eb,
    ep,
    name,
    model.forEinzelhandel,
    mengeneinheiten
  )

  let preise = arrayItemReplace(eb.preise, (i) => i.key === ep.key, ep)

  if (ep.staffelmenge === 0) {
    if (ep.basisTyp == null) {
      // Wenn basisTyp leer, lösche alle Staffelzeilen
      preise = preise.filter((i) => i.staffelmenge === 0 || i.preisGruppeId !== ep.preisGruppeId)
    } else {
      preise = preise.map((p) => {
        if (p.staffelmenge === 0 || p.staffelTyp === VkPreisStaffelTyp.ABSOLUT) {
          return p
        }
        const pp = { ...p }
        onPreisValueChange(
          model.neueKondi.mwstSatz,
          model.neueKondi.basisPreisgruppe,
          eb,
          pp,
          (pp.staffelRabattAbs && 'staffelRabattAbs') ||
            (pp.staffelRabattProz && 'staffelRabattProz'),
          model.forEinzelhandel,
          mengeneinheiten,
          ep
        )
        return pp
      })
    }
  }

  const staffelGroup = preise.filter((p) => p.preisGruppeId === ep.preisGruppeId)
  const sp = staffelGroup.find((p) => p.staffelmenge === 0)

  const hasNullMenge = staffelGroup.filter((i) => i.staffelmenge === null).length > 0
  const hasDupMenge = arrayFindDups(staffelGroup.map((i) => i.staffelmenge)).length > 0

  const hasNullPreise =
    staffelGroup
      .filter((i) => i.staffelmenge > 0)
      .filter((i) => {
        switch (i.staffelTyp) {
          case VkPreisStaffelTyp.ABSOLUT:
            return i.preis == null
          case VkPreisStaffelTyp.RABATT_ABS:
            return i.staffelRabattAbs == null
          case VkPreisStaffelTyp.RABATT_PROZ:
          case VkPreisStaffelTyp.RABATT_PROZ_VERD:
            return i.staffelRabattProz == null
          default:
            return false
        }
      }).length > 0

  const staffelErr = staffelGroup.length > 1 && (hasNullMenge || hasDupMenge || hasNullPreise)
  const staffelCount = staffelGroup.length - 1 || null

  preise = arrayItemReplace(preise, (i) => i.key === sp.key, {
    ...sp,
    staffelErr,
    staffelCount
  })

  let changedReferenzPreis =
    ep.preisGruppeId === eb.referenzPreisgruppeId && ep.staffelmenge === 0 ? ep : null

  if (ep.staffelmenge === 0 && ep.preisGruppeId === model.neueKondi.basisPreisgruppeId) {
    preise = preise.map((preis) => {
      if (preis.basisTyp === VkPreisBasisTyp.PREISGRUPPE && !preis.kasse) {
        const p = { ...preis }
        if (p.preisGruppeId === eb.referenzPreisgruppeId) {
          changedReferenzPreis = p
        }

        p.basis = ep.preis
        p.basisBrutto = ep.brutto

        p.preis = roundByRule(
          p.basis,
          p.runden && p.rundungGanzeCent,
          p.brutto && !p.basisBrutto && model.neueKondi.mwstSatz
        )
        onPreisValueChange(
          model.neueKondi.mwstSatz,
          model.neueKondi.basisPreisgruppe,
          eb,
          p,
          (ep.staffelmenge !== 0 &&
            ((ep.staffelRabattProz && 'staffelRabattProz') ||
              (ep.staffelRabattAbs && 'staffelRabattAbs'))) ||
            preis.aufschlag != null
            ? 'aufschlag'
            : 'spanne',
          model.forEinzelhandel,
          mengeneinheiten,
          ep
        )
        return p
      }
      return preis
    })
  }

  // Update Preise, wenn Basis/Referenzpreis geändert wurde....
  if (changedReferenzPreis) {
    preise = preise.map((preis) => {
      if (preis.kasse || (model.forEinzelhandel && eb.festpreis && preis != changedReferenzPreis)) {
        const p: VkPreisEdit = {
          ...preis,
          basis: changedReferenzPreis.basis,
          basisTyp: changedReferenzPreis.basisTyp,
          basisBrutto: changedReferenzPreis.basisBrutto,
          spanne: changedReferenzPreis.spanne,
          aufschlag: changedReferenzPreis.aufschlag,
          preis: roundByRule(
            changedReferenzPreis.preis,
            preis.runden && preis.rundungGanzeCent,
            preis.brutto && !changedReferenzPreis.brutto && model.neueKondi.mwstSatz
          )
        }
        return p
      }
      return preis
    })
  }

  eb = { ...eb, preise }
  const ek = {
    ...model.neueKondi,
    blaetter: arrayItemReplace(model.neueKondi.blaetter, (i) => i.key === eb.key, eb)
  }

  return { ...model, neueKondi: ek }
}

export const updateBlatt = (
  model: VkPreiseBearbeitenEdit,
  standortId: number,
  modifier: (blatt: VkPreisListeBlattEdit) => VkPreisListeBlattEdit
) => {
  const rb = model.neueKondi.blaetter.find((i) => i.standortId === standortId)
  if (rb == null) {
    return model
  }

  const eb = modifier({ ...rb })
  if (eb == null || isEqual(eb, rb)) {
    return model
  }

  const ek = {
    ...model.neueKondi,
    blaetter: arrayItemReplace(model.neueKondi.blaetter, (i) => i.key === eb.key, eb)
  }

  return { ...model, neueKondi: ek }
}

export const updateBlattValue = (
  model: VkPreiseBearbeitenEdit,
  standortId: number,
  name: string,
  value: any
) =>
  updateBlatt(model, standortId, (blatt) => {
    blatt[name] = value
    return blatt
  })

export interface VkFrachkostenOwner {
  frachtkosten?: VkFrachtkostenJson
  effektiverEkPreis?: number
}

export const updateFrachtkosten = <T extends VkFrachkostenOwner>(
  blatt: T,
  name: string,
  value: any
): T => {
  const copy = { ...blatt, frachtkosten: { ...blatt.frachtkosten } }
  copy.frachtkosten[name] = value
  switch (name) {
    case 'frachtArt':
      {
        switch (value) {
          case VkFrachtArt.GEWICHT:
          case VkFrachtArt.FIX:
            copy.frachtkosten.frachtAbs = 0
            copy.frachtkosten.frachtProz = null
            // copy.frachtkosten.frachtNichtSkonto = false
            // copy.frachtkosten.frachtNachRabatt = false
            break
          case VkFrachtArt.PROZENT:
            copy.frachtkosten.frachtAbs = null
            copy.frachtkosten.frachtProz = 0
            // copy.frachtkosten.frachtNichtSkonto = false
            // copy.frachtkosten.frachtNachRabatt = false
            break
          default:
            copy.frachtkosten.frachtAbs = null
            copy.frachtkosten.frachtProz = null
            copy.frachtkosten.frachtNichtSkonto = null
            copy.frachtkosten.frachtNachRabatt = null
        }
      }
      break

    case 'frachtProz':
      {
        if (copy.frachtkosten.frachtArt === VkFrachtArt.PROZENT) {
          copy.frachtkosten.frachtAbs =
            copy.effektiverEkPreis == null || copy.frachtkosten.frachtProz == null
              ? null
              : roundNumber((copy.effektiverEkPreis * copy.frachtkosten.frachtProz) / 100, 2)
        }
      }
      break
  }

  return copy
}

export const updateBlattFrachtkosten = (
  model: VkPreiseBearbeitenEdit,
  standortId: number,
  name: string,
  value: any
) => updateBlatt(model, standortId, (blatt) => updateFrachtkosten(blatt, name, value))

export const updateRundungsregel = (model: VkPreiseBearbeitenEdit, standortId, rundungsregelId) => {
  const { mengeneinheiten, rundungsregelnMap } = model
  const rundungsregel = rundungsregelId && rundungsregelnMap.get(rundungsregelId)
  const ganzeCent = rundungsregel && rundungsregel.ganzeCent
  const { neueKondi } = model
  return updateBlatt(model, standortId, (blatt) => {
    blatt.rundungsregelId = rundungsregelId
    blatt.rundungGanzeCent = ganzeCent
    if (blatt.preise == null) {
      return blatt
    }

    const p0 = blatt.preise.find((p) => p.staffelmenge === 0)

    blatt.preise = blatt.preise.map((preis) => {
      const ep = { ...preis }
      onPreisValueChange(
        neueKondi.mwstSatz,
        neueKondi.basisPreisgruppe,
        blatt,
        ep,
        (ep.staffelmenge !== 0 &&
          ((ep.staffelRabattProz && 'staffelRabattProz') ||
            (ep.staffelRabattAbs && 'staffelRabattAbs'))) ||
          (preis.spanne != null && 'spanne') ||
          (preis.aufschlag != null && 'aufschlag') ||
          'reroundpreis',
        model.forEinzelhandel,
        mengeneinheiten,
        p0
      )
      return ep
    })

    const changedReferenzPreis = blatt.preise.find(
      (p) => p.preisGruppeId === blatt.referenzPreisgruppeId && p.staffelmenge === 0
    )

    if (changedReferenzPreis) {
      blatt.preise = blatt.preise.map((preis) => {
        if (preis.kasse) {
          return {
            ...preis,
            basis: changedReferenzPreis.basis,
            basisTyp: changedReferenzPreis.basisTyp,
            basisBrutto: changedReferenzPreis.basisBrutto,
            spanne: changedReferenzPreis.spanne,
            aufschlag: changedReferenzPreis.aufschlag,
            preis: roundByRule(
              changedReferenzPreis.preis,
              preis.runden && blatt.rundungGanzeCent,
              preis.brutto && !changedReferenzPreis.basisBrutto && model.neueKondi.mwstSatz
            )
          }
        }
        return preis
      })
    }

    return blatt
  })
}

export const updateReferenzPreis = (
  model: VkPreiseBearbeitenEdit,
  standortId: number,
  referenzPreisgruppeId: number
) => {
  const { neueKondi, mengeneinheiten } = model
  return updateBlatt(model, standortId, (m) => {
    const refPreis =
      referenzPreisgruppeId && m.preise.find((p) => p.preisGruppeId === referenzPreisgruppeId)

    m.referenzPreisgruppeId = referenzPreisgruppeId

    const refPreis0 = m.preise.find((p) => p.kasse && p.staffelmenge === 0)
    if (refPreis0 == null) {
      return m
    }

    const ep0 = {
      ...refPreis0,
      basis: refPreis?.basis,
      basisTyp: refPreis?.basisTyp,
      basisBrutto: refPreis?.brutto,
      spanne: refPreis?.spanne,
      aufschlag: refPreis?.aufschlag,
      preis:
        refPreis == null
          ? null
          : roundByRule(
              refPreis.preis,
              refPreis0.runden && m.rundungGanzeCent,
              refPreis0.brutto && !refPreis?.brutto && neueKondi.mwstSatz
            )
    }

    ep0.preisDelta = calcVKPreisDiff(ep0)

    m.preise = m.preise?.map((preis) => {
      if (preis === refPreis0) {
        return ep0
      }
      // Staffelpreise werden aktuell nicht unterstützt an der ZKASSE...
      // if (preis.preisGruppeId === refPreis0.preisGruppeId && preis.staffelmenge !== 0) {
      //   const ep = { ...preis }
      //   onPreisValueChange(
      //     neueKondi.mwstSatz,
      //     neueKondi.basisPreisgruppe,
      //     m,
      //     ep,
      //     (ep.staffelRabattProz && 'staffelRabattProz') ||
      //       (ep.staffelRabattAbs && 'staffelRabattAbs'),
      //     model.forEinzelhandel,
      //     mengeneinheiten,
      //     ep0
      //   )
      //   return ep
      // }
      return preis
    })

    return m
  })
}

export const setPreisblattKalkulationsvorschlag = (
  model: VkPreiseBearbeitenEdit,
  standortId: number,
  kalkulationsvorschlagId: number
) => {
  const {
    neueKondi,
    mengeneinheiten,
    rundungsregelnMap,
    preisgruppen,
    kalkulationsvorschlaegeMap
  } = model
  const { basisPreisgruppe, defaultReferenzPreisgruppeId } = neueKondi
  const kalkulationsvorschlag = kalkulationsvorschlaegeMap.get(kalkulationsvorschlagId)

  return updateBlatt(model, standortId, (blatt) => {
    blatt.kalkulationsVorschlagId = kalkulationsvorschlag?.id
    if (blatt.kalkulationsVorschlagId == null) {
      // gelöscht, dann alles lassen wie war
      return blatt
    }

    if (kalkulationsvorschlag != null) {
      blatt.preise = kalkulationsvorschlag.preise.map((kr) => {
        return {
          preisGruppeId: kr.preisGruppeId,
          aufschlag: kr.aufschlag,
          basisTyp: kr.basisTyp,
          brutto: kr.brutto,
          etikettpreis: kr.etikettpreis,
          preis: kr.preis,
          spanne: kr.spanne,
          staffelmenge: kr.staffelmenge
        } as VkPreisEdit
      })
    }

    const asp = model.alteStandortPreise.get(standortId)

    initVkPreisblattPreise(
      blatt,
      preisgruppen,
      true,
      asp,
      rundungsregelnMap,
      defaultReferenzPreisgruppeId,
      model.rundungsregelGanzeCentId,
      model.forEinzelhandel,
      mengeneinheiten,
      neueKondi.mwstSatz,
      neueKondi.basisPreisgruppe
    )

    const ekFreiHaus = blatt.effektiverEkPreis
    const listenpreis = blatt.effektiverListenpreis

    const basispreis =
      basisPreisgruppe && blatt.preise.find((kr) => kr.preisGruppeId === basisPreisgruppe.id)

    const referenzpreis =
      blatt.referenzPreisgruppeId &&
      blatt.preise.find((kr) => kr.preisGruppeId === blatt.referenzPreisgruppeId)

    const preiseBasis: VkPreisEdit[] = []
    if (basispreis != null) {
      preiseBasis.push(basispreis)
    }
    if (referenzpreis != null && referenzpreis != basispreis) {
      preiseBasis.push(referenzpreis)
    }

    const preiseRest = blatt.preise.filter((p) => p !== basispreis && p !== referenzpreis)

    preiseBasis.concat(preiseRest).forEach((preis) => {
      if (preis.kasse) {
        preis.spanne = referenzpreis?.spanne
        preis.aufschlag = referenzpreis?.aufschlag
        preis.basis = referenzpreis?.basis
        preis.basisTyp = referenzpreis?.basisTyp
        preis.basisBrutto = referenzpreis?.brutto
        preis.preis = roundByRule(
          referenzpreis?.preis,
          preis.runden && blatt.rundungGanzeCent,
          preis.brutto && !preis.basisBrutto && neueKondi.mwstSatz
        )
      } else {
        switch (preis.basisTyp) {
          case VkPreisBasisTyp.KALK_EK:
            preis.basis = ekFreiHaus ?? listenpreis
            preis.basisBrutto = false
            break
          case VkPreisBasisTyp.LISTENPREIS:
            preis.basis = listenpreis
            preis.basisBrutto = false
            break
          case VkPreisBasisTyp.PREISGRUPPE:
            preis.basis = basispreis?.preis
            preis.basisBrutto = basispreis?.brutto
            break
          default:
            preis.basis = null
            preis.basisBrutto = false
            break
        }

        onPreisValueChange(
          neueKondi.mwstSatz,
          basisPreisgruppe,
          blatt,
          preis,
          (preis.staffelmenge !== 0 &&
            ((preis.staffelRabattProz && 'staffelRabattProz') ||
              (preis.staffelRabattAbs && 'staffelRabattAbs'))) ||
            (preis.spanne != null && 'spanne') ||
            (preis.aufschlag != null && 'aufschlag') ||
            'basisTyp',
          model.forEinzelhandel,
          mengeneinheiten
        )
      }
    })

    return blatt
  })
}

export const setStandorteAbgeschlossen = (model: VkPreiseBearbeitenEdit) => {
  let copy = model
  model.neueKondi.blaetter
    .map((b) => b.standortId)
    .forEach((standortId) => {
      copy = updateBlatt(copy, standortId, (blatt) => {
        blatt.abgeschlossen = blatt.standortVk
        return blatt
      })
    })

  return copy
}

const addStaffel = (model: VkPreiseBearbeitenEdit, standortId: number, preis: VkPreisEdit) => {
  const preisGruppeId = preis.preisGruppeId
  const preisKey = preis.key
  return updateBlatt(model, standortId, (b) => {
    const sp = b.preise.find((p) => p.key === preisKey)
    const me =
      model.mengeneinheiten.find((m) => m.id === model.neueKondi.mengenEinheitId) ||
      ({} as MengeneinheitJson)
    const np: VkPreisEdit = {
      key: nextKey(),
      id: null,
      version: null,
      preisGruppeId: preisGruppeId,
      basisTyp: VkPreisBasisTyp.MANUELL,
      staffelTyp: VkPreisStaffelTyp.ABSOLUT,
      staffelmenge: null,
      vpeinheitId: model.neueKondi.mengenEinheitId,
      vpeeinheitName: me.bez || 'Menge'
    }
    const nl = [...b.preise, np]
    const len = nl.filter((i) => i.preisGruppeId === preisGruppeId).length
    const staffelErr =
      nl.filter((i) => i.preisGruppeId === preisGruppeId && i.staffelmenge == null).length > 0

    b.preise = arrayItemReplace(nl, (i) => i.key === preisKey, {
      ...sp,
      staffelCount: len > 1 ? len - 1 : null,
      staffelErr
    } as VkPreisEdit)

    return b
  })
}

const removeStaffel = (model: VkPreiseBearbeitenEdit, standortId: number, preis: VkPreisEdit) => {
  const preisGruppeId = preis.preisGruppeId
  const preisKey = preis.key
  return updateBlatt(model, standortId, (b) => {
    const sp = b.preise.find((i) => i.preisGruppeId === preisGruppeId && i.staffelmenge === 0)
    b.preise = arrayItemReplace(b.preise, (i) => i.key === preisKey, null)
    const len = b.preise.filter((i) => i.preisGruppeId === preisGruppeId).length

    const staffelErr =
      b.preise.filter((i) => i.preisGruppeId === preisGruppeId && i.staffelmenge == null).length > 0

    b.preise = arrayItemReplace(b.preise, (i) => i === sp, {
      ...sp,
      staffelCount: len > 1 ? len - 1 : null,
      staffelErr
    } as VkPreisEdit)
    return b
  })
}

export const initVkPreiseZumSpeichern = (data: VkPreiseBearbeitenEdit) => {
  const neueKondi = data.neueKondi

  const next: VkPreisListeEintragJson = {
    id: neueKondi.id,
    version: neueKondi.version,
    gueltigVon: neueKondi.gueltigVon,
    gueltigBis: neueKondi.gueltigBis,
    mengenEinheit: neueKondi.mengenEinheit,
    listenpreis: neueKondi.listenpreis,
    preismenge: neueKondi.preismenge,
    ekFreiHaus: neueKondi.ekFreiHaus,
    mengenEinheitEkEinheit: neueKondi.mengenEinheitEkEinheit,
    listenpreisEkEinheit: neueKondi.listenpreisEkEinheit,
    preismengeEkEinheit: neueKondi.preismengeEkEinheit,
    ekFreiHausEkEinheit: neueKondi.ekFreiHausEkEinheit,
    alleListenpreise: neueKondi.alleListenpreise,
    alleEkFreiHaus: neueKondi.alleEkFreiHaus,
    mwstSatz: neueKondi.mwstSatz,
    basisPreisgruppeId: neueKondi.basisPreisgruppeId,
    defaultReferenzPreisgruppeId: neueKondi.defaultReferenzPreisgruppeId,
    // audit,
    // mengenEinheitEkEinheitId,
    // mengenEinheitEkEinheitKurz,
    // mengenEinheitId,
    // mengenEinheitKurz
    blaetter: neueKondi.blaetter
      .filter((blatt) => blatt.standortVk)
      .map((blatt) => {
        const blattCleaned: VkPreisListeBlattJson = {
          id: blatt.id,
          version: blatt.version,
          standortId: blatt.standortId,
          standortVk: blatt.standortVk,
          kalkulationsVorschlagId: blatt.kalkulationsVorschlagId,
          rundungsregelId: blatt.rundungsregelId,
          referenzPreisgruppeId: blatt.referenzPreisgruppeId,
          abgeschlossen: blatt.abgeschlossen,
          frachtkosten: blatt.frachtkosten,
          festpreis: blatt.festpreis,
          quelle: blatt.quelle,
          quelleStaffel: blatt.quelleStaffel,
          preise: blatt.preise
            .filter(
              (preis) =>
                preis.basisTyp != null &&
                (preis.preis != null ||
                  (preis.staffelmenge !== 0 &&
                    (preis.staffelRabattAbs != null || preis.staffelRabattProz != null)))
            )
            .map((preis) => {
              const preisCleaned: VkPreisJson = {
                aufschlag: preis.aufschlag,
                basis: preis.basis,
                basisTyp: preis.basisTyp,
                brutto: preis.brutto,
                etikettpreis: preis.etikettpreis,
                fix: preis.fix,
                id: preis.id,
                preis: preis.preis,
                preisGruppeId: preis.preisGruppeId,
                spanne: preis.spanne,
                staffelRabattAbs: preis.staffelRabattAbs,
                staffelRabattProz: preis.staffelRabattProz,
                staffelTyp: preis.staffelTyp,
                staffelmenge: preis.staffelmenge,
                version: preis.version,
                vpeinheitId: preis.vpeinheitId,
                vpmenge: preis.vpmenge
              }
              return preisCleaned
            })
        }
        return blattCleaned
      })
  }

  return next
}

// eslint-disable-next-line no-unused-vars
export const validateVKPreise = (model: VkPreiseBearbeitenEdit) => {
  const errors = {} as any

  const { neueKondi, preisgruppen } = model
  if (!neueKondi) {
    return errors
  }

  const pgEKId = preisgruppen.find((pg) => pg.name === PG_EK)?.id
  const pgLPId = preisgruppen.find((pg) => pg.name === PG_LP)?.id

  let err = ''
  neueKondi.blaetter
    .filter((b) => b.standortVk)
    .forEach((b) => {
      if (b.rundungsregelId == null) {
        err = err + 'Preisblatt für Standort ' + b.standortNr + "' hat keine Rundungsregel\n"
      }
      b.preise
        .filter((p) => p.staffelmenge === 0)
        .forEach((p) => {
          if (p.staffelErr) {
            err =
              err +
              'Preisblatt für Standort ' +
              b.standortNr +
              ": Preis für Preisgruppe '" +
              p.preisGruppeName +
              "' hat Fehler in der Staffel\n"
          }
          if (p.kasse) {
            if (b.referenzPreisgruppeId == null) {
              err =
                err +
                'Preisblatt für Standort ' +
                b.standortNr +
                ': Der Kassenpreis muss eine Basis ausgewählt haben!\n'
            } else if (p.preis == null || p.preis === 0) {
              err =
                err +
                'Preisblatt für Standort ' +
                b.standortNr +
                ': Der Kassenpreis muss eine Basis mit Preis haben!\n'
            }
          } else if (!p.kasse && p.basisTyp != null) {
            if (p.basisTyp === VkPreisBasisTyp.MANUELL) {
              if (
                (p.preis == null || (p.preis === 0 && p.basisTyp !== VkPreisBasisTyp.MANUELL)) &&
                !model.forEinzelhandel
              ) {
                err =
                  err +
                  'Preisblatt für Standort ' +
                  b.standortNr +
                  ": Preis für Preisgruppe '" +
                  p.preisGruppeName +
                  "' hat kein VK-Preis\n"
              } else if (
                (p.preis == null || (p.preis === 0 && p.basisTyp !== VkPreisBasisTyp.MANUELL)) &&
                model.forEinzelhandel
              ) {
                if ((p.spanne != null && p.spanne !== 100) || p.aufschlag != null) {
                  err =
                    err +
                    'Preisblatt für Standort ' +
                    b.standortNr +
                    ": Leerer Preis für Preisgruppe '" +
                    p.preisGruppeName +
                    "' darf weder Aufschlag noch Spanne haben\n"
                }
              }
            } else if (
              p.aufschlag == null &&
              p.spanne == null &&
              b.kalkulationsVorschlagId == null &&
              !(p.preisGruppeId === pgEKId || p.preisGruppeId === pgLPId)
            ) {
              err =
                err +
                'Preisblatt für Standort ' +
                b.standortNr +
                ": Preis für Preisgruppe '" +
                p.preisGruppeName +
                "' hat weder Aufschlag noch Spanne\n"
            }
          }
        })
    })

  if (err.length > 0) {
    errors.blaetter = err
  }

  // if (!model.gueltigVon) {
  //   errors.gueltigVon = 'Gültig von ist ein Pflichtfeld'
  // }

  return errors
}

const setQuelle = (
  model: VkPreiseBearbeitenEdit,
  standortId: number,
  value: VkPreisQuelle,
  field: 'quelle' | 'quelleStaffel'
) => {
  return updateBlatt(model, standortId, (blatt) => {
    return {
      ...blatt,
      [field]: value,
      festpreis: field === 'quelle' && value === VkPreisQuelle.LISTUNG ? false : blatt.festpreis,
      preise: blatt.preise.map((preis) => {
        return preis.brutto
          ? {
              ...preis,
              basisTyp: VkPreisBasisTyp.MANUELL
            }
          : preis
      })
    }
  })
}

const setFestpreis = (model: VkPreiseBearbeitenEdit, standortId: number, value: any) => {
  return updateBlatt(model, standortId, (blatt) => {
    const refPreis =
      blatt.referenzPreisgruppeId &&
      blatt.preise.find(
        (preis) => preis.preisGruppeId === blatt.referenzPreisgruppeId && preis.staffelmenge === 0
      )
    return {
      ...blatt,
      festpreis: value,
      preise: (value = blatt.preise
        ? blatt.preise.map((preis) => {
            return refPreis && refPreis != preis
              ? {
                  ...preis,
                  preis: refPreis.preis,
                  basis: refPreis.basis,
                  basisTyp: refPreis.basisTyp,
                  basisBrutto: refPreis.basisBrutto,
                  spanne: refPreis.spanne,
                  aufschlag: refPreis.aufschlag
                }
              : preis
          })
        : blatt.preise)
    }
  })
}

export const VKPreisUsecase = {
  SETALLEABGESCHLOSSEN: 'SETALLEABGESCHLOSSEN',
  SETPREISBLATTKALKULATIONSVORSCHLAG: 'SETPREISBLATTKALKULATIONSVORSCHLAG',
  ADDSTAFFEL: 'ADDSTAFFEL',
  REMOVESTAFFEL: 'REMOVESTAFFEL',
  UPDATEPREISVALUE: 'UPDATEPREISVALUE',
  UPDATEBLATTVALUE: 'UPDATEBLATTVALUE',
  UPDATERUNDUNGSREGEL: 'UPDATERUNDUNGSREGEL',
  UPDATEREFERENZPREIS: 'UPDATEREFERENZPREIS',
  UPDATEFRACHTKOSTEN: 'UPDATEFRACHTKOSTEN',
  SETQUELLE: 'SETQUELLE',
  SETQUELLESTAFFEL: 'SETQUELLESTAFFEL',
  SETFESTPREIS: 'SETFESTPREIS',

  reducer: (state: VkPreiseBearbeitenEdit, action: ModelAction) => {
    switch (action.type) {
      case VKPreisUsecase.SETALLEABGESCHLOSSEN:
        return setStandorteAbgeschlossen(state)
      case VKPreisUsecase.SETPREISBLATTKALKULATIONSVORSCHLAG:
        return setPreisblattKalkulationsvorschlag(state, action.standortId, action.value)
      case VKPreisUsecase.UPDATERUNDUNGSREGEL:
        return updateRundungsregel(state, action.standortId, action.value)
      case VKPreisUsecase.UPDATEREFERENZPREIS:
        return updateReferenzPreis(state, action.standortId, action.value)
      case VKPreisUsecase.ADDSTAFFEL:
        return addStaffel(state, action.standortId, action.preis)
      case VKPreisUsecase.REMOVESTAFFEL:
        return removeStaffel(state, action.standortId, action.preis)
      case VKPreisUsecase.UPDATEPREISVALUE:
        return updatePreis(state, action.standortId, action.preis, action.name, action.value)
      case VKPreisUsecase.UPDATEBLATTVALUE:
        return updateBlattValue(state, action.standortId, action.name, action.value)
      case VKPreisUsecase.UPDATEFRACHTKOSTEN:
        return updateBlattFrachtkosten(state, action.standortId, action.name, action.value)
      case VKPreisUsecase.SETQUELLE:
        return setQuelle(state, action.standortId, action.value, 'quelle')
      case VKPreisUsecase.SETQUELLESTAFFEL:
        return setQuelle(state, action.standortId, action.value, 'quelleStaffel')
      case VKPreisUsecase.SETFESTPREIS:
        return setFestpreis(state, action.standortId, action.value)
      default:
        return null
    }
  }
}
