import { ChangeCircle } from '@mui/icons-material'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import {
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import {
  ArtikelAbonniertStatusSymbol,
  getArtikelAbonniertStatusText
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { HeaderTitleCell } from '@one/components/common/HeaderTitleCell'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { getArchiviertRowStyle } from '@one/components/common/TableUtils'
import { formatLieferant } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelAbonniertStatus,
  ArtikelDisplayJson,
  ArtikelQuelle,
  SuchArtikelTyp
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { arrayFromSet } from '@utils/utils'
import { useCallback, useContext, useMemo, useState } from 'react'

export interface ArtikelUebersichtTableProps {
  result: SearcherResultType<ArtikelDisplayJson>
  reload: VoidFunction
  borderless?: boolean
  onChangeBT?: (selected: ArtikelDisplayJson[]) => void
}

export const ArtikelUebersichtTable = ({
  result,
  reload,
  borderless,
  onChangeBT
}: ArtikelUebersichtTableProps) => {
  const { et } = useEnums()
  const { isGesellschafter, isPIMModel } = useContext(AppContext)
  const [selected, setSelected] = useState(new Set<ArtikelDisplayJson>())
  const { openLabelsEdit } = useArtikelLabelEdit()

  const can = useMemo(
    () => ({
      changeBT:
        selected.size > 0 &&
        arrayFromSet(selected).find((a) => a.aboStatus != null || a.quelle !== ArtikelQuelle.ERP) ==
          null,
      editLabel:
        selected.size > 0 &&
        arrayFromSet(selected).find((a) => a.quelle !== ArtikelQuelle.ERP) == null
    }),
    [selected]
  )

  const columnsConfig = useMemo<ArtikelDefaultColumnsProps<ArtikelDisplayJson>>(
    () => ({
      isGesellschafter,
      additionalColumns: [
        {
          field: 'eigenlistung',
          header: 'E',
          headerTip: 'Eigenlistung',
          sortable: true,
          type: 'boolean',
          align: 'center',
          hideIfEmpty: true
        },
        {
          field: 'typ',
          header: 'Quelle',
          sortable: true,
          valueGetter: (row) => et(HkmEnum.SuchArtikelTyp, row.typ)
        },
        {
          field: 'lieferant',
          headerTip: 'Lieferant zum Artikel, der über die Suche gefunden wurde.',
          header: <HeaderTitleCell title="Lieferant" subtitle="Suche" />,
          sortable: true,
          hideIfEmpty: true,
          valueGetter: (row) => formatLieferant(row.lieferant),
          body: (row) =>
            row.lieferant != null ? <LieferantCell lieferant={row.lieferant} asLink /> : null
        },
        {
          field: 'lieferantIAN',
          header: <HeaderTitleCell title="IAN" subtitle="Suche" />,
          headerTip: 'Industrieartikelnummer des Lieferanten, der über die Suche gefunden wurde.',
          hideIfEmpty: true,
          valueGetter: (row) => row.lieferantIAN
        },
        // {
        //   field: 'lieferantEkBrutto',
        //   header: <HeaderTitleCell title="Listenpreis" subtitle="Suche" />,
        //   headerTip: 'Listenpreis des Lieferanten, der über die Suche gefunden wurde.',
        //   align: 'right',
        //   type: 'money2',
        //   hideIfEmpty: true,
        //   valueGetter: (row) => row.lieferantEkBrutto
        // },
        // {
        //   field: 'lieferantEkPreisEinheit',
        //   header: <HeaderTitleCell title="Einheit" subtitle="Suche" />,
        //   headerTip:
        //     'Einkaufsmengen und Einheit des Lieferanten, der über die Suche gefunden wurde.',
        //   hideIfEmpty: true,
        //   valueGetter: (row) =>
        //     row.lieferantEkPreisBasis != null
        //       ? `${row.lieferantEkPreisBasis ?? ''}${row.lieferantEkPreisEinheit ?? ''}`
        //       : null
        // },
        {
          field: 'aboStatus',
          header: 'Abo',
          align: 'center',
          valueGetter: (row) => getArtikelAbonniertStatusText(et, row.aboStatus),
          body: (row) => <ArtikelAbonniertStatusSymbol value={row.aboStatus} et={et} />
        }
      ]
    }),
    [et, isGesellschafter]
  )

  const columns = useArtikelColumns(columnsConfig)

  // Button actions for each table row
  const tableActions = useMemo(
    () =>
      [
        {
          icon: 'visibility',
          tooltip: 'Anzeigen',
          getLink: (artikel: ArtikelDisplayJson) => AppPaths.ArtikelFn(artikel.id)
        },
        {
          icon: 'edit',
          tooltip: 'Bearbeiten',
          getLink: (data) =>
            isPIMModel &&
            data.typ === SuchArtikelTyp.DEZENTRAL &&
            AppPaths.EigenlistungsArtikelPflegeFn(data.id)
        }
      ] as DataTableAction<ArtikelDisplayJson>[],
    [isPIMModel]
  )

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(selected),
      callback: {
        then: () => {
          reload()
        }
      }
    })
  }, [openLabelsEdit, selected, reload])

  const actions = useMemo(
    () =>
      [
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Labels bearbeiten',
          icon: 'label',
          text: 'Labels',
          variant: 'contained',
          disabled: !can.editLabel,
          onClick: openLabelDialog
        },
        {
          role: UserRoles.STAMMDATEN_EDITOR,
          tooltip: 'Betriebstyp wechseln',
          icon: <ChangeCircle />,
          text: 'Betriebstyp wechseln',
          variant: 'contained',
          disabled: !can.changeBT,
          visible: onChangeBT != null,
          onClick: () => onChangeBT(arrayFromSet(selected))
        }
      ] as Action[],
    [can.changeBT, can.editLabel, onChangeBT, openLabelDialog, selected]
  )

  const getRowStyle = (row: ArtikelDisplayJson) => {
    return getArchiviertRowStyle(row?.archiviert)
  }

  return (
    <DataTableCard
      name="ArtikelUebersichtTable"
      height="100%"
      title="Artikelübersicht"
      columns={columns}
      actions={tableActions}
      bottomActions={actions}
      selectCol
      selectMode="multi"
      selected={selected}
      onSelect={setSelected}
      dense
      initialOrderBy="name"
      filterMode="both"
      borderless={borderless}
      rowStyle={getRowStyle}
      hover
      localStateName="ArtikelUebersichtTable"
      result={result}
    />
  )
}
