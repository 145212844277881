import { CheckCircle, CloudOff, Sync, SyncProblem, WifiFind } from '@mui/icons-material'
import { amber, orange, red } from '@mui/material/colors'
import clsx from 'clsx'
import { memo } from 'react'
import { keyframes } from 'tss-react'
import { makeStyles } from 'tss-react/mui'
import { IconButton } from './Buttons/IconButton'

const useStyles = makeStyles()((theme: any) => ({
  sync: {
    borderRadius: '40px',
    border: '1px',
    color: 'white',
    opacity: 0.5,
    fontSize: '20px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '1!important'
    }
  },
  alert: {
    backgroundColor: red[300],
    animation: `${keyframes`
    0% {
      opacity: 1;
      transform: scale(1.1);
    }
    80% {
      opacity: 0.5;
      backgroundColor: unset;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(1.1);
    }
    `} 3s ease 0s infinite normal forwards`,
    '&:hover': {
      animation: 'none'
    }
  },
  todo: {
    backgroundColor: amber[400],
    animation: `${keyframes`
    0% {
      opacity: 1;
      transform: scale(1.1);
    }
    80% {
      opacity: 0.5;
      background-color: unset;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(1.1);
    }
    `} 3s ease 0s infinite normal forwards`,
    '&:hover': {
      animation: 'none'
    }
  },
  autoTodo: {
    backgroundColor: orange[400],
    opacity: 0.8
  },
  ok: {
    color: 'white',
    backgroundColor: 'unset',
    opacity: 0.8,
    '&:hover': {
      animation: 'none'
    }
  },
  unknown: {
    // color: 'yellow',
    backgroundColor: 'unset',
    opacity: 0.8,
    '&:hover': {
      animation: 'none'
    }
  },
  running: {
    backgroundColor: orange[300],
    animation: `${keyframes` 
    0% {
       transform: rotate(0);
    }
    100% {
       transform: rotate(360deg);
    }
   `} 4s linear 0s infinite reverse none`
  },
  help: {
    textAlign: 'center'
  },
  dummy: {
    paddingTop: '7px'
  },
  offline: {}
}))

export enum SyncSignType {
  off,
  offline,
  unknown,
  ok,
  todo,
  autoTodo,
  running,
  error
}

export type SyncSignProps = {
  type: SyncSignType
  tooltip?: string
  info?: string
  onClick?: (e: MouseEvent) => void
}

export type SyncTimeProps = {
  type: SyncSignType
  info?: string
  tooltip?: string
  onClick?: () => void
  autoTime?: string
}

export const SyncSign = memo(
  ({ type, info = null, tooltip = null, onClick = null, autoTime = null }: SyncTimeProps) => {
    const { classes } = useStyles()

    if (type == null || type === SyncSignType.off) {
      return null
    }

    const infox = info ? `${info} ` : ''

    const message =
      tooltip ||
      (type == SyncSignType.offline && 'Verbindung zum Server offline') ||
      (type == SyncSignType.unknown && `Status ${infox}Synchronisation unbekannt`) ||
      (type == SyncSignType.todo && `${infox}Synchronisation notwendig!`) ||
      (type == SyncSignType.autoTodo && `${infox}Automatische Synchronisation um ${autoTime}!`) ||
      (type == SyncSignType.ok && `${infox}Synchronisation ok.`) ||
      (type == SyncSignType.running && `${infox}Synchronisation ist aktiv`) ||
      (type == SyncSignType.error && `${infox}Synchronisation hat Fehler!`)

    const icon =
      (type == SyncSignType.offline && (
        <CloudOff className={clsx(classes.sync, classes.offline)} />
      )) ||
      (type == SyncSignType.unknown && (
        <WifiFind className={clsx(classes.sync, classes.unknown)} />
      )) ||
      (type == SyncSignType.todo && <Sync className={clsx(classes.sync, classes.todo)} />) ||
      (type == SyncSignType.autoTodo && (
        <Sync className={clsx(classes.sync, classes.autoTodo)} />
      )) ||
      (type == SyncSignType.ok && <CheckCircle className={clsx(classes.sync, classes.ok)} />) ||
      (type == SyncSignType.running && <Sync className={clsx(classes.sync, classes.running)} />) ||
      (type == SyncSignType.error && <SyncProblem className={clsx(classes.sync, classes.alert)} />)

    return (
      <div>
        <IconButton
          icon={icon}
          onClick={onClick}
          disabled={onClick == null}
          name="sync-sign"
          tooltip={<div className={classes.help}>{message}</div>}
        />
      </div>
    )
  }
)

SyncSign.displayName = 'SyncSign'
