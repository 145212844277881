import { Download } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { api } from '@one/api'
import { LieferantAboField } from '@one/components/LieferantAbo/LieferantAboField'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import { ExportTypField } from '@one/components/common/ExportTypField'
import { NeonKontextField } from '@one/components/common/NeonKontextField'
import { PreisEbeneField } from '@one/components/common/PreisEbeneField'
import { formatLieferant } from '@one/components/common/formatters'
import { DownloadResponseJson, SpreadSheetType } from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useFormState } from '@utils/formstate'
import { buildActionButtons } from '@utils/ui/Action'
import { AppContext } from '@utils/ui/App/AppContext'
import { useAsyncDownloader } from '@utils/ui/AsyncDownload'
import { DialogEx } from '@utils/ui/DialogEx'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useContext } from 'react'

export const EkPreisExportDialog = ({ open, onClose }) => {
  const [exportValues, onChangeExportValues] = useFormState({
    lieferant: null,
    type: SpreadSheetType.XLSX,
    neonKontext: null,
    preisEbeneId: null,
    bt: null
  })
  const { isPIMModel } = useContext(AppContext)
  const [apiCall, apiBusy] = useApiCaller(api)
  const asyncDownloader = useAsyncDownloader()

  const handleDownload = () => {
    apiCall<DownloadResponseJson>({
      method: 'GET',
      rest: 'ekpreis/export',
      params: {
        lieferantId: exportValues.lieferant.id,
        type: exportValues.type,
        neonKontexte: exportValues.neonKontext,
        preisEbeneId: exportValues.preisEbeneId,
        betriebstypen: exportValues.bt
      },
      onSuccess: (data) => {
        asyncDownloader({
          downloadKey: data.key,
          title: `Ek-Preise Export für ${formatLieferant(exportValues.lieferant)}`,
          asyncTask: data.state.asyncTask
        })
        onClose()
      }
    })
  }

  const actions = buildActionButtons({
    actions: {
      icon: <Download />,
      text: 'EK-Preise herunterladen',
      tooltip: 'Download',
      onClick: handleDownload,
      enabled: Boolean(
        exportValues?.lieferant?.id && (!isPIMModel || exportValues.neonKontext) && !apiBusy
      )
    }
  })

  return (
    <DialogEx
      open={open}
      onClose={onClose}
      fullWidth
      title="EK-Preise Export"
      helpKey="ekpreis_export_dialog"
      height="400px"
      actions={actions}
    >
      <StatePlane wait={apiBusy} noNav>
        <Grid
          container
          spacing={2}
          direction="column"
          marginTop={0}
          paddingLeft={1}
          paddingRight={1}
        >
          <Grid item xs={12} sm={6}>
            <LieferantAboField
              value={exportValues.lieferant}
              label="Lieferant"
              name="lieferant"
              onChange={onChangeExportValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PreisEbeneField
              label="Standort"
              name="preisEbeneId"
              emptyText="Alle"
              value={exportValues.preisEbeneId}
              onChange={onChangeExportValues}
              kontext={exportValues.neonKontext}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ExportTypField
              label="Export-Typ"
              value={exportValues.type}
              name="type"
              onChange={onChangeExportValues}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {isPIMModel ? (
              <NeonKontextField
                label="Kontext"
                name="neonKontext"
                value={exportValues.neonKontext}
                onChange={onChangeExportValues}
                required
                multiple
              />
            ) : (
              <ArtikelBetriebstypField
                label="Betriebstyp"
                name="bt"
                value={exportValues.bt}
                onChange={onChangeExportValues}
                showArtikelzahlVonLieferantId={exportValues.lieferant?.id}
                filter="mitArtikeln"
                disabled={exportValues.lieferant == null}
                required
                multiple
              />
            )}
          </Grid>
        </Grid>
      </StatePlane>
    </DialogEx>
  )
}
