import {
  PimArtikelVersionGtinJson,
  PimArtikelVersionLandJson,
  PimLandDisplayJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import React from 'react'
import { EigenlistungsArtikelUseCase } from '@one/components/Artikel/EigenlistungsArtikel/model/EigenlistungsArtikelUseCase'
import { onChangeWrapper, ValueChangeFn } from '@utils/modelmgr'
import { aidOf, resolveObjectField } from '@utils/utils'
import { RowForm } from '@utils/ui/fields/RowForm'
import Grid from '@mui/material/Grid2'
import { PimLandField } from '@one/components/common/PimLandField'
import { TextField } from '@utils/ui/fields/TextField'
import { Checkbox } from '@utils/ui/fields/Checkbox'

type PimArtikelZusatzEANProps = {
  ean: PimArtikelVersionGtinJson
  eans: PimArtikelVersionGtinJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
  errorsPath: string
  readonly?: boolean
}

const PimArtikelZusatzEAN = ({
  ean,
  eans,
  onChange,
  errors,
  errorsPath,
  readonly
}: PimArtikelZusatzEANProps) => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 3 }}>
        <TextField
          label="GTIN"
          name="gtin"
          value={ean.gtin}
          onChange={onChange}
          error={resolveObjectField(errors, `${errorsPath}.gtin`)}
          fullWidth
          disabled={readonly /*|| ean.id != null*/}
          regex={/^\d{1,14}$/}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 2 }}>
        <Checkbox
          label="Gelöscht"
          name="geloescht"
          onChange={onChange}
          disabled={readonly}
          checked={ean.geloescht}
        />
      </Grid>
    </Grid>
  )
}

export const PimArtikelZusatzEANMemo = React.memo(PimArtikelZusatzEAN)

export interface PimArtikelZusatzEANsProps {
  zusatzEANs: PimArtikelVersionGtinJson[]
  readonly?: boolean
  dispatch: (action: any) => void
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
}

export const PimArtikelZusatzEANs = ({
  zusatzEANs,
  readonly,
  dispatch,
  onChange,
  errors
}: PimArtikelZusatzEANsProps) => {
  return (
    <RowForm
      title="Zusatz EANs"
      name="ZusatzEANs"
      rows={zusatzEANs}
      onAdd={readonly ? null : () => dispatch({ type: EigenlistungsArtikelUseCase.ADDEAN })}
      canRemove={(row) => row.id == null && !readonly}
      onRemove={(ean) => dispatch({ type: EigenlistungsArtikelUseCase.REMOVEEAN, ean })}
      body={(row, idx) => (
        <PimArtikelZusatzEANMemo
          ean={row}
          eans={zusatzEANs}
          onChange={onChangeWrapper(onChange, `pimArtikel.version.eans.[__aid=${aidOf(row)}]`)}
          errors={errors}
          errorsPath={`pimArtikel.version.eans.[${idx}]`}
          readonly={readonly}
        />
      )}
    />
  )
}

export const PimArtikelZusatzEANsMemo = React.memo(PimArtikelZusatzEANs)
