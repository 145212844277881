import { UebernahmeEigenlistungsDialog } from '@one/components/Datenpool/UebernahmeEigenlistungDialog'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant, formatMengeneinheit } from '@one/components/common/formatters'
import {
  ArtikelDatenpoolDisplayJson,
  ArtikelDatenpoolUebernahmeStatus
} from '@one/typings/apiTypings'
import { SearcherResultType } from '@utils/searcher'
import { Button } from '@utils/ui/Buttons/Button'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useCallback, useMemo, useState } from 'react'
import { nameOf, singleFromSet } from '@utils/utils'
import { HkmEnum } from '@one/enums/HkmEnum'
import { useEnums } from '@utils/enums'
import Grid from '@mui/material/Grid2'
import { Switch } from '@utils/ui/fields/Switch'
import { ArtikelDatenpoolUebernahmeStatusMedal } from '@one/components/Datenpool/ArtikelDatenpoolUebernahmeStatusMedal'
import { UebernahmeEigenlistungDetails } from '@one/components/Datenpool/UebernahmeEigenlistungDetails'

export interface ArtikelDatenPoolUebersichtTableProps {
  result: SearcherResultType<ArtikelDatenpoolDisplayJson>
  reload: VoidFunction
  borderless?: boolean
}

export const ArtikelDatenPoolUebersichtTable = ({
  result,
  reload,
  borderless
}: ArtikelDatenPoolUebersichtTableProps) => {
  const [selected, setSelected] = useState<Set<ArtikelDatenpoolDisplayJson>>(new Set())
  const [DlgAnker, showDlg] = useDialogAnker()
  const { et } = useEnums()
  const [filter, setFilter] = useState<boolean>(true)

  const single = singleFromSet(selected)

  const createEigenlistungDisabled = useMemo(
    () =>
      selected.size === 0 ||
      Array.from(selected).find(
        (item) =>
          item.status !== ArtikelDatenpoolUebernahmeStatus.ANLEGBAR &&
          item.status !== ArtikelDatenpoolUebernahmeStatus.AKTUALISIERBAR
      ) != null,
    [selected]
  )

  const rowsFiltered = useMemo(
    () =>
      result?.rows?.filter(
        (item) =>
          item.status == ArtikelDatenpoolUebernahmeStatus.ANLEGBAR ||
          item.status == ArtikelDatenpoolUebernahmeStatus.AKTUALISIERBAR
      ),
    [result]
  )

  const resultFiltered = useMemo(
    () =>
      filter
        ? {
            ...result,
            rows: rowsFiltered
          }
        : result,
    [filter, result, rowsFiltered]
  )

  const columns = useMemo<Column<ArtikelDatenpoolDisplayJson>[]>(
    () => [
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('status'),
        header: 'Status',
        valueGetter: (row) => et(HkmEnum.ArtikelDatenpoolUebernahmeStatus, row.status),
        body: (row) => <ArtikelDatenpoolUebernahmeStatusMedal value={row.status} />,
        tooltip: (row) => row.meldungen?.map((m) => m.message).join('\n')
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('supplierDistributionChannel'),
        header: 'Vertriebsweg',
        align: 'center'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('artikelArt'),
        header: 'Artikelart',
        valueGetter: (row) => et(HkmEnum.PimArtikelArt, row.artikelArt) ?? row.articleType
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('hageNummer'),
        header: 'hage-Nr.'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('gtin'),
        header: 'EAN'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('additionalGtin'),
        header: 'Zusatz EAN',
        headerTip: 'Zusatz EAN x zur Haupt-EAN'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('lan'),
        header: 'IAN'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('gln'),
        header: 'GLN'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('supplierArticleDescription'),
        header: 'Bezeichnung',
        headerTip: 'Artikelbezeichnung'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('statisticalCode'),
        header: 'Statistische Warennummer',
        headerTip: 'Statistische Warennummer'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('lieferantWarenGruppe'),
        header: 'Warengruppe'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('lieferant'),
        header: 'Lieferant',
        body: (row) =>
          row.lieferant ? <LieferantCell lieferant={row.lieferant} asLink /> : row.supplierID,
        valueGetter: (row) => (row.lieferant ? formatLieferant(row.lieferant) : row.supplierID)
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('supplierCountryOfOrigin'),
        header: 'Land Lieferant',
        align: 'center'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('factoryPrice'),
        header: 'Werkslistenpreis',
        type: 'money2'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('priceValidityFactoryPrice'),
        header: 'Preisgültigkeit',
        headerTip: 'Preisgültigkeit ab (Werkslistenpreis)'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('taxIndicator'),
        header: 'Steuerindikator'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('basisME'),
        header: 'Basis-ME',
        valueGetter: (row) => formatMengeneinheit(row.basisME)
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('einkaufMEEH'),
        header: 'Einkauf-ME EH',
        headerTip: 'Einkaufsmengeneinheit Einzelhandel',
        valueGetter: (row) => formatMengeneinheit(row.einkaufMEEH)
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('einkaufMEFH'),
        header: 'Einkauf-ME FH',
        headerTip: 'Einkaufsmengeneinheit Fachhandel',
        valueGetter: (row) => formatMengeneinheit(row.einkaufMEFH)
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('priceBasisFH'),
        header: 'Preisbasis FH',
        headerTip: 'Preisbasis - Fachhandel',
        type: 'number'
      },
      {
        field: nameOf<ArtikelDatenpoolDisplayJson>('minimumOrderQuantityFH'),
        header: 'Mindestbestellmenge FH',
        headerTip: 'Mindestbestellmenge (VE) - Fachhandel',
        type: 'number'
      }
      // {
      //   field: 'uebernahmeInfo.artikel',
      //   header: 'EL Artikel',
      //   headerTip: 'Artikel als Eigenlistung',
      //   valueGetter: (row) =>
      //     `${row.uebernahmeInfo?.artikel?.hageNummer ?? ''} - ${
      //       row.uebernahmeInfo?.artikel?.bez1 ?? ''
      //     }`,
      //   body: (row) => <ArtikelCell artikel={row.uebernahmeInfo?.artikel} asLink />
      // },
      // {
      //   field: 'uebernahmeInfo.audit.updatedOn',
      //   header: 'EL Zeitpunkt',
      //   headerTip: 'Zeitpunkt letzte Übernahme',
      //   valueGetter: (row) =>
      //     row.uebernahmeInfo?.audit?.updatedOn
      //       ? formatDateTime(row.uebernahmeInfo?.audit?.updatedOn)
      //       : formatDateTime(row.uebernahmeInfo?.audit?.createdOn)
      // },
      // {
      //   field: 'ebmeSpecialist',
      //   header: 'EBME - FH',
      //   headerTip: 'Einkaufs-Berechnungsmengeneinheit (EBME) - Fachhandel'
      // },
      // {
      //   field: 'definitionOfStandardDeliveryUnit1',
      //   header: 'Liefermengeneinheit 1 - FH',
      //   headerTip: 'Definition Standard-Liefermengeneinheit 1 - Fachhandel'
      // },
    ],
    [et]
  )

  const onCreateEigenlistungClose = useCallback(
    (isSuccess: boolean) => {
      if (isSuccess) {
        reload()
      }
    },
    [reload]
  )

  const showCreateEigenlistung = useCallback(() => {
    const sel = Array.from(selected)
    const hageNummerArr = sel.map((item) => ({
      hageNummer: item.hageNummer,
      supplierID: item.supplierID
    }))

    showDlg((visible, onClose) => (
      <UebernahmeEigenlistungsDialog
        open={visible}
        onClose={onClose(onCreateEigenlistungClose)}
        artikelKeys={hageNummerArr}
        anlage={
          sel.find((item) => item.status === ArtikelDatenpoolUebernahmeStatus.ANLEGBAR) != null
        }
      />
    ))
  }, [selected, showDlg, onCreateEigenlistungClose])

  const actions = useMemo(
    () => [
      <Button
        key="eigenlistung"
        label="Eigenlistung übernehmen"
        disabled={createEigenlistungDisabled}
        onClick={showCreateEigenlistung}
        variant="contained"
        size="small"
      />
    ],
    [showCreateEigenlistung, selected.size]
  )

  const extendedFilter = result?.rows && rowsFiltered && (
    <Grid container paddingLeft={2} paddingRight={2} justifyContent="center">
      <Grid>
        <Switch
          label={`${result?.rows && rowsFiltered ? result.rows.length - rowsFiltered.length : ' '} Artikel ausblenden, die nicht übernommen werden können`}
          size="small"
          checked={result.rows.length === rowsFiltered.length ? false : filter}
          onClick={() => setFilter(!filter)}
          disabled={result.rows.length === rowsFiltered.length}
        />
      </Grid>
    </Grid>
  )

  return (
    <Grid container direction="row" height="100%" spacing={1}>
      <Grid flexGrow={1}>
        <DataTableCard
          name="ArtikelDatenPoolUebersichtTable"
          height="100%"
          title="Artikelübersicht"
          header={extendedFilter}
          columns={columns}
          selectCol
          bottomActions={actions}
          selectMode="multi"
          selected={selected}
          onSelect={setSelected}
          dense
          initialOrderBy="name"
          filterMode="both"
          borderless={borderless}
          hover
          localStateName="ArtikelDatenPoolUebersichtTable"
          rowFiller
          result={resultFiltered}
        />
        <DlgAnker />
      </Grid>
      {single ? (
        <Grid size={{ xl: 3, lg: 4, md: 5, sm: 12, xs: 12 }}>
          <UebernahmeEigenlistungDetails artikel={single} />
        </Grid>
      ) : null}
    </Grid>
  )
}
