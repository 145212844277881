import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { LieferantAbonniertStatusMedal } from '@one/components/common/LieferantAbonniertStatusMedal'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  LieferantAboDisplayJson,
  LieferantAenderungTyp,
  SuchLieferantTyp
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useEnums } from '@utils/enums'
import { SearcherResultType } from '@utils/searcher'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { PermissionUtil } from '@utils/ui/Permission'
import { ThemeContext } from '@utils/ui/Theme'
import { useCallback, useContext, useMemo, useState } from 'react'
import {getLieferantLink} from "@one/components/Lieferant/LieferantUtils"

export interface LieferantAboUebersichtTableProps {
  result: SearcherResultType<LieferantAboDisplayJson>
  lieferantenAenderungsTyp: LieferantAenderungTyp
  reload?: VoidFunction
  readonly?: boolean
  borderless?: boolean
}

export const LieferantAboUebersichtTable = ({
  result,
  lieferantenAenderungsTyp,
  reload,
  readonly,
  borderless
}: LieferantAboUebersichtTableProps) => {
  const { darkMode } = useContext(ThemeContext)
  const [selected, setSelected] = useState<Set<LieferantAboDisplayJson>>(new Set())

  const { askToConfirm } = useMessageDialog()
  const [apiCall, apiBusy] = useApiCaller(api)

  const tableActions = useMemo(
    (): DataTableAction<LieferantAboDisplayJson>[] =>
      readonly
        ? []
        : [
            {
              icon: 'edit',
              tooltip: 'Bearbeiten',
              check: (data) =>
                PermissionUtil.show(lieferantenAenderungsTyp !== LieferantAenderungTyp.NEU),
              getLink: (data) => {
                return AppPaths.LieferantAboPflegeFn(data.id)
              }
            },
            {
              icon: 'add',
              tooltip: 'Hinzufügen',
              check: (data) =>
                PermissionUtil.show(lieferantenAenderungsTyp === LieferantAenderungTyp.NEU),
              getLink: (data) => {
                return AppPaths.LieferantAboPflegeFn('neu', data.zeLieferantId)
              }
            },
            {
              icon: 'local_shipping',
              tooltip: 'Lieferant öffnen',
              getLink: (data) => getLieferantLink(data.lieferant?.typ, data?.lieferant.id)
            }
            /* erstmal kein Löschen, Anforderung besteht noch nicht */
          ],
    [lieferantenAenderungsTyp, readonly]
  )
  const { et } = useEnums()

  const columns = useMemo<Column<LieferantAboDisplayJson>[]>(
    () => [
      {
        field: 'aboName',
        header: 'Abo-Name',
        sortable: true,
        hideIfEmpty: true
      },
      {
        field: ['lieferant.name1', 'lieferant.ort', 'lieferant.nummer'],
        header:
          lieferantenAenderungsTyp === LieferantAenderungTyp.NEU
            ? 'Zentrale Lieferant'
            : 'Lieferant',
        valueGetter: (row) => formatLieferant(row.lieferant),
        body: (row) => <LieferantCell lieferant={row.lieferant} asLink />,
        sortable: true
      },
      {
        field: 'lieferanten',
        header: 'passende ERP-Lieferanten',
        off: lieferantenAenderungsTyp !== LieferantAenderungTyp.NEU,
        body: (row) =>
          row.matchingDzLieferant.map((l) => <LieferantCell key={l.id} lieferant={l} asLink />),
        hideIfEmpty: true
      },
      {
        field: 'anzahlAbonniert',
        header: 'Abonnierte Artikel',
        sortable: true,
        type: 'number',
        hideIfEmpty: true
      },
      {
        field: 'anzahlGelistet',
        header: 'Gelistete Artikel',
        sortable: true,
        type: 'number',
        hideIfEmpty: true
      },
      {
        field: 'status',
        header: 'Status',
        sortable: true,
        hideIfEmpty: true,
        valueGetter: (row) => row.status && et(HkmEnum.LieferantAbonniertStatus, row.status),
        body: (row) => (
          <LieferantAbonniertStatusMedal value={row.status} et={et} darkMode={darkMode} fullWidth />
        )
      }
    ],
    [darkMode, et, lieferantenAenderungsTyp]
  )

  const onLieferantenNichtVerwenden = useCallback(() => {
    askToConfirm({
      severity: 'severe',
      title: 'Lieferanten nicht verwenden',
      extraConfirm: true,
      message: `Sollen die ausgewählten Lieferanten nicht verwendet werden?`,
      confirmLabel: 'Lieferanten nicht verwenden',
      onConfirm: () => {
        apiCall({
          method: 'POST',
          rest: 'lieferantabo/setzeUnerwuenscht',
          data: Array.from(selected).map((s) => s.lieferant.id),
          onSuccess: () => {
            reload()
          }
        })
      }
    })
  }, [askToConfirm, selected, apiCall, reload])

  const actions = useMemo<Action[]>(
    () =>
      readonly
        ? []
        : [
            {
              role: UserRoles.STAMMDATEN_EDITOR,
              tooltip: 'Lieferantenabo hinzufügen',
              navlink: '/lieferantabo/neu',
              icon: 'add'
            }
          ],
    [readonly]
  )

  const bottomActions = useMemo(
    () =>
      readonly
        ? ([] as Action[])
        : ([
            {
              role: UserRoles.STAMMDATEN_EDITOR,
              tooltip: 'Ausgewählte Lieferanten nicht verwenden',
              enabled: selected.size > 0,
              onClick: onLieferantenNichtVerwenden,
              visible: lieferantenAenderungsTyp === LieferantAenderungTyp.NEU,
              text: 'Lieferanten nicht verwenden'
            }
          ] as Action[]),
    [readonly, lieferantenAenderungsTyp, selected, onLieferantenNichtVerwenden]
  )

  return (
    <DataTableCard
      name="LieferantAboUebersichtTable"
      title="Lieferantenabonnements"
      filterMode="both"
      localStateName="LieferantAboUebersichtTable"
      topActions={actions}
      selectCol
      selected={selected}
      onSelect={setSelected}
      selectMode={lieferantenAenderungsTyp === LieferantAenderungTyp.NEU ? 'multi' : 'none'}
      borderless={borderless}
      columns={columns}
      actions={tableActions}
      initialOrderBy="name"
      bottomActions={bottomActions}
      dense
      emptyMessage="Keine Daten"
      rowFiller="100%"
      result={result}
    />
  )
}
